.hover-submenu {
  @media (max-width: 767.98px) {
    .app-sidebar {
      top: 71px !important;
    }
  }
  @media (min-width: 768px) {
    &.sidebar-mini.sidenav-toggled .side-menu__item .side-menu__icon {
      margin-left: 0px;
    }
    .app-sidebar {
      padding-top: 75px !important;
    }
  }
  @media (min-width: 992px) {
    &.app.header-light.sidenav-toggled.dark-menu,
    &.app.header-light.sidenav-toggled.sidenav-toggled-open.dark-menu,
    &.app.dark-header.sidenav-toggled.dark-menu,
    &.app.dark-header.sidenav-toggled.sidenav-toggled-open.dark-menu,
    &.app.color-header.sidenav-toggled.dark-menu,
    &.app.color-header.sidenav-toggled.sidenav-toggled-open.dark-menu,
    &.app.gradient-header.sidenav-toggled.dark-menu,
    &.app.gradient-header.sidenav-toggled.sidenav-toggled-open.dark-menu {
      .side-header {
        .header-brand1 {
          .toggle-logo {
            display: block !important;
          }
          .desktop-logo,
          .light-logo,
          .light-logo1 {
            display: none !important;
          }
        }
      }
    }
    &.app.dark-mode.color-menu.dark-header,
    &.app.dark-mode.gradient-menu.dark-header {
      .side-header {
        background-color: $primary !important;
      }
    }

    &.app.dark-mode.color-menu.gradient-header {
      .side-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.light-menu.dark-header,
    &.app.dark-mode.light-menu.dark-header.sidenav-toggled.sidenav-toggled-open {
      .side-header {
        background-color: $white !important;
      }
      .app-header {
        background-color: $dark-theme !important;
      }
    }
    &.app.dark-mode.light-menu.color-header,
    &.app.dark-mode.light-menu.color-header.sidenav-toggled.sidenav-toggled-open,
    &.app.dark-mode.light-menu.gradient-header,
    &.app.dark-mode.light-menu.gradient-header.sidenav-toggled.sidenav-toggled-open {
      .side-header {
        background-color: $white !important;
      }
      .app-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.color-header.dark-menu {
      .app-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.gradient-menu.color-header {
      .side-header,
      .app-header {
        background-color: $primary !important;
      }
    }
    &.app.header-light.dark-mode.color-menu.sidenav-toggled,
    &.app.header-light.dark-mode.gradient-menu.sidenav-toggled {
      .side-header {
        .header-brand1 {
          .toggle-logo {
            display: block !important;
          }
          .desktop-logo,
          .light-logo,
          .light-logo1 {
            display: none !important;
          }
        }
      }
    }
    &.app.header-light.dark-mode.color-menu,
    &.app.header-light.dark-mode.gradient-menu {
      .app-header {
        background-color: $white !important;
      }
      .side-header {
        background-color: $primary !important;
        .header-brand1 {
          .desktop-logo {
            display: block !important;
          }
          .toggle-logo,
          .light-logo,
          .light-logo1 {
            display: none !important;
          }
        }
      }
    }
    &.app.header-light.light-menu.dark-mode {
      .app-header,
      .side-header {
        background-color: $white !important;
      }
    }

    &.app.light-mode.header-light.color-menu {
      .slide-menu li .slide-item:before {
        color: $white !important;
      }
    }
    &.app.dark-mode.header-light.dark-menu {
      .app-header {
        background-color: $white !important;
      }
      .side-header {
        .header-brand1 {
          .desktop-logo {
            display: block !important;
          }
          .toggle-logo,
          .light-logo,
          .light-logo1 {
            display: none !important;
          }
        }
      }
    }
    &.app.sidenav-toggled.dark-mode.color-menu.gradient-header {
      .side-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.light-menu.color-header {
      .app-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.light-menu.dark-header {
      .app-header {
        background-color: $dark-theme !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.color-menu.color-header {
      .app-header {
        background-color: $primary !important;
      }
      .side-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.dark-header.color-menu {
      .side-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.color-menu.header-light {
      .side-header {
        background-color: $primary !important;
      }
      .app-header {
        background-color: $white !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.gradient-header.gradient-menu,
    &.app.dark-mode.gradient-header.gradient-menu {
      .side-header {
        background-color: $primary !important;
      }
    }
    &.app.dark-mode.sidenav-toggled.light-menu,
    &.app.dark-mode.sidenav-toggled.sidenav-toggled-open.light-menu {
      .side-header {
        background-color: $white !important;
        border-bottom: 1px solid $border !important;
        border-right: 1px solid $border !important;
        .header-brand1 {
          .light-logo {
            display: block !important;
          }
          .light-logo1,
          .desktop-logo,
          .toggle-logo {
            display: none !important;
          }
        }
      }
      // .app-header {
      //     background-color: $white !important;
      // }
      .app-sidebar {
        border-right: 1px solid $border-dark !important;
      }
    }
    &.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__icon {
      margin-left: 0px !important;
    }
    &.rtl.sidebar-mini.sidenav-toggled .slide-menu {
      box-shadow: -5px 0px 17px rgba(0, 0, 0, 0.1);
    }
    &.rtl.app.sidenav-toggled .app-header.header {
      padding-right: 130px !important;
      padding-left: 10px !important;
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.light-logo {
          display: block !important;
        }
        &.toggle-logo,
        &.desktop-logo,
        &.light-logo1 {
          display: none !important;
        }
      }
      .side-menu h3 {
        display: none !important;
      }
      &.color-menu .slide-menu li .slide-item:before {
        color: $white !important;
      }
    }
    &.app.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }
      .app-sidebar {
        left: 230px;
      }
      .app-sidebar__overlay {
        visibility: hidden;
      }
    }
    .app-sidebar {
      padding-top: 55px;
    }
    .side-header {
      width: 270px;
      left: 0;
      right: 0;
      top: 0;
      position: fixed;
      z-index: 99;
      background: #fff;
    }
    &.app.sidebar-mini.sidenav-toggled .side-header {
      width: 120px !important;
    }
    &.app.sidebar-mini.sidenav-toggled
      .side-header
      .header-brand-img.light-logo {
      height: 2.5rem;
    }
    &.sidebar-mini footer.footer {
      padding: 1.25rem 1.25rem 1.25rem 270px;
    }
    &.sidebar-mini.sidenav-toggled footer.footer {
      padding: 1.25rem 1.25rem 1.25rem 80px;
    }
  }
  @media (max-width: 991px) {
    &.app {
      overflow-x: hidden;
      .app-sidebar {
        left: -270px;
      }
      .app-sidebar__overlay {
        visibility: hidden;
      }
      &.sidenav-toggled {
        .app-content {
          margin-left: 0;
        }
        .app-sidebar {
          left: 0;
        }
        .app-sidebar__overlay {
          visibility: visible;
        }
      }
      &.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
        left: 0;
      }
      .app-header .header-brand1 {
        width: inherit;
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }
  .app-content {
    min-height: calc(100vh - 50px);
    margin-bottom: 0 !important;
    -webkit-transition: margin-left 0.2s ease;
    -o-transition: margin-left 0.2s ease;
    transition: margin-left 0.2s ease;
    overflow: hidden;
    margin-top: 74px;
  }
  .footer .container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 992px) {
    .app-content {
      margin-left: 270px;
    }
  }
  @media (max-width: 991px) {
    .app-content {
      min-width: 100%;
    }
  }
  @media (max-width: 480px) {
  }
  @media print {
    .app-content {
      margin: 0;
      padding: 0;
      background-color: #fff;
    }
  }
  .app-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 99 !important;
    border-bottom: 1px solid $border;
    background: #ffffff;
    transition: padding-left 0.3s ease;
  }
  @media (max-width: 991.98px) {
    .app-header {
      z-index: 999 !important;
    }
  }
  @media (min-width: 992px) {
    .app-header {
      padding-right: 10px;
      padding-left: 285px;
    }
  }
  @media print {
    .app-header {
      display: none;
    }
  }
  .app-header__logo {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    color: #fff;
    text-align: center;
    font-family: "Niconne";
    padding: 0 15px;
    font-size: 26px;
    font-weight: 400;
    line-height: 50px;
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  @media (min-width: 992px) {
    .app-header__logo {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      display: block;
      width: 230px;
    }
  }
  .app-sidebar__toggle {
    z-index: 1;
    transition: padding-left 0.3s ease;
    padding: 0 9px;
    text-align: center;
    // height: 2.5rem;
    font-size: 1.5rem;
    position: relative;
    right: 7px;
    &:before {
      content: "\e907";
      transition: all 0.25s;
      font-size: 21px;
      font-family: "feather";
    }
    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      bottom: 0;
      border-radius: 50%;
      right: 12px;
      background: rgba($primary-1, 0);
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 991px) {
    .app-sidebar__toggle {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
  }
  .app-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    color: $default-color;
    width: 270px;
    z-index: 99;
    background: #fff;
    -webkit-box-shadow: 0 8px 24px rgba(168, 180, 208, 0.1);
    box-shadow: 0 8px 24px rgba(168, 180, 208, 0.1);
    -webkit-transition: left 0.2s ease, width 0.2s ease;
    -o-transition: left 0.2s ease, width 0.2s ease;
    transition: left 0.2s ease, width 0.2s ease;
    border-right: 1px solid $border;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  @media print {
    .app-sidebar {
      display: none;
    }
  }
  @media (max-width: 991px) {
    .app-sidebar__overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9;
    }
  }
  .app-sidebar__user {
    .dropdown-menu {
      top: 10px !important;
    }
    img {
      box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
      padding: 2px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
    }
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #a8a8a8;
    width: 100%;
    padding: 15px 0 15px;
    display: inline-block;
  }
  .app-sidebar__user-avatar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 15px;
  }
  .app-sidebar__user-name {
    &.text-sm {
      font-size: 12px;
      font-weight: 400;
    }
    font-size: 17px;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
  }
  .app-sidebar__user-designation {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
  }
  .side-menu {
    margin-bottom: 0;
  }
  .side-menu__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.625rem 1.5rem;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
    -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
    transition: border-left-color 0.3s ease, background-color 0.3s ease;
    color: #294865;
    margin: 0px 0px 2px 0px;
    &.active {
      text-decoration: none;
      color: $primary-1;
      &:hover,
      &:focus {
        text-decoration: none;
        color: $primary-1;
      }
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary-1;
      .side-menu__icon,
      .side-menu__label {
        color: $primary-1 !important;
      }
    }
  }
  @media (min-width: 992px) {
    &.app.sidebar-mini.sidenav-toggled .side-menu__label {
      display: block !important;
      font-size: 12px;
      padding-top: 0px;
      margin-top: 0;
    }
  }
  .slide-item {
    &.active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #b5c1d2;
    }
  }
  .slide-menu a.active {
    color: $primary-1;
  }
  .slide-item {
    &.active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary-1 !important;
    }
  }
  .slide-menu li {
    .slide-item {
      &:before {
        content: "\e0e7";
        font-family: "typicons";
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        top: 2px;
        font-size: 10px;
        margin-right: 8px;
        color: #68798b;
      }
    }
    position: relative;
  }
  .slide.is-expanded a {
    text-decoration: none;
  }
  .side-menu .side-menu__icon {
    color: $primary-1;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    margin-right: 8px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 12px;
      bottom: 0;
      border-radius: 50%;
      right: 3px;
      background: rgba($primary-1, 0);
    }
  }
  .side-menu__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .side-menu__label {
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .slide.is-expanded {
    .slide-menu {
      display: block;
    }
    .angle {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  .slide-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 17px;
    font-size: 13.3px;
    color: #74829c;
    .icon {
      margin-right: 5px;
    }
  }
  .angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 2px;
  }
  @media (min-width: 992px) {
    &.sidebar-mini.sidenav-toggled {
      .app-sidebar__user-name,
      .app-sidebar__user-designation,
      .angle,
      .app-sidebar__user-name,
      .user-notification,
      .app-sidebar__user-name,
      .user-info {
        display: none;
      }
      .app-header.header {
        padding-left: 130px !important;
      }
      .side-menu {
        padding: 0 0px 40px 00px;
        .sub-category {
          display: none;
        }
      }
      .side-menu .side-menu__icon {
        background: none !important;
        box-shadow: none;
        margin-right: 0;
        &:after {
          top: 6px;
        }
      }
      .side-badge,
      .slide-menu open.slide-menu hide .side-badge {
        top: 6px;
        right: 40px;
      }
      &.sidebar-mini.sidenav-toggled.user-notification::before {
        background: transparent;
        display: none;
      }
      .app-sidebar__user-name,
      .avatar-xl {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        font-size: 1rem;
        margin-bottom: 0px !important;
      }
      .app-sidebar__user-avatar {
        width: 25px;
        height: 25px;
      }
      .side-menu li .side-menu__item.active:before {
        display: none;
      }
      .app-sidebar__user {
        padding: 12px 0px 12px 0;
        margin-bottom: 0px;
        border-bottom: 1px solid rgba(225, 225, 225, 0.05);
      }
      .profile-img {
        top: 0px;
        right: 19px;
      }
      .app-content {
        margin-left: 120px;
      }
      .app-sidebar {
        left: 0;
        width: 120px !important;
        overflow: hidden;
        &:hover {
          overflow: visible;
        }
      }
      .slide-menu {
        position: absolute;
        opacity: 0;
        z-index: 9;
        visibility: hidden;
        -webkit-transition: visibility 0.3s ease;
        -o-transition: visibility 0.3s ease;
        transition: visibility 0.3s ease;
      }
    }
    &.app.sidebar-mini.sidenav-toggled {
      .side-menu__item {
        padding: 12px 5px;
        text-align: center;
        display: block;
      }
    }
    &.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
      margin: 0 auto;
    }
    &.app.sidebar-mini.sidenav-toggled .nav-badge {
      position: absolute;
      top: 8px;
      right: 28px;
      padding: 0.2rem 0.4rem;
      font-size: 11px;
    }
    &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar {
        width: 120px !important;
        .side-menu {
          padding-top: 0 !important;
          li {
            h3 {
              display: none !important;
            }
            .badge {
              display: none !important;
            }
            .side-menu__icon {
              top: 0 !important;
            }
          }
        }
      }
      .side-header {
        width: 120px !important;
        display: flex !important;
      }
      .light-logo,
      .light-logo1,
      .mobile-light {
        display: none !important;
      }
      .side-menu .side-menu__icon:after {
        top: 6px;
      }
      .side-menu__item {
        display: block;
        padding: 12px 5px;
        text-align: center;
      }
      .side-menu .sub-category {
        display: none !important;
      }
      .side-menu__icon {
        background: none !important;
        box-shadow: none;
        margin-right: 0 !important;
      }
      .side-menu__label {
        display: block !important;
        font-size: 12px;
        padding-top: 0px;
        margin-top: 0;
      }
      .angle {
        display: none;
      }
      .side-badge {
        top: 6px;
        right: 40px;
      }
    }
  }
  .dropdown-menu {
    border-radius: 0;
    &.dropdown-menu-right {
      left: auto;
    }
  }
  .dropdown-item {
    .fa,
    .icon {
      vertical-align: middle;
    }
  }
  .app-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: #fff;
    margin: -30px -30px 30px;
    padding: 20px 30px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    h1 {
      margin: 0;
      font-size: 24px;
      font-weight: 400;
    }
    p {
      margin-bottom: 0;
      font-style: italic;
    }
  }
  @media print {
    .app-title {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .app-title {
      margin: -15px -15px 15px;
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }
  @media (max-width: 480px) {
    .app-title p {
      display: none;
    }
  }
  .app-breadcrumb {
    margin-bottom: 0;
    text-align: right;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    padding: 0;
    text-align: left;
    padding: 0;
    background-color: transparent;
  }
  @media (max-width: 480px) {
    .app-breadcrumb {
      margin-top: 10px;
    }
  }
  &.sidenav-toggled .app-sidebar__user .avatar-md {
    line-height: 2rem;
    font-size: 1rem;
  }
  &.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
    .nav-badge {
      position: absolute;
      top: 5px;
      left: 57px;
      display: block !important;
      padding: 3px 5px !important;
    }
    .nav-badge1 {
      display: none;
    }
  }
  .nav-badge {
    border-radius: 30px;
    padding: 0.4em 0.6em;
    font-size: 12px;
  }
  .user-info {
    .text-dark {
      color: #25252a !important;
      font-weight: 400;
      font-size: 16px;
    }
    .text-muted {
      color: #74829c !important;
    }
  }
  .side-header {
    .header-brand1 {
      text-align: center;
      margin: 0 auto !important;
    }
    .header-brand-img.desktop-logo {
      display: none;
    }
  }
  &.sidebar-mini.sidenav-toggled
    .app-sidebar
    .side-header
    .header-brand-img.desktop-logo {
    display: none;
  }
  .side-header .header-brand-img {
    &.toggle-logo {
      display: none;
    }
    &.light-logo1 {
      display: block !important;
    }
  }
  &.sidebar-mini.sidenav-toggled {
    .app-sidebar .side-header .header-brand-img {
      &.light-logo1 {
        display: none !important;
      }
      &.toggle-logo {
        display: none;
        margin-right: 0;
      }
    }
    .sidebar-navs {
      display: none;
    }
  }
  .side-header {
    display: flex;
    border-bottom: 1px solid $border;
    border-right: 1px solid $border;
    padding: 19px 17px;
    --webkit-transition: left 0.3s ease, width 0.2s ease;
    -o-transition: left 0.3s ease, width 0.2s ease;
    transition: left 0.3s ease, width 0.2s ease;
    height: 75px;
    .header-brand-img {
      &.toggle-logo,
      &.light-logo {
        height: 2.5rem;
      }
    }
  }
  &.sidebar-mini.sidenav-toggled {
    .app-sidebar__toggle {
      padding-left: 10px;
    }
    .mobile-header .app-sidebar__toggle {
      margin: 0;
      margin-top: 5px;
    }
  }
  .user-pic {
    margin-bottom: 0.8rem;
  }
  .side-menu .sub-category {
    color: #74829c;
    margin-bottom: 0.5rem;
    padding: 12px 30px 2px 20px;
    margin-bottom: 0;
    h3 {
      margin-bottom: 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 11px;
      font-weight: 500;
    }
  }
  .side-menu li:not(:first-child) h3 {
    margin-top: 0rem;
  }
  &.sidebar-mini .app-header .light-logo1 {
    display: block !important;
  }
  @media (min-width: 992px) {
    &.sidenav-toggled .header-brand-img.light-logo {
      display: block;
      margin-right: 0;
    }
  }
  .side-badge,
  .slide-menu open.slide-menu hide .side-badge {
    position: absolute;
    top: 18px;
    right: 20px;
    border-radius: 50px;
    padding: 3px 6px 3px;
  }
  /*-- Subslide ---*/
  .sub-side-menu__item {
    padding: 8px 17px;
    display: flex;
    color: #74829c;
    .sub-side-menu__label {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }
  .slide-menu a:before {
    content: "\e0e7";
    margin-right: 10px;
    font-size: 11px;
    position: relative;
    font-family: typicons !important;
    opacity: 0.6;
    top: 2px;
  }
  .sub-slide.is-expanded .sub-angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sub-angle {
    float: right;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    margin-right: 0;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .sub-slide.is-expanded .sub-slide-menu {
    display: block;
  }
  .sub-slide-menu {
    display: none;
    padding: 0 15px 15px 0;
    font-size: 13px !important;
    list-style: none;
  }
  .sub-slide-item {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
    padding: 8px 0 8px 27px !important;
    color: #74829c;
  }
  /*-- Subslide2 ---*/
  .sub-side-menu__item2 {
    padding: 8px 2px 8px 27px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #74829c;
  }
  .sub-slide2 .sub-side-menu__label2 {
    flex: 1 1 auto;
  }
  .sub-angle2 {
    float: right;
    margin-top: -2px;
    margin-right: 0px;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .sub-slide2.is-expanded .sub-angle2 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sub-slide2.is-expanded .sub-slide-menu2 {
    display: block;
  }
  .sub-slide-menu2 {
    display: none;
    padding: 0;
    font-size: 0.8rem !important;
    padding-left: 0;
    list-style: none;
  }
  .sub-slide-item2 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
    padding: 8px 0 8px 40px !important;
    color: #74829c;
  }
  @media (max-width: 991px) {
    .header-brand1 .desktop-logo {
      margin: 0 auto;
      margin-top: 6px;
    }
  }
  &.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open
    .app-sidebar
    .side-header
    .header-brand-img.desktop-logo {
    display: block !important;
  }
  &.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open
    .app-sidebar
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
  }
  &.app.sidebar-mini.sidenav-toggled
    .app-sidebar
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
  }
  &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
    .app-sidebar
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
  }
  &.app.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open
    .app-sidebar
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
  }
  @media (min-width: 992px) {
    &.sidenav-toggled .app-sidebar {
      overflow: visible !important;
      position: absolute !important;
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open
      .side-menu:hover
      .side-menu {
      max-height: inherit !important;
      visibility: visible;
      border-radius: 1px !important;
      transition: none !important;
      padding: 8px 0px;
      opacity: inherit;
      display: block !important;
    }
    .app-sidebar .slide {
      position: relative;
      display: block;
    }
    &.app.sidebar-mini.sidenav-toggled .slide-menu {
      position: absolute;
      left: 108px;
      min-width: 185px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      display: block;
    }
    &.app.sidebar-mini.sidenav-toggled-open .slide-menu {
      position: absolute;
      left: 108px;
      min-width: 185px;
      opacity: 1;
      z-index: 9999;
      visibility: visible;
      top: 0;
      display: block;
    }
    &.app.sidebar-mini.sidenav-toggled-open .slide-menu.open {
      position: absolute;
      left: 108px;
      min-width: 185px;
      opacity: 1;
      z-index: 9999;
      visibility: visible;
      top: 0;
      display: block;
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open
      .slide.is-expanded
      .slide-menu {
      position: absolute;
      left: 108px;
      min-width: 185px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      height: auto !important;
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      position: absolute;
      left: 108px;
      min-width: 185px;
      opacity: 1;
      z-index: 9999;
      visibility: hidden;
      top: 0;
      display: block;
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open
      .slide:hover
      .slide-menu {
      visibility: visible;
      display: block !important;
      height: auto !important;
    }
    &.sidebar-mini.sidenav-toggled .slide-menu {
      position: absolute;
      background: #ffffff;
      opacity: 1;
      visibility: visible;
      z-index: 999;
      left: 72px;
      transition: none !important;
      display: block !important;
      box-shadow: 8px 8px 17px rgba(0, 0, 0, 0.1);
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu-label1 {
      display: none;
    }
    &.sidenav-toggled .app-content {
      min-height: 1262px;
    }
  }
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: none;
  }
  &.sidebar-mini.sidenav-toggled .slide-menu {
    max-height: inherit !important;
    padding-bottom: 8px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 8px !important;
    height: auto !important;
    overflow: hidden !important;
  }
  &.color-menu .side-header .light-logo {
    display: none !important;
  }
  &.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    border-radius: 0px 6px 6px 0px !important;
  }
  &.rtl.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    border-radius: 6px 0px 0px 6px !important;
  }
  @media (min-width: 992px) {
    &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__icon {
      color: $default-color;
    }
    &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
      .side-menu__item:hover
      .side-menu__icon,
    &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
      .side-menu__item:focus
      .side-menu__icon {
      color: var(--primary-bg-color);
    }
  }

  @media (min-width: 768px) {
    &.sidebar-mini.sidenav-toggled .app-sidebar__user,
    &.sidebar-mini.sidenav-toggled .side-menu {
      padding-top: 0px !important;
    }
  }
}
@media (min-width: 992px) {
  .app.sidebar-mini.hover-submenu.dark-mode.sidenav-toggled.sidenav-toggled-open,
  .hover-submenu.light-mode.color-header.color-menu.sidenav-toggled.sidenav-toggled-open,
  .app.hover-submenu.sidenav-toggled.color-menu.sidenav-toggled-open,
  .app.dark-mode.hover-submenu.header-light.dark-menu.sidenav-toggled {
    .app-sidebar .side-header {
      .header-brand1 {
        .desktop-logo,
        .light-logo,
        .light-logo1 {
          display: none !important;
        }
        .toggle-logo {
          display: block !important;
        }
      }
    }
  }
  .app.sidebar-mini.dark-mode.hover-submenu.sidenav-toggled.light-menu.sidenav-toggled-open {
    .slide-menu li .slide-item:before {
      color: #294865 !important;
    }
  }
  .app.hover-submenu.sidenav-toggled.dark-menu.sidenav-toggled-open {
    .slide-menu li .slide-item:before {
      color: $white !important;
    }
  }
}
.dark-mode {
  &.hover-submenu .app-header {
    background: $dark-body !important;
    border-bottom: 1px solid $border-dark !important;
  }
  &.hover-submenu .app-sidebar {
    border-right: 1px solid $border-dark !important;
  }
  @media (min-width: 992px) {
    &.hover-submenu .side-header {
      background: $dark-body !important;
      border-bottom: 0;
      border-right: 1px solid $border-dark;
    }
    @supports (
      (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
    ) {
      &.hover-submenu .side-header {
        background: transparent !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-bottom: 0;
        border-right: 1px solid $border-dark;
      }
    }
    &.rtl.hover-submenu .side-header {
      border-left: 1px solid $border-dark;
      border-right: inherit;
    }
  }
  &.hover-submenu.sidebar-mini .app-header .light-logo1 {
    display: none !important;
  }
  &.hover-submenu {
    .app-sidebar {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    }
  }
  &.app.hover-submenu.sidenav-toggled {
    .sticky.stickyClass {
      .side-header {
        background-color: $dark-body;
      }
      @supports (
        (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))
      ) {
        .side-header {
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          background-color: transparent;
        }
      }
    }
  }
}
@media (max-width: 991.98px) {
  .dark-mode.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
    background: transparent !important;
  }
}
@media (min-width: 992px) {
  .dark-mode.hover-submenu.sidebar-mini.sidenav-toggled {
    .slide-menu {
      background: $dark-body !important;
      border: 1px solid $border-dark;
      border-left: 0px !important;
    }
  }
}
@media (min-width: 992px) {
  .app.hover-submenu.sidenav-toggled {
    .slide-menu {
      display: none;
      padding: 0px;
      font-size: 0.8rem !important;
      padding-left: 3px;
      margin-left: 12px;
    }
  }
}
.app.hover-submenu {
  .slide-menu {
    display: none;
    padding: 5px 30px;
    font-size: 0.8rem !important;
    padding-left: 20px;
    margin-left: 20px;
  }
}
.rtl.app.hover-submenu {
  .slide-menu {
    padding-right: 20px !important;
    padding-left: inherit !important;
    margin-right: 20px !important;
    margin-left: inherit !important;
  }
}
@media (min-width: 992px) {
  .rtl.app.hover-submenu.sidenav-toggled {
    .slide-menu {
      padding-right: 3px !important;
      padding-left: inherit !important;
      margin-right: 12px !important;
      margin-left: inherit !important;
    }
  }
  .rtl.dark-mode.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
    border-right: 0px !important;
    border: 1px solid $border-dark !important;
  }
}
@media (min-width: 992px) {
  .hover-submenu.bg-img1,
  .hover-submenu.bg-img2,
  .hover-submenu.bg-img3,
  .hover-submenu.bg-img4 {
    &.dark-mode .side-header {
      background: transparent !important;
      border-bottom: 0;
      border-right: 0px solid var(--dark-border);
    }
  }
}
@media (min-width: 992px) {
  .bg-img1,
  .bg-img2,
  .bg-img3,
  .bg-img4 {
    &.dark-mode.sidebar-mini.sidenav-toggled.hover-submenu {
      .side-header {
        background-color: $dark-body !important;
      }
      @supports (
        (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
      ) {
        .side-header {
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          background-color: transparent !important;
        }
      }
    }
  }
}
