progress {
  vertical-align: baseline;
}
/*-----progress-----*/

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.703125rem;
  background-color: #ebecf5;
  border-radius: 7px;
  box-shadow: 0 5px 10px rgba(227, 228, 237, 0.3);
  position: relative;
}
.mixed-color-progress {
  .bar-end {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: $white;
  text-align: center;
  white-space: nowrap;
  border-radius: 7px;
  transition: width 0.6s ease;
}
.current-progressbar {
  margin-top: 14.5px;
}
.progress-content {
  margin-bottom: 16.5px;
  &:last-child {
    margin-bottom: 0;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
/*------ progress sizes ------*/

.progress-xs {
  height: 0.25rem;
  .progress-bar {
    height: 0.25rem;
  }
}
.progress-sm {
  height: 0.5rem;
  .progress-bar {
    height: 0.5rem;
  }
}
.progress-lg {
  height: 1.25rem;
  .progress-bar {
    height: 1.25rem;
  }
}
.progress-bar-indeterminate {
  &:after {
    content: "";
    position: absolute;
    background-color: inherit;
    left: 0;
    will-change: left, right;
    top: 0;
    bottom: 0;
  }
  &:before {
    content: "";
    position: absolute;
    background-color: inherit;
    left: 0;
    will-change: left, right;
    top: 0;
    bottom: 0;
    -webkit-animation: indeterminate 2.1s
      cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
      infinite;
  }
  &:after {
    -webkit-animation: indeterminate-short 2.1s
      cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
      infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
  }
}
.progress-info {
  display: block;
  position: absolute;
  left: 0;
  top: -1.75rem;
  font-size: 0.875rem;
}

/*----- Skeleton Loader -----*/
.skeleton-loader {
  border-radius: 0.125rem;
  display: block;
  height: 0.75rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(28, 125, 167, 0.1);

  &__media {
    .skeleton-loader {
      border-radius: 50%;
      height: 7.5rem;
      width: 7.5rem;
    }

    &-sm {
      .skeleton-loader {
        height: 3.25rem;
        width: 3.25rem;
        border-radius: 50%;
      }
    }
    &-xs {
      .skeleton-loader {
        height: 1.326rem;
        width: 1.375rem;
        border-radius: 50%;
      }
    }
    &-rounded {
      .skeleton-loader {
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 50%;
      }
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

// OBJECTS

.o-media {
  display: flex;

  &__body {
    flex-grow: 1;
    margin-left: 1em;
  }
}
