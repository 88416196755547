.mbsc-textfield {
    font-size: inherit !important;
    font-family: inherit;
}
.mbsc-ios.mbsc-textfield,
.mbsc-windows.mbsc-textfield {
    padding: 0px 1.25rem 0px 0px !important;
    height: auto !important;
    border: 1px solid transparent !important;

    &.mbsc-focus {
        box-shadow: none !important;
    }
}
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after,
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before,
.mbsc-windows.mbsc-form-control-wrapper.mbsc-ltr:after,
.mbsc-windows.mbsc-form-control-wrapper.mbsc-ltr:before {
    border: 0px;
    border-top: 0px;
    border-bottom: 0px;
}
.form-control {
    .mbsc-ios.mbsc-textfield,
    .mbsc-windows.mbsc-textfield  {
        line-height: normal !important;
        max-height: 22px !important;
    }
}

