.header {
  &__search {
    padding: 0 1rem 0 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    position: relative;

    &-icon {
      color: $white;
      left: 3rem;
      position: absolute;
      top: 0.625rem;
      z-index: 1;
      opacity: 0.75;
    }

    &-input {
      background: transparent;
      border-color: rgba(255, 255, 255, 0.5);
      color: $white;
      padding-left: 2.5rem;
      border-radius: 50rem;

      &:focus {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.75);
        color: $white;
        outline: 0;
      }
    }
  }
}
