/*------ Card -------*/

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  border: 1px solid var(--border);
  background-clip: border-box;
  border-radius: 0.25rem;
  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }
    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
      }
    }
  }
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
  margin: 0;
  position: relative;
}
.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link {
  &:hover {
    text-decoration: none;
  }
  + .card-link {
    margin-left: 1.5rem;
  }
}
.card-header {
  &:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  }
  + .list-group .list-group-item:first-child {
    border-top: 0;
  }
}
.card-footer {
  padding: 1.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #eaedf1;
  color: #6e7687;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  background-color: $black-4;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px);
}
.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  .card {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    .card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 0.75rem;
      margin-bottom: 0;
      margin-left: 0.75rem;
    }
  }
}
.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  > .card {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    > .card {
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
      + .card {
        margin-left: 0;
        border-left: 0;
      }
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .card-img-top,
        .card-header {
          border-top-left-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }
      &:only-child {
        border-radius: 3px;
        .card-img-top,
        .card-header {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
      &:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
        .card-img-top,
        .card-img-bottom,
        .card-header,
        .card-footer {
          border-radius: 0;
        }
      }
    }
  }
}
.card-columns .card {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    .card {
      display: inline-block;
      width: 100%;
    }
  }
}
.card-blog-overlay {
  background: url("../../images/media/thumbnails/thumb2.jpg");
  background-size: cover;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background: $black-5;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
  }
}
.card-blog-overlay1:before,
.card-blog-overlay2:before {
  content: "";
  display: block;
  position: absolute;
  background: $black-5;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}
.card-blog-overlay1 {
  background: url("../../images/media/thumbnails/thumb3.jpg");
  background-size: cover;
}
.card-blog-overlay2 {
  background: url("../../images/media/22.jpg");
  background-size: cover;
}
/*------ Default Card Values -------*/

.card {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  .card {
    border: 1px solid #eaedf1;
  }
}
@media print {
  .card {
    box-shadow: none;
    border: none;
  }
}
.card-body {
  + .card-body {
    border-top: 1px solid #eaedf1;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
@media print {
  .card-body {
    padding: 0;
  }
}
.card-body-scrollable {
  overflow: auto;
}
.card-footer,
.card-bottom {
  padding: 0.8rem 1.6rem;
  background: none;
}
.card-header {
  background: none;
  padding: 0.8rem 1.6rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: none;
  .card-title {
    margin-bottom: 0;
  }
  &.border-0 + .card-body {
    padding-top: 0;
  }
}
@media print {
  .card-header {
    display: none;
  }
}
.card-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.reg {
  text-align: center;
  font-size: 50px;
  color: #2e1170;
  float: right;
}
.card-title {
  a {
    color: inherit;
  }
  &:only-child {
    margin-bottom: 0;
  }
  small {
    color: #9aa0ac;
    font-size: 0.875rem;
    display: block;
    margin: -0.75rem 0 1rem;
    line-height: 1.1;
    font-weight: 400;
  }
}
.card-subtitle {
  color: #9aa0ac;
  font-size: 0.875rem;
  display: block;
  margin: -0.75rem 0 1rem;
  line-height: 1.1;
  font-weight: 400;
}
.card-table {
  margin-bottom: 0;
  tr {
    &:first-child {
      td,
      th {
        border-top: 0;
      }
    }
    td:first-child,
    th:first-child {
      padding-left: 1.5rem !important;
    }
    td:last-child,
    th:last-child {
      padding-right: 1.5rem;
    }
  }
}
.card-body + .card-table {
  border-top: 1px solid #eaedf1;
}
.card-profile .card-header {
  height: 5rem;
  background-size: cover;
  border: 0;
}
.card-link + .card-link {
  margin-left: 1rem;
}
.card-body + .card-list-group {
  border-top: 1px solid #eaedf1;
}
.card-list-group .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &:last-child {
    border-bottom: 0;
  }
  &:first-child {
    border-top: 0;
  }
}
.card-header-tabs {
  margin: -1.25rem 0;
  border-bottom: 0;
  line-height: 2rem;
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  .nav-item {
    margin-bottom: 1px;
  }
}
.card-header-pills {
  margin: -0.75rem 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.card-aside {
  -ms-flex-direction: row;
  flex-direction: row;
}
.card-aside-column {
  min-width: 5rem;
  width: 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  background: no-repeat center/cover;
}
.card-value {
  font-size: 1rem;
  line-height: 3.4rem;
  height: 3.4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
  i {
    vertical-align: middle;
    font-size: 30px;
  }
}
.card-chart-bg {
  height: 4rem;
  margin-top: -1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-right: -0.5rem;
  color: #9aa0ac;
  -ms-flex-item-align: center;
  align-self: center;
  a:not(.btn) {
    margin-left: 0.5rem;
    color: #76839a;
    display: inline-block;
    min-width: 1rem;
    &:hover {
      text-decoration: none;
      color: #6e7687;
    }
    i {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  .dropdown-toggle:after {
    display: none;
  }
}
.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
  > .card {
    width: 100%;
    min-width: 100%;
  }
}
.card-img-absolute {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin-left: -28px;
}
.card-image {
  position: absolute;
  top: 0;
  height: 100%;
  margin-left: -126px;
}
/*Card options*/

.card-collapsed {
  > :not(.card-header):not(.card-status) {
    display: none;
  }
  .card-options-collapse i:before {
    content: "\e92d";
  }
}
.card-fullscreen {
  .card-options-fullscreen i:before {
    content: "\e992";
  }
  .card-options-remove {
    display: none;
  }
}
/*Card maps*/

.card-map {
  height: 15rem;
  background: #e9ecef;
}
.card-map-placeholder {
  background: no-repeat center;
}
/**Card tabs*/

.card-tabs {
  display: -ms-flexbox;
  display: flex;
}
.card-tabs-bottom .card-tabs-item {
  border: 0;
  &.active {
    border-top-color: $white;
  }
}
.card-tabs-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: block;
  padding: 1.4rem 1.5rem;
  color: inherit;
  overflow: hidden;
}
a.card-tabs-item {
  background: #fafbfc;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    z-index: 1;
  }
  &.active {
    background: $white;
    border-bottom-color: $white;
  }
}
/**Card status*/

.card-status {
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  height: 3px;
  border-radius: 7px 7px 0 0;
}
.card-status-left {
  right: auto;
  bottom: 0;
  height: auto;
  width: 3px;
  border-radius: 3px 0 0 3px;
}
/**Card icon*/

.card-icon {
  width: 3rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
}
/**Card fullscreen*/

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}
/**Card alert*/

.card-alert {
  border-radius: 0;
  margin: -1px -1px 0;
}
.card-category {
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 0.5rem;
  background: #f1f1f9;
  padding: 0.35rem;
  border-radius: 5px;
}
.item-card {
  .cardbody {
    position: relative;
    padding: 30px 15px;
    border-radius: 0 0 0.2rem 0.2rem;
  }
  .cardtitle {
    span {
      display: block;
      font-size: 0.75rem;
    }
    a {
      color: #707070;
      font-weight: 500;
      text-decoration: none;
    }
  }
  .cardprice {
    position: absolute;
    top: 30px;
    right: 15px;
    span {
      &.type--strikethrough {
        opacity: 0.7;
        text-decoration: line-through;
      }
      display: block;
      color: #707070;
    }
  }
}
.card-padding {
  padding: 14px 2px 2px 11px;
}
.card-items {
  padding: 10px;
}
.card .box {
  .img img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }
  h2 {
    font-size: 20px;
    color: #262626;
    margin: 20px auto;
    span {
      font-size: 14px;
      color: $white;
      display: inline-block;
      padding: 4px 10px;
      border-radius: 15px;
    }
  }
  p {
    color: #262626;
  }
}
.card-counter {
  padding: 20px 10px;
  background-color: $white;
  height: 100px;
  border-radius: 7px;
  transition: 0.3s linear all;
  margin-bottom: 1.5rem;
  &:hover {
    transition: 0.3s linear all;
  }
  &.primary {
    color: #fff;
  }
  &.secondary {
    background: linear-gradient(to bottom right, #fbc434 0%, #f66b4e 100%);
    color: #fff;
  }
  &.success {
    background: linear-gradient(to bottom right, #62fb62, #21a544);
    color: #fff;
  }
  &.info {
    background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%);
    color: #fff;
  }
  i {
    font-size: 4rem;
    opacity: 0.3;
  }
  .count-numbers {
    position: absolute;
    right: 35px;
    top: 10px;
    font-size: 32px;
    display: block;
  }
  .count-name {
    position: absolute;
    right: 35px;
    top: 65px;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
  }
}
.card-label {
  border: 0;
  &.list-group-item {
    border-bottom: 0;
  }
}
.card-img-holder i {
  font-size: 45px;
}
.card-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.card-aside-img {
  img {
    width: 80px;
    height: 80px;
    vertical-align: middle;
  }
  &.wrap-border img {
    border: 1px solid rgba(167, 180, 201, 0.3);
    padding: 3px;
  }
  position: relative;
  a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.card-item-desc {
  padding: 15px;
  h6 {
    margin-top: 5px;
  }
}
.card-blogs .card {
  border: none;
  box-shadow: none;
}
.card-blogs1 {
  .card {
    box-shadow: none;
    border-bottom: 1px solid rgba(167, 180, 201, 0.3);
    padding-bottom: 15px;
    margin-bottom: 10px;
    &:last-child {
      box-shadow: none;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .card-item-desc .card-item-price {
    .newprice {
      font-weight: 600;
    }
    .oldprice {
      font-size: 14px;
    }
  }
}
.card-blogs2 .card {
  border: none;
  box-shadow: none;
}
/*------ Card -------*/

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-radius: 5px;
  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }
    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
      }
    }
  }
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  position: relative;
}
.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link {
  &:hover {
    text-decoration: none;
  }
  + .card-link {
    margin-left: 1.5rem;
  }
}
.card-header {
  &:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  }
  + .list-group .list-group-item:first-child {
    border-top: 0;
  }
}
.card-footer {
  padding: 0.8rem 1.6rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #eaedf1;
  color: #6e7687;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  background-color: $black-4;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px);
}
.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  .card {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    .card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 0.75rem;
      margin-bottom: 0;
      margin-left: 0.75rem;
    }
  }
}
.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  > .card {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    > .card {
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
      + .card {
        margin-left: 0;
        border-left: 0;
      }
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .card-img-top,
        .card-header {
          border-top-left-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }
      &:only-child {
        border-radius: 3px;
        .card-img-top,
        .card-header {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
      &:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
        .card-img-top,
        .card-img-bottom,
        .card-header,
        .card-footer {
          border-radius: 0;
        }
      }
    }
  }
}
.card-columns .card {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    .card {
      display: inline-block;
      width: 100%;
    }
  }
}
.card-blog-overlay {
  background: url("../../images/media/thumbnails/thumb2.jpg");
  background-size: cover;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background: $black-5;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
  }
}
.card-blog-overlay1:before,
.card-blog-overlay2:before {
  content: "";
  display: block;
  position: absolute;
  background: $black-5;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}
.card-blog-overlay1 {
  background: url("../../images/media/thumbnails/thumb3.jpg");
  background-size: cover;
}
.card-blog-overlay2 {
  background: url("../../images/media/22.jpg");
  background-size: cover;
}
/*------ Default Card Values -------*/

.card {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  .card {
    border: 1px solid #eaedf1;
  }
}
@media print {
  .card {
    box-shadow: none;
    border: none;
  }
}
.card-body {
  + .card-body {
    border-top: 1px solid #eaedf1;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
@media print {
  .card-body {
    padding: 0;
  }
}
.card-body-scrollable {
  overflow: auto;
}
.card-footer,
.card-bottom {
  padding: 0.8rem 1.6rem;
  background: none;
}
.card-header {
  background: none;
  padding: 0.8rem 1.6rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  background-color: transparenrt;
  .card-title {
    margin-bottom: 0;
    font-family: "Rubik";
  }
  &.border-0 + .card-body {
    padding-top: 0;
  }
}
@media print {
  .card-header {
    display: none;
  }
}
.card-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-family: "Rubik";
}
.reg {
  text-align: center;
  font-size: 50px;
  color: #2e1170;
  float: right;
}
.card-title {
  a {
    color: inherit;
  }
  &:only-child {
    margin-bottom: 0;
  }
  small {
    color: #9aa0ac;
    font-size: 0.875rem;
    display: block;
    margin: -0.75rem 0 1rem;
    line-height: 1.1;
    font-weight: 400;
  }
}
.card-subtitle {
  color: #9aa0ac;
  font-size: 0.875rem;
  display: block;
  margin: -0.75rem 0 1rem;
  line-height: 1.1;
  font-weight: 400;
}
.card-table {
  margin-bottom: 0;
  tr {
    &:first-child {
      td,
      th {
        border-top: 0;
      }
    }
    td:first-child,
    th:first-child {
      padding-left: 1.5rem;
    }
    td:last-child,
    th:last-child {
      padding-right: 1.5rem;
    }
  }
}
.card-body + .card-table {
  border-top: 1px solid #eaedf1;
}
.card-profile .card-header {
  height: 5rem;
  background-size: cover;
  border: 0;
}
.card-link + .card-link {
  margin-left: 1rem;
}
.card-body + .card-list-group {
  border-top: 1px solid #eaedf1;
}
.card-list-group .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &:last-child {
    border-bottom: 0;
  }
  &:first-child {
    border-top: 0;
  }
}
.card-header-tabs {
  margin: -1.25rem 0;
  border-bottom: 0;
  line-height: 2rem;
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  .nav-item {
    margin-bottom: 1px;
  }
}
.card-header-pills {
  margin: -0.75rem 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.card-aside {
  -ms-flex-direction: row;
  flex-direction: row;
}
.card-aside-column {
  min-width: 5rem;
  width: 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  background: no-repeat center/cover;
}
.card-value {
  font-size: 1rem;
  line-height: 3.4rem;
  height: 3.4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
  i {
    vertical-align: middle;
    font-size: 30px;
  }
}
.card-chart-bg {
  height: 4rem;
  margin-top: -1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-right: -0.5rem;
  color: #9aa0ac;
  -ms-flex-item-align: center;
  align-self: center;
  a:not(.btn) {
    margin-left: 0.5rem;
    color: #76839a;
    display: inline-block;
    min-width: 1rem;
    &:hover {
      text-decoration: none;
      color: #6e7687;
    }
    i {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  .dropdown-toggle:after {
    display: none;
  }
}
.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
  > .card {
    width: 100%;
    min-width: 100%;
  }
}
.card-img-absolute {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin-left: -28px;
}
.card-image {
  position: absolute;
  top: 0;
  height: 100%;
  margin-left: -126px;
}
/*Card options*/

.card-collapsed {
  > :not(.card-header):not(.card-status) {
    display: none;
  }
  .card-options-collapse i:before {
    content: "\e92d";
  }
}
.card-fullscreen {
  .card-options-fullscreen i:before {
    content: "\e992";
  }
  .card-options-remove {
    display: none;
  }
}
/*Card maps*/

.card-map {
  height: 15rem;
  background: #e9ecef;
}
.card-map-placeholder {
  background: no-repeat center;
}
/**Card tabs*/

.card-tabs {
  display: -ms-flexbox;
  display: flex;
}
.card-tabs-bottom .card-tabs-item {
  border: 0;
  &.active {
    border-top-color: $white;
  }
}
.card-tabs-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: block;
  padding: 1.4rem 1.5rem;
  color: inherit;
  overflow: hidden;
}
a.card-tabs-item {
  background: #fafbfc;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    z-index: 1;
  }
  &.active {
    background: $white;
    border-bottom-color: $white;
  }
}
/**Card status*/

.card-status {
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  height: 3px;
  border-radius: 7px 7px 0 0;
}
.card-status-left {
  right: auto;
  bottom: 0;
  height: auto;
  width: 3px;
  border-radius: 3px 0 0 3px;
}
/**Card icon*/

.card-icon {
  width: 3rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
}
/**Card fullscreen*/

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
}
/**Card alert*/

.card-alert {
  border-radius: 0;
  margin: -1px -1px 0;
}
.card-category {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 0 0 0.5rem;
  background: #f1f1f9;
}
.item-card {
  .cardbody {
    position: relative;
    padding: 30px 15px;
    border-radius: 0 0 0.2rem 0.2rem;
  }
  .cardtitle {
    span {
      display: block;
      font-size: 0.75rem;
    }
    a {
      color: #707070;
      font-weight: 500;
      text-decoration: none;
    }
  }
  .cardprice {
    position: absolute;
    top: 30px;
    right: 15px;
    span {
      &.type--strikethrough {
        opacity: 0.7;
        text-decoration: line-through;
      }
      display: block;
      color: #707070;
    }
  }
}
.card-padding {
  padding: 14px 2px 2px 11px;
}
.card-items {
  padding: 10px;
}
.card .box {
  .img img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }
  h2 {
    font-size: 20px;
    color: #262626;
    margin: 20px auto;
    span {
      font-size: 14px;
      color: $white;
      display: inline-block;
      padding: 4px 10px;
      border-radius: 15px;
    }
  }
  p {
    color: #262626;
  }
}
.card-counter {
  padding: 20px 10px;
  background-color: $white;
  height: 100px;
  border-radius: 7px;
  transition: 0.3s linear all;
  margin-bottom: 1.5rem;
  &:hover {
    transition: 0.3s linear all;
  }
  &.primary {
    color: #fff;
  }
  &.secondary {
    background: linear-gradient(to bottom right, #fbc434 0%, #f66b4e 100%);
    color: #fff;
  }
  &.success {
    background: linear-gradient(to bottom right, #62fb62, #21a544);
    color: #fff;
  }
  &.info {
    background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%);
    color: #fff;
  }
  i {
    font-size: 4rem;
    opacity: 0.3;
  }
  .count-numbers {
    position: absolute;
    right: 35px;
    top: 10px;
    font-size: 32px;
    display: block;
  }
  .count-name {
    position: absolute;
    right: 35px;
    top: 65px;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
  }
}
.card-label {
  border: 0;
  &.list-group-item {
    border-bottom: 0;
  }
}
.card-img-holder i {
  font-size: 45px;
}
.card-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.card-aside-img {
  img {
    width: 80px;
    height: 80px;
    vertical-align: middle;
  }
  &.wrap-border img {
    border: 1px solid rgba(167, 180, 201, 0.3);
    padding: 3px;
  }
  position: relative;
  a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.card-item-desc {
  padding: 15px;
  h6 {
    margin-top: 5px;
  }
}
.card-blogs .card {
  border: none;
  box-shadow: none;
}
.card-blogs1 {
  .card {
    box-shadow: none;
    border-bottom: 1px solid rgba(167, 180, 201, 0.3);
    padding-bottom: 15px;
    margin-bottom: 10px;
    &:last-child {
      box-shadow: none;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .card-item-desc .card-item-price {
    .newprice {
      font-weight: 600;
    }
    .oldprice {
      font-size: 14px;
    }
  }
}
.card-blogs2 .card {
  border: none;
  box-shadow: none;
}
.card.card-collapsed {
  .card-header {
    border-bottom: 0px !important;
  }
}

.card-style {
  border: 1px solid rgba($secondary, 0.5) !important;
  color: $dark;
  min-height: 101px;
  overflow: hidden;
  transition: 0.3s linear all;

  &:before {
    content: "";
    background: rgba($secondary, 0.1);
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    position: absolute;
    right: -2rem;
    bottom: -2rem;
    transition: 0.3s linear all;
  }
  &:after {
    content: "\f178";
    color: $secondary;
    position: absolute;
    right: 0.375rem;
    bottom: 0.375rem;
    font: normal normal normal 14px/1 FontAwesome;
    transition: 0.3s linear all;
  }

  &:hover {
    background: $white;
    border: 1px solid $primary !important;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

    h4,
    .icon {
      color: $primary;
    }

    &:before {
      content: "";
      background: $primary;
    }
    &:after {
      color: $white;
    }
  }
}
.card-style2 {
  border: 0.0625rem solid $border;
  background: $white;
  border-radius: 0.25rem;
  display: block;
  transition: all 0.2s linear;
  overflow: hidden;

  &__figure {
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
    display: block;
    padding: 1rem 1rem 0 1rem;
    align-items: center;
    text-align: center;
    height: 10rem;
    overflow: hidden;
  }

  &__figure img {
    height: 100%;
    max-height: 10rem;
  }

  &__img {
    display: block;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    height: 9rem;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;

    &:before {
      background: rgb(255,255,255);
      background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(8,48,80,1) 100%);
      bottom: 50%;
      content: '';
      left: 50%;
      opacity: 0;
      position: absolute;
      right: 50%;
      top: 50%;
      z-index: 1;
      transition: all 0.2s linear;
    }
  }

  &__img i {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    font-size: 1rem;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 2;
  }

  &__info {
    display: block;
    padding: 1rem;
    position: relative;
  }
  &__title {
    color: #083050;
    display: block;
    font-size: 0.875rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__title label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__date {
    color: #89879f;
    display: block;
    margin-top: 0.25rem;
    padding-left: 1.25rem;
    padding-right: 2rem;
    position: relative;
    font-size: 0.75rem;
  }

  &__action {
    border: none;
    background: none;
    position: absolute;
    right: -0.75rem;
    top: -0.125rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
  }

  &__action-item {
    border-top: 0.0625rem solid #eee;
    display: block;
    padding: 0.25rem 0 0.25rem 1.25rem;
    font-size: 0.875rem;
    position: relative;
    cursor: pointer;
  }

  &__action-item:first-child {
    border-top: none;
  }

  &__action-item i {
    color: #083050;
    position: absolute;
    left: 0;
    top: 0.375rem;
  }

  &__icon {
    fill: #e4e7ec;
    stroke: #e4e7ec;
    position: absolute;
    font-size: 1rem;
    height: 6rem;
    width: 6rem;
    left: calc(50% - 3rem);
    top: calc(50% - 3rem);
    z-index: 2;
    transition: all 0.2s linear;

    &-info {
      font-size: 0.75rem;
      display: inline-block;
      background-color: #e4e7ec;
      padding: 0.25rem 1rem;
      position: absolute;
      left: calc(50% - 3rem);
      top: 50%;
      border-radius: 0.25rem;
      z-index: 3;
      transition: all 0.2s linear;
    }
  }

  &__img .fa-file-image-o,
  &__date .fa-file-image-o,
  &__title .fa-file-image-o,
  .info-image {
    color: #d93025;
  }
  &__img .fa-file-pdf-o,
  &__date .fa-file-pdf-o,
  &__title .fa-file-pdf-o,
  .info-pdf {
    color: #f40f02;
  }
  &__img .fa-file-excel-o,
  &__date .fa-file-excel-o,
  &__title .fa-file-excel-o,
  .info-excel {
    color: #0f9d58;
  }
  &__img .fa-file-word-o,
  &__date .fa-file-word-o,
  &__title .fa-file-word-o,
  .info-word {
    color: #1a73e8;
  }
  &__img .fa-file-text-o,
  &__date .fa-file-text-o,
  &__title .fa-file-text-o,
  .info-text {
    color: #5ccecd;
  }
  &__img .fa-file-video-o,
  &__date .fa-file-video-o,
  &__img .fa-file-audio-o,
  &__date .fa-file-audio-o,
  &__title .fa-file-video-o,
  &__title .fa-file-audio-o,
  .file-icon .fa-file-audio-o,
  .info-audio {
    color: #f40f02;
  }
  &__img .fa-file-archive-o,
  &__date .fa-file-archive-o,
  &__title .fa-file-archive-o,
  &__img .fa-folder-open,
  &__date .fa-folder-open,
  &__title .fa-folder-open,
  .file-icon .fa-file-archive-o,
  .info-folder {
    color: #ff9100;
  }
  &__img .fa-file-code-o,
  &__date .fa-file-code-o,
  &__title .fa-file-code-o,
  .info-code {
    color: #ab75e9;
  }
  &__img .fa-file-o,
  &__date .fa-file-o,
  &__title .fa-file-o,
  .info-file {
    color: #666666;
  }

  
  &:hover {
    border: 0.0625rem solid #083050;
    cursor: pointer;

    .card-style2__img {
  
      &:before {
        bottom: 0;
        left: 0;
        opacity: 0.1;
        right: 0;
        top: 0;
        border-radius: 0.5rem;
      }

      i {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.card-style3 {
  position: relative;
  transition: 0.3s linear all;

  &:before {
    content: '';
    position: absolute;
    left: -0.5rem;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    background-color: $info;
    opacity: 0;
  }

  &:hover {
    background-color: $info;
    border-radius: 0 0.5rem 0.5rem 0;
    border-right: 1px solid transparent !important;

    &:before {
      opacity: 1;
    }
  }
}
  .info-image {
    color: #d93025;
  }
  .info-pdf {
    color: #f40f02;
  }
  .info-excel {
    color: #0f9d58;
  }
  .info-word {
    color: #1a73e8;
  }
  .info-text {
    color: #5ccecd;
  }
  .info-audio {
    color: #f40f02;
  }
  .info-folder {
    color: #ff9100;
  }
  .info-code {
    color: #ab75e9;
  }
  .info-file {
    color: #666666;
  }

.card-btn {
  cursor: pointer;
  opacity: 0.75;

  .card-btn__input {
    display: none;
  }

  &.is-active {
    opacity: 1;
    border: 1px solid $dark !important;

    .card-btn__input {
      display: block;
    }
  }
}
@media (max-width: 767px) {
  .card {
    margin-bottom: 1rem;
  }
}
