/*Color variables*/
/*Gradient variables*/
/*Background variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*text variables*/
@import "../iconfonts/font-awesome/css/font-awesome.min";
@import "../iconfonts/glyphicons/glyphicon";
@import "../iconfonts/ionicons/ionicons";
@import "../iconfonts/linearicons/Linearicons";
@import "../iconfonts/materialdesignicons/materialdesignicons";
@import "../iconfonts/pe-icon-7-stroke/Pe-icon-7";
@import "../iconfonts/simple-line-icons/simple-line-icons";
@import "../iconfonts/themify/themify";
@import "../iconfonts/typicons/typicons";
@import "../iconfonts/weathericons/weathericons";
@import "../iconfonts/feather/feather";
@import "../plugins/iconfonts/remixicon/remixicon";
