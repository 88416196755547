/*----- Range slider -------*/

.range {
  display: table;
  position: relative;
  height: 25px;
  margin-top: 20px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px $black-1;
  box-shadow: inset 0 1px 2px $black-1;
  cursor: pointer;
}

.range input[type="range"] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  display: table-cell;
  width: 100%;
  background-color: transparent;
  height: 25px;
  cursor: pointer;
}

.range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  width: 11px;
  height: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px;
  background-color: rgba(153, 153, 153, 1);
}

.range input[type="range"]::-moz-slider-thumb {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  width: 11px;
  height: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px;
  background-color: rgba(153, 153, 153, 1);
}

.range output {
  display: table-cell;
  padding: 3px 5px 2px;
  min-width: 40px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(153, 153, 153, 1);
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.range input[type="range"] {
  outline: none;
}

.range.range-success input[type="range"]::-webkit-slider-thumb {
  background-color: $green;
}

.range.range-success input[type="range"]::-moz-slider-thumb {
  background-color: $green;
}

.range.range-success output {
  background-color: $green;
}

.range.range-success input[type="range"] {
  outline-color: $green;
}

.range.range-info input[type="range"]::-webkit-slider-thumb {
  background-color: $azure;
}

.range.range-info input[type="range"]::-moz-slider-thumb {
  background-color: $azure;
}

.range.range-info output {
  background-color: $azure;
}

.range.range-info input[type="range"] {
  outline-color: $azure;
}

.range.range-warning input[type="range"]::-webkit-slider-thumb {
  background-color: #ecb403;
}

.range.range-warning input[type="range"]::-moz-slider-thumb {
  background-color: #ecb403;
}

.range.range-warning output {
  background-color: #ecb403;
}

.range.range-warning input[type="range"] {
  outline-color: #ecb403;
}

.range.range-danger input[type="range"]::-webkit-slider-thumb {
  background-color: #c21a1a;
}

.range.range-danger input[type="range"]::-moz-slider-thumb {
  background-color: #c21a1a;
}

.range.range-danger output {
  background-color: #c21a1a;
}

.range.range-danger input[type="range"] {
  outline-color: #c21a1a;
}
