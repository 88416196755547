#jquery-script-menu {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  border-top: 5px solid #316594;
  background: #fff;
  -moz-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  z-index: 999999;
  padding: 10px 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.jquery-script-center {
  width: 960px;
  margin: 0 auto;

  ul {
    width: 212px;
    float: left;
    line-height: 45px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.jquery-script-ads {
  width: 728px;
  height: 90px;
  float: right;
}

.jquery-script-clear {
  clear: both;
  height: 0;
}
