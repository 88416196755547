@import "../variables";

.app-sidebar .mCSB_draggerContainer {
  right: -11px !important;
}
@media (min-width: 768px) {
  .app.sidenav-toggled {
    .app-content {
      margin-left: 0;
    }
    .app-sidebar {
      left: 230px;
    }
    .app-sidebar__overlay {
      visibility: hidden;
    }
  }
  .app-sidebar {
    padding-top: 75px;
  }
  .side-header {
    width: 270px;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 99;
    background: #fff;
  }
  .sidebar-mini.sidenav-toggled footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 80px;
  }
}
@media (min-width: 992px) {
  .sidebar-mini footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 270px;
  }
}
.app-content {
  margin-bottom: 0 !important;
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  overflow: hidden;
  margin-top: 74px;
  .side-app {
    padding: 0px 15px 0 15px;
  }
}
.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .app-content {
    margin-left: 270px;
  }
  .app.sidebar-mini.sidenav-toggled .side-header {
    width: 80px !important;
  }
  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
    width: 270px !important;
  }
}
@media (max-width: 480px) {
}
@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
}
.light-text {
  font-weight: 300 !important;
}
.semibold-text {
  font-weight: 600 !important;
}
.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 99 !important;
  background: #ffffff;
  transition: all ease 0.2s;
}
@media (max-width: 991.98px) {
  .app-header {
    z-index: 999 !important;
  }
}
@media (min-width: 992px) {
  .app-header {
    padding-right: 10px;
    padding-left: 285px;
  }
}
@media print {
  .app-header {
    display: none;
  }
}
.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  font-family: "Niconne";
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
  }
}
.app-sidebar__hamb {
  color: #294865;
  fill: #294865;
  z-index: 1;
  margin-top: 5px;
  transition: padding-left 0.3s ease;
  padding: 9px;
  text-align: center;
  height: 2.7rem;
  font-size: 2.451rem;
  line-height: 1;
  position: relative;
  border-radius: 50%;
  &:hover {
    color: $primary;
  }
  &:before {
    transition: all 0.25s;
    font-size: 21px;
  }
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
  }
}
.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}
@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}
.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}
.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  cursor: pointer;
}
.app-notification {
  min-width: 270px;
}
.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}
.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}
.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}
.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: #e0e0e0;
  }
}
.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}
.app-notification__icon {
  padding-right: 10px;
}
.app-notification__message {
  line-height: 1.2;
}
.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: #8a98ac;
  z-index: 99;
  background: #fff !important;
  -webkit-box-shadow: 0 8px 24px rgba(229, 228, 230, 0.4);
  // box-shadow: 0 8px 24px rgba(229, 228, 230, .4);
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  // border-right: $border;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}
@media print {
  .app-sidebar {
    display: none;
  }
}
.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }
  img {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #a8a8a8;
  width: 100%;
  padding: 15px 0 15px;
  display: inline-block;
}
.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}
.app-sidebar__user-name {
  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }
  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}
.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}
.sub-side-menu__item:hover {
  color: $primary;
}
.sub-slide-item:hover {
  color: $primary;
}
.sub-side-menu__item2:hover {
  color: $primary;
}
.sub-slide-item2:hover {
  color: $primary;
}
.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1.5rem;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  color: #294865;
  margin: 0px 0px 2px 0px;
  &.active {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary !important;
    background: transparent !important;
    .side-menu__icon,
    .side-menu__label {
      color: $primary !important;
      fill: $primary !important;
    }
  }
}
.slide-item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: #b5c1d2;
  }
}
.slide-menu a.active {
  color: $primary !important;
}
.slide-item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary !important;
  }
}
.slide-menu li {
  position: relative;
}
.slide.is-expanded a {
  text-decoration: none;
}

.sidebar-mini .side-menu .side-menu__icon {
  color: #294865;
  width: 1.3rem !important;
  text-align: center;
  vertical-align: middle;
  margin-right: 8px;
  position: relative;
  font-size: 1.25rem !important;
  transition: all ease 0.3s;
  fill: #294865;
}
.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  top: 1px;
  line-height: inherit;
}
.slide.is-expanded {
  .slide-menu {
    display: block;
  }
  .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
.slide-menu {
  display: none;
  padding: 0px 20px;
  font-size: 0.8rem !important;
}
.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 8px;
  font-size: 13px;
  color: #8491a5;
  .icon {
    margin-right: 5px;
  }
}
.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  margin-right: 2px;
}
@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled {
    .side-menu__item .side-menu__icon {
      margin-left: -6px;
      margin-right: -3px;
    }
    .side-menu .side-menu__icon {
      background: none !important;
      box-shadow: none;
    }
    .sidebar-mini.sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }
    .app-sidebar__user-name {
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 1rem;
      margin-bottom: 0px !important;
    }
    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }
    .side-menu li .side-menu__item.active:before {
      display: none;
    }
    .app-sidebar__user {
      padding: 12px 0px 12px 0;
      margin-bottom: 0px;
      border-bottom: 1px solid rgba(225, 225, 225, 0.05);
    }
    .profile-img {
      top: 0px;
      right: 19px;
    }
    .app-content {
      margin-left: 80px;
    }
    .app-sidebar {
      &:hover {
        overflow: visible;
      }
    }
    .side-menu__item {
      overflow: hidden;
    }
    .slide-menu {
      position: absolute;
      opacity: 0;
      border-bottom-right-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
    }
  }
  .app.sidebar-mini.sidenav-toggled {
    .side-menu__item {
      border-radius: 0;
    }
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }
  .app.sidebar-mini.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open {
    .app-sidebar {
      width: 270px !important;
    }
    .side-header .header-brand-img.light-logo1 {
      display: block !important;
    }
    .side-menu h3 {
      display: block !important;
    }
    .side-menu__icon {
      font-size: 1.25rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 8px !important;
      position: relative;
      top: -1px;
    }
    .side-menu__label {
      display: block !important;
      white-space: nowrap;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      position: relative;
      top: 1px;
      line-height: inherit;
    }
    .angle {
      display: block;
    }
    .side-badge {
      top: 10px;
    }
  }
}
.app.sidebar-mini {
  .side-menu_label {
    display: none;
  }
}
.dropdown-menu {
  border-radius: 0;
  &.dropdown-menu-right {
    left: auto;
  }
}
.dropdown-item {
  .fa,
  .icon {
    vertical-align: middle;
  }
}
.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #fff;
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }
  p {
    margin-bottom: 0;
    font-style: italic;
  }
}
@media print {
  .app-title {
    display: none;
  }
}
@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}
.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}
@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}
.tile {
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media print {
  .tile {
    border: 1px solid #ddd;
  }
}
.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}
.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.05);
  }
  .mCSB_scrollTools {
    .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_draggerRail {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }
  .nav-badge1 {
    display: none;
  }
}
.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}
.user-info {
  .text-dark {
    color: #25252a !important;
    font-weight: 400;
    font-size: 16px;
  }
  .text-muted {
    color: #8491a5 !important;
  }
}
.side-header {
  .header-brand1 {
    text-align: center;
    margin: 0 auto !important;
  }
  .header-brand-img.desktop-logo {
    display: none;
  }
}
.sidebar-mini.sidenav-toggled
  .app-sidebar
  .side-header
  .header-brand-img.desktop-logo {
  display: none;
}
.side-header .header-brand-img {
  &.toggle-logo {
    display: none;
  }
  &.light-logo1 {
    display: block !important;
  }
}
.sidebar-mini.sidenav-toggled {
  .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
      display: none !important;
    }
    &.toggle-logo {
      display: none;
      margin-right: 0;
    }
  }
  .sidebar-navs {
    display: none;
  }
}
.side-header {
  display: flex;
  padding: 22px 17px;
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  height: 75px;
}
.sidebar-mini.sidenav-toggled {
  .app-sidebar__toggle {
    padding-left: 10px;
  }
  .mobile-header .app-sidebar__toggle {
    margin: 0;
    margin-top: 5px;
  }
}
.user-pic {
  margin-bottom: 0.8rem;
}
.sidebar-navs a {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #eaedf1 !important;
  color: #68798b !important;
  border-radius: 5px;
  padding: 0.8rem !important;
}
.app-sidebar .sidebar-navs {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #eaedf1;
}
.sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}
.side-menu h3 {
  color: #8491a5;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  margin-bottom: 0.5rem;
  padding-top: 1.5rem !important;
  font-weight: 500;
  padding: 5px 30px 5px;
  opacity: 0.7;
}
.side-menu li:not(:first-child) h3 {
  margin-top: 0rem;
}
@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled .side-menu h3 {
    display: none !important;
  }
  .side-header {
    width: 270px !important;
    display: flex !important;
    padding: 22px 17px !important;
  }
}
.sidebar-mini .app-header .light-logo1 {
  display: block !important;
}
@media (min-width: 768px) {
  .sidenav-toggled .header-brand-img.light-logo {
    display: block;
    margin-right: 0;
  }
}
.side-badge,
.slide-menu open.slide-menu hide .side-badge {
  position: absolute;
  top: 15px;
  right: 27px;
  border-radius: 50em;
  padding: 0.8em 0.7em;
  display: inline-block;
}
.side-menu__item.active .side-menu__label {
  color: $primary !important;
}

/*-- Subslide ---*/

.sub-side-menu__item {
  padding: 8px 8px;
  font-size: 13px;
  display: flex;
  color: #294865;
  .sub-side-menu__label {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
.sub-slide.is-expanded .sub-angle {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sub-angle {
  float: right;
  line-height: 40px;
  margin-top: 3px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.sub-slide.is-expanded .sub-slide-menu {
  display: block;
}
.sub-slide-menu {
  display: none;
  padding: 0 15px 15px 0;
  font-size: 13px !important;
  list-style: none;
}
.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 8px 0 8px 24px !important;
  color: #294865;
  font-size: 12px;
}

/*-- Subslide2 ---*/

.sub-side-menu__item2 {
  padding: 8px 2px 8px 24px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #294865;
  font-size: 12px;
}
.sub-slide2 .sub-side-menu__label2 {
  flex: 1 1 auto;
}
.sub-angle2 {
  float: right;
  line-height: 40px;
  margin-top: -2px;
  margin-right: -9px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.sub-slide2.is-expanded .sub-angle2 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sub-slide2.is-expanded .sub-slide-menu2 {
  display: block;
}
.sub-slide-menu2 {
  display: none;
  padding: 0;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}
.sub-slide-item2 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 8px 0 8px 40px !important;
  font-size: 11px;
  color: #294865;
}
.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.desktop-logo {
  display: block !important ;
}
.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.sidenav-toggled
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: block !important;
}
.app.sidebar-mini.dark-mode.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: none !important;
}
.side-menu__item.active .side-menu__icon {
  color: $primary !important;
  fill: $primary !important;
}
.side-menu__item.active:hover .side-menu__icon {
  color: $primary !important;
  fill: $primary !important;
}
.app.sidenav-toggled .side-menu .side-menu__icon {
  font-size: 1.5rem !important;
  width: 1.3rem !important;
}
.sidebar-open #menu-icon {
  color: $primary;
  fill: $primary;
}
@media (max-width: 991.98px) {
  .side-header {
    display: none !important;
  }
  .app .app-sidebar {
    left: -270px;
  }
  .app.sidenav-toggled .side-menu h3 {
    display: block !important;
  }
  .app.sidenav-toggled {
    .app-content {
      margin-left: 0;
    }
    .app-sidebar {
      left: 0;
    }
    .app-sidebar__overlay {
      visibility: visible;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .app.navbar-toggled {
    .app-sidebar__overlay {
      visibility: visible;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
    left: 0;
  }
  .header-brand1 .desktop-logo {
    margin: 0 auto;
    margin-top: 6px;
  }
  .app {
    overflow-x: hidden;
    &.sidebar-mini {
      &.sidenav-toggled .side-menu .side-menu__icon {
        margin-right: 8px;
        font-size: 1.5rem !important;
      }
    }
    .app-sidebar__overlay {
      visibility: hidden;
    }
    .app-header .header-brand1 {
      width: inherit;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .app-content {
    min-width: 100%;
  }
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}
@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
    .light-logo,
    .light-logo1,
    .mobile-light {
      display: none !important;
    }
    .side-menu__label {
      display: block !important;
    }

    .badge-hide,
    .slide-menu open.slide-menu hide .badge-hide {
      display: block;
    }
    .app-sidebar__user-name,
    .app-sidebar__user-designation,
    .angle,
    .app-sidebar__user-name,
    .user-notification,
    .app-sidebar__user-name,
    .user-info {
      display: block;
    }
  }
  .app.sidebar-mini.sidenav-toggled {
    .app-sidebar__user-name,
    .app-sidebar__user-designation,
    .angle,
    .app-sidebar__user-name,
    .user-notification,
    .app-sidebar__user-name,
    .user-info {
      display: none;
    }
    .badge-hide,
    .slide-menu open.slide-menu hide .badge-hide {
      display: none;
    }
    .app-header.header {
      padding-left: 100px;
    }
    .side-menu {
      padding: 0 0px 40px 0px;
    }
  }
  .sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    width: 80px !important;
    overflow: hidden;
  }
  .app.sidebar-mini.sidenav-toggled .side-menu__label {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open {
    .side-menu {
      padding-top: 0px !important;
    }
  }
  .sidebar-mini.sidenav-toggled {
    .side-menu {
      padding-top: 10px !important;
    }
  }
}
.app.sidebar-mini .side-menu {
  .slide-item.active,
  .slide-item:hover,
  .slide-item:focus {
    &:before {
      color: $primary !important;
    }
  }
}
.app.dark-mode.icon-overlay.sidenav-toggled.light-menu {
  .side-header {
    .header-brand1 {
      .light-logo {
        display: block !important;
      }
      .desktop-logo,
      .toggle-logo,
      .light-logo1 {
        display: none !important;
      }
    }
  }
}
.sidebar-mini .app-sidebar .side-menu {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
// @media (min-width:992px) {
//  .horizontal-hover .side-menu__item:not(.has-link),.horizontal-hover .sub-side-menu__item,.horizontal-hover .sub-side-menu__item2 {
//     pointer-events: none;
//   }
// }
.side-menu__item.active {
  i {
    color: $primary;
  }
}
@media (max-width: 992px) {
  .side-menu {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.app.sidebar-mini {
  .app-sidebar {
    width: 270px;
  }
}
@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    display: none;
    position: unset;
    top: inherit;
    min-width: 0;
    width: 100%;
    opacity: inherit;
    visibility: inherit;
  }
}
@media (max-width: 767.98px) {
  .app-sidebar {
    top: 71px;
  }
  .demo-icon.nav-link {
    margin: 2px 0px 0px 0px !important;
  }
}
.side-menu__item .badge {
  border-radius: 50px !important;
}

@media (min-width: 992px) {
  .horizontal-hover .side-menu__item:not(.has-link),
  .horizontal-hover .sub-side-menu__item,
  .horizontal-hover .sub-side-menu__item2 {
    pointer-events: none;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open
    .slide.is-expanded
    .slide-menu {
    display: block !important;
    position: unset;
    top: inherit;
    min-width: 0;
    width: 100%;
    opacity: inherit;
    visibility: inherit;
  }
}
