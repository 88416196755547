/*--------- Width--------------*/
.w-auto {
	width: auto !important;
}
.w-0 {
	width: 0 !important;
}
.mw-100 {
	max-width: 100% !important;
}
.minw-100 {
	min-width: 100% !important;
}

.w-icn{
	width: 1.45rem !important
}
.w-inner-icn{
	width: 1.2rem !important;
}
.wv-100{
	width: 100v !important;
}
/*----- Width in %  -----*/
.wp-1 {
	width: 1% !important;
}
.wp-2 {
	width: 2% !important;
}
.wp-3 {
	width: 3% !important;
}
.wp-4 {
	width: 4% !important;
}
.wp-5 {
	width: 5% !important;
}
.wp-6 {
	width: 6% !important;
}
.wp-7 {
	width: 7% !important;
}
.wp-8 {
	width: 8% !important;
}
.wp-9 {
	width: 9% !important;
}
.wp-10 {
	width: 10% !important;
}
.wp-11 {
	width: 11% !important;
}
.wp-12 {
	width: 12% !important;
}
.wp-13 {
	width: 13% !important;
}
.wp-14 {
	width: 14% !important;
}
.wp-15 {
	width: 15% !important;
}
.wp-16 {
	width: 16% !important;
}
.wp-17 {
	width: 17% !important;
}
.wp-18 {
	width: 18% !important;
}
.wp-19 {
	width: 19% !important;
}
.wp-20 {
	width: 20% !important;
}
.wp-21 {
	width: 21% !important;
}
.wp-22 {
	width: 22% !important;
}
.wp-23 {
	width: 23% !important;
}
.wp-24 {
	width: 24% !important;
}
.wp-25 {
	width: 25% !important;
}
.wp-26 {
	width: 26% !important;
}
.wp-27 {
	width: 27% !important;
}
.wp-28 {
	width: 28% !important;
}
.wp-29 {
	width: 29% !important;
}
.wp-30 {
	width: 30% !important;
}
.wp-31 {
	width: 31% !important;
}
.wp-32 {
	width: 32% !important;
}
.wp-33 {
	width: 33% !important;
}
.wp-34 {
	width: 34% !important;
}
.wp-35 {
	width: 35% !important;
}
.wp-36 {
	width: 36% !important;
}
.wp-37 {
	width: 37% !important;
}
.wp-38 {
	width: 38% !important;
}
.wp-39 {
	width: 39% !important;
}
.wp-40 {
	width: 40% !important;
}
.wp-41 {
	width: 41% !important;
}
.wp-42 {
	width: 42% !important;
}
.wp-43 {
	width: 43% !important;
}
.wp-44 {
	width: 44% !important;
}
.wp-45 {
	width: 45% !important;
}
.wp-46 {
	width: 46% !important;
}
.wp-47 {
	width: 47% !important;
}
.wp-48 {
	width: 48% !important;
}
.wp-49 {
	width: 49% !important;
}
.wp-50 {
	width: 50% !important;
}
.wp-51 {
	width: 51% !important;
}
.wp-52 {
	width: 52% !important;
}
.wp-53 {
	width: 53% !important;
}
.wp-54 {
	width: 54% !important;
}
.wp-55 {
	width: 55% !important;
}
.wp-56 {
	width: 56% !important;
}
.wp-57 {
	width: 57% !important;
}
.wp-58 {
	width: 58% !important;
}
.wp-59 {
	width: 59% !important;
}
.wp-60 {
	width: 60% !important;
}
.wp-61 {
	width: 61% !important;
}
.wp-62 {
	width: 62% !important;
}
.wp-63 {
	width: 63% !important;
}
.wp-64 {
	width: 64% !important;
}
.wp-65 {
	width: 65% !important;
}
.wp-66 {
	width: 66% !important;
}
.wp-67 {
	width: 67% !important;
}
.wp-68 {
	width: 68% !important;
}
.wp-69 {
	width: 69% !important;
}
.wp-70 {
	width: 70% !important;
}
.wp-71 {
	width: 71% !important;
}
.wp-72 {
	width: 72% !important;
}
.wp-73 {
	width: 73% !important;
}
.wp-74 {
	width: 74% !important;
}
.wp-75 {
	width: 75% !important;
}
.wp-76 {
	width: 76% !important;
}
.wp-77 {
	width: 77% !important;
}
.wp-78 {
	width: 78% !important;
}
.wp-79 {
	width: 79% !important;
}
.wp-80 {
	width: 80% !important;
}
.wp-81 {
	width: 81% !important;
}
.wp-82 {
	width: 82% !important;
}
.wp-83 {
	width: 83% !important;
}
.wp-84 {
	width: 84% !important;
}
.wp-85 {
	width: 85% !important;
}
.wp-86 {
	width: 86% !important;
}
.wp-87 {
	width: 87% !important;
}
.wp-88 {
	width: 88% !important;
}
.wp-89 {
	width: 89% !important;
}
.wp-90 {
	width: 90% !important;
}
.wp-91 {
	width: 91% !important;
}
.wp-92 {
	width: 92% !important;
}
.wp-93 {
	width: 93% !important;
}
.wp-94 {
	width: 94% !important;
}
.wp-95 {
	width: 95% !important;
}
.wp-96 {
	width: 96% !important;
}
.wp-97 {
	width: 97% !important;
}
.wp-98 {
	width: 98% !important;
}
.wp-99 {
	width: 99% !important;
}
.wp-100 {
	width: 100% !important;
}

.wp-101 {
	width: 101% !important;
}
.wp-102 {
	width: 102% !important;
}
.wp-103 {
	width: 103% !important;
}
.wp-104 {
	width: 104% !important;
}
.wp-105 {
	width: 105% !important;
}
.wp-106 {
	width: 106% !important;
}
.wp-107 {
	width: 107% !important;
}
.wp-108 {
	width: 108% !important;
}
.wp-109 {
	width: 109% !important;
}
.wp-110 {
	width: 110% !important;
}
.wp-111 {
	width: 111% !important;
}
.wp-112 {
	width: 112% !important;
}
.wp-113 {
	width: 113% !important;
}
.wp-114 {
	width: 114% !important;
}
.wp-115 {
	width: 115% !important;
}
.wp-116 {
	width: 116% !important;
}
.wp-117 {
	width: 117% !important;
}
.wp-118 {
	width: 118% !important;
}
.wp-119 {
	width: 119% !important;
}
.wp-120 {
	width: 120% !important;
}
.wp-121 {
	width: 121% !important;
}
.wp-122 {
	width: 122% !important;
}
.wp-123 {
	width: 123% !important;
}
.wp-124 {
	width: 124% !important;
}
.wp-125 {
	width: 125% !important;
}
.wp-126 {
	width: 126% !important;
}
.wp-127 {
	width: 127% !important;
}
.wp-128 {
	width: 128% !important;
}
.wp-129 {
	width: 129% !important;
}
.wp-130 {
	width: 130% !important;
}
.wp-131 {
	width: 131% !important;
}
.wp-132 {
	width: 132% !important;
}
.wp-133 {
	width: 133% !important;
}
.wp-134 {
	width: 134% !important;
}
.wp-135 {
	width: 135% !important;
}
.wp-136 {
	width: 136% !important;
}
.wp-137 {
	width: 137% !important;
}
.wp-138 {
	width: 138% !important;
}
.wp-139 {
	width: 139% !important;
}
.wp-140 {
	width: 140% !important;
}
.wp-141 {
	width: 141% !important;
}
.wp-142 {
	width: 142% !important;
}
.wp-143 {
	width: 143% !important;
}
.wp-144 {
	width: 144% !important;
}
.wp-145 {
	width: 145% !important;
}
.wp-146 {
	width: 146% !important;
}
.wp-147 {
	width: 147% !important;
}
.wp-148 {
	width: 148% !important;
}
.wp-149 {
	width: 149% !important;
}
.wp-150 {
	width: 150% !important;
}
.wp-151 {
	width: 151% !important;
}
.wp-152 {
	width: 152% !important;
}
.wp-153 {
	width: 153% !important;
}
.wp-154 {
	width: 154% !important;
}
.wp-155 {
	width: 155% !important;
}
.wp-156 {
	width: 156% !important;
}
.wp-157 {
	width: 157% !important;
}
.wp-158 {
	width: 158% !important;
}
.wp-159 {
	width: 159% !important;
}
.wp-160 {
	width: 160% !important;
}
.wp-161 {
	width: 161% !important;
}
.wp-162 {
	width: 162% !important;
}
.wp-163 {
	width: 163% !important;
}
.wp-164 {
	width: 164% !important;
}
.wp-165 {
	width: 165% !important;
}
.wp-166 {
	width: 166% !important;
}
.wp-167 {
	width: 167% !important;
}
.wp-168 {
	width: 168% !important;
}
.wp-169 {
	width: 169% !important;
}
.wp-170 {
	width: 170% !important;
}
.wp-171 {
	width: 171% !important;
}
.wp-172 {
	width: 172% !important;
}
.wp-173 {
	width: 173% !important;
}
.wp-174 {
	width: 174% !important;
}
.wp-175 {
	width: 175% !important;
}
.wp-176 {
	width: 176% !important;
}
.wp-177 {
	width: 177% !important;
}
.wp-178 {
	width: 178% !important;
}
.wp-179 {
	width: 179% !important;
}
.wp-180 {
	width: 180% !important;
}
.wp-181 {
	width: 181% !important;
}
.wp-182 {
	width: 182% !important;
}
.wp-183 {
	width: 183% !important;
}
.wp-184 {
	width: 184% !important;
}
.wp-185 {
	width: 185% !important;
}
.wp-186 {
	width: 186% !important;
}
.wp-187 {
	width: 187% !important;
}
.wp-188 {
	width: 188% !important;
}
.wp-189 {
	width: 189% !important;
}
.wp-190 {
	width: 190% !important;
}
.wp-191 {
	width: 191% !important;
}
.wp-192 {
	width: 192% !important;
}
.wp-193 {
	width: 193% !important;
}
.wp-194 {
	width: 194% !important;
}
.wp-195 {
	width: 195% !important;
}
.wp-196 {
	width: 196% !important;
}
.wp-197 {
	width: 197% !important;
}
.wp-198 {
	width: 198% !important;
}
.wp-199 {
	width: 199% !important;
}
.wp-200 {
	width: 200% !important;
}

/*----- Width in px -----*/
.wpx-1 {
	width: 1px !important;
}
.wpx-2 {
	width: 2px !important;
}
.wpx-3 {
	width: 3px !important;
}
.wpx-4 {
	width: 4px !important;
}
.wpx-5 {
	width: 5px !important;
}
.wpx-6 {
	width: 6px !important;
}
.wpx-7 {
	width: 7px !important;
}
.wpx-8 {
	width: 8px !important;
}
.wpx-9 {
	width: 9px !important;
}
.wpx-10 {
	width: 10px !important;
}
.wpx-15 {
	width: 15px !important;
}
.wpx-20 {
	width: 20px !important;
}
.wpx-21 {
	width: 21px !important;
}
.wpx-22 {
	width: 22px !important;
}
.wpx-23 {
	width: 23px !important;
}
.wpx-24 {
	width: 24px !important;
}
.wpx-25 {
	width: 25px !important;
}
.wpx-26 {
	width: 26px !important;
}
.wpx-27 {
	width: 27px !important;
}
.wpx-28 {
	width: 28px !important;
}
.wpx-29 {
	width: 29px !important;
}
.wpx-30 {
	width: 30px !important;
}
.wpx-31 {
	width: 31px !important;
}
.wpx-32 {
	width: 32px !important;
}
.wpx-33 {
	width: 33px !important;
}
.wpx-34 {
	width: 34px !important;
}
.wpx-35 {
	width: 35px !important;
}
.wpx-36 {
	width: 36px !important;
}
.wpx-37 {
	width: 37px !important;
}
.wpx-38 {
	width: 38px !important;
}
.wpx-39 {
	width: 39px !important;
}
.wpx-40 {
	width: 40px !important;
}
.wpx-41 {
	width: 41px !important;
}
.wpx-42 {
	width: 42px !important;
}
.wpx-43 {
	width: 43px !important;
}
.wpx-44 {
	width: 44px !important;
}
.wpx-45 {
	width: 45px !important;
}
.wpx-46 {
	width: 46px !important;
}
.wpx-47 {
	width: 47px !important;
}
.wpx-48 {
	width: 48px !important;
}
.wpx-49 {
	width: 49px !important;
}
.wpx-50 {
	width: 50px !important;
}
.wpx-51 {
	width: 51px !important;
}
.wpx-52 {
	width: 52px !important;
}
.wpx-53 {
	width: 53px !important;
}
.wpx-54 {
	width: 54px !important;
}
.wpx-55 {
	width: 55px !important;
}
.wpx-56 {
	width: 56px !important;
}
.wpx-57 {
	width: 57px !important;
}
.wpx-58 {
	width: 58px !important;
}
.wpx-59 {
	width: 59px !important;
}
.wpx-60 {
	width: 60px !important;
}
.wpx-61 {
	width: 61px !important;
}
.wpx-62 {
	width: 62px !important;
}
.wpx-63 {
	width: 63px !important;
}
.wpx-64 {
	width: 64px !important;
}
.wpx-65 {
	width: 65px !important;
}
.wpx-66 {
	width: 66px !important;
}
.wpx-67 {
	width: 67px !important;
}
.wpx-68 {
	width: 68px !important;
}
.wpx-69 {
	width: 69px !important;
}
.wpx-70 {
	width: 70px !important;
}
.wpx-71 {
	width: 71px !important;
}
.wpx-72 {
	width: 72px !important;
}
.wpx-73 {
	width: 73px !important;
}
.wpx-74 {
	width: 74px !important;
}
.wpx-75 {
	width: 75px !important;
}
.wpx-76 {
	width: 76px !important;
}
.wpx-77 {
	width: 77px !important;
}
.wpx-78 {
	width: 78px !important;
}
.wpx-79 {
	width: 79px !important;
}
.wpx-80 {
	width: 80px !important;
}
.wpx-81 {
	width: 81px !important;
}
.wpx-82 {
	width: 82px !important;
}
.wpx-83 {
	width: 83px !important;
}
.wpx-84 {
	width: 84px !important;
}
.wpx-85 {
	width: 85px !important;
}
.wpx-86 {
	width: 86px !important;
}
.wpx-87 {
	width: 87px !important;
}
.wpx-88 {
	width: 88px !important;
}
.wpx-89 {
	width: 89px !important;
}
.wpx-90 {
	width: 90px !important;
}
.wpx-91 {
	width: 91px !important;
}
.wpx-92 {
	width: 92px !important;
}
.wpx-93 {
	width: 93px !important;
}
.wpx-94 {
	width: 94px !important;
}
.wpx-95 {
	width: 95px !important;
}
.wpx-96 {
	width: 96px !important;
}
.wpx-97 {
	width: 97px !important;
}
.wpx-98 {
	width: 98px !important;
}
.wpx-99 {
	width: 99px !important;
}
.wpx-100 {
	width: 100px !important;
}



.wpx-101 {
	width: 101px !important;
}
.wpx-102 {
	width: 102px !important;
}
.wpx-103 {
	width: 103px !important;
}
.wpx-104 {
	width: 104px !important;
}
.wpx-105 {
	width: 105px !important;
}
.wpx-106 {
	width: 106px !important;
}
.wpx-107 {
	width: 107px !important;
}
.wpx-108 {
	width: 108px !important;
}
.wpx-109 {
	width: 109px !important;
}
.wpx-150 {
	width: 150px !important;
}
.wpx-151 {
	width: 151px !important;
}
.wpx-152 {
	width: 152px !important;
}
.wpx-153 {
	width: 153px !important;
}
.wpx-154 {
	width: 154px !important;
}
.wpx-155 {
	width: 155px !important;
}
.wpx-156 {
	width: 156px !important;
}
.wpx-157 {
	width: 157px !important;
}
.wpx-158 {
	width: 158px !important;
}
.wpx-159 {
	width: 159px !important;
}
.wpx-200 {
	width: 200px !important;
}

/*----- Width in rem -----*/
.wrem-1 {
	width: 1rem !important;
}
.wrem-2 {
	width: 2rem !important;
}
.wrem-3 {
	width: 3rem !important;
}
.wrem-4 {
	width: 4rem !important;
}
.wrem-5 {
	width: 5rem !important;
}
.wrem-6 {
	width: 6rem !important;
}
.wrem-7 {
	width: 7rem !important;
}
.wrem-8 {
	width: 8rem !important;
}
.wrem-9 {
	width: 9rem !important;
}
.wrem-10 {
	width: 10rem !important;
}
.wrem-15 {
	width: 15rem !important;
}
.wrem-20 {
	width: 20rem !important;
}
.wrem-21 {
	width: 21rem !important;
}
.wrem-22 {
	width: 22rem !important;
}
.wrem-23 {
	width: 23rem !important;
}
.wrem-24 {
	width: 24rem !important;
}
.wrem-25 {
	width: 25rem !important;
}
.wrem-26 {
	width: 26rem !important;
}
.wrem-27 {
	width: 27rem !important;
}
.wrem-28 {
	width: 28rem !important;
}
.wrem-29 {
	width: 29rem !important;
}
.wrem-30 {
	width: 30rem !important;
}
.wrem-31 {
	width: 31rem !important;
}
.wrem-32 {
	width: 32rem !important;
}
.wrem-33 {
	width: 33rem !important;
}
.wrem-34 {
	width: 34rem !important;
}
.wrem-35 {
	width: 35rem !important;
}
.wrem-36 {
	width: 36rem !important;
}
.wrem-37 {
	width: 37rem !important;
}
.wrem-38 {
	width: 38rem !important;
}
.wrem-39 {
	width: 39rem !important;
}
.wrem-40 {
	width: 40rem !important;
}
.wrem-41 {
	width: 41rem !important;
}
.wrem-42 {
	width: 42rem !important;
}
.wrem-43 {
	width: 43rem !important;
}
.wrem-44 {
	width: 44rem !important;
}
.wrem-45 {
	width: 45rem !important;
}
.wrem-46 {
	width: 46rem !important;
}
.wrem-47 {
	width: 47rem !important;
}
.wrem-48 {
	width: 48rem !important;
}
.wrem-49 {
	width: 49rem !important;
}
.wrem-50 {
	width: 50rem !important;
}
.wrem-51 {
	width: 51rem !important;
}
.wrem-52 {
	width: 52rem !important;
}
.wrem-53 {
	width: 53rem !important;
}
.wrem-54 {
	width: 54rem !important;
}
.wrem-55 {
	width: 55rem !important;
}
.wrem-56 {
	width: 56rem !important;
}
.wrem-57 {
	width: 57rem !important;
}
.wrem-58 {
	width: 58rem !important;
}
.wrem-59 {
	width: 59rem !important;
}
.wrem-60 {
	width: 60rem !important;
}
.wrem-61 {
	width: 61rem !important;
}
.wrem-62 {
	width: 62rem !important;
}
.wrem-63 {
	width: 63rem !important;
}
.wrem-64 {
	width: 64rem !important;
}
.wrem-65 {
	width: 65rem !important;
}
.wrem-66 {
	width: 66rem !important;
}
.wrem-67 {
	width: 67rem !important;
}
.wrem-68 {
	width: 68rem !important;
}
.wrem-69 {
	width: 69rem !important;
}
.wrem-70 {
	width: 70rem !important;
}
.wrem-71 {
	width: 71rem !important;
}
.wrem-72 {
	width: 72rem !important;
}
.wrem-73 {
	width: 73rem !important;
}
.wrem-74 {
	width: 74rem !important;
}
.wrem-75 {
	width: 75rem !important;
}
.wrem-76 {
	width: 76rem !important;
}
.wrem-77 {
	width: 77rem !important;
}
.wrem-78 {
	width: 78rem !important;
}
.wrem-79 {
	width: 79rem !important;
}
.wrem-80 {
	width: 80rem !important;
}
.wrem-81 {
	width: 81rem !important;
}
.wrem-82 {
	width: 82rem !important;
}
.wrem-83 {
	width: 83rem !important;
}
.wrem-84 {
	width: 84rem !important;
}
.wrem-85 {
	width: 85rem !important;
}
.wrem-86 {
	width: 86rem !important;
}
.wrem-87 {
	width: 87rem !important;
}
.wrem-88 {
	width: 88rem !important;
}
.wrem-89 {
	width: 89rem !important;
}
.wrem-90 {
	width: 90rem !important;
}
.wrem-91 {
	width: 91rem !important;
}
.wrem-92 {
	width: 92rem !important;
}
.wrem-93 {
	width: 93rem !important;
}
.wrem-94 {
	width: 94rem !important;
}
.wrem-95 {
	width: 95rem !important;
}
.wrem-96 {
	width: 96rem !important;
}
.wrem-97 {
	width: 97rem !important;
}
.wrem-98 {
	width: 98rem !important;
}
.wrem-99 {
	width: 99rem !important;
}
.wrem-100 {
	width: 100rem !important;
}


.wrem-1-1 {
	width: 1.1rem !important;
}
.wrem-2-1 {
	width: 2.1rem !important;
}
.wrem-3-1 {
	width: 3.1rem !important;
}
.wrem-4-1 {
	width: 4.1rem !important;
}
.wrem-5-1 {
	width: 5.1rem !important;
}
.wrem-6-1 {
	width: 6.1rem !important;
}
.wrem-7-1 {
	width: 7.1rem !important;
}
.wrem-8-1 {
	width: 8.1rem !important;
}
.wrem-9-1 {
	width: 9.1rem !important;
}
.wrem-10-1 {
	width: 10.1rem !important;
}
.wrem-15-1 {
	width: 15.1rem !important;
}
.wrem-20-1 {
	width: 20.1rem !important;
}
.wrem-21-1 {
	width: 21.1rem !important;
}
.wrem-22-1 {
	width: 22.1rem !important;
}
.wrem-23-1 {
	width: 23.1rem !important;
}
.wrem-24-1 {
	width: 24.1rem !important;
}
.wrem-25-1 {
	width: 25.1rem !important;
}
.wrem-26-1 {
	width: 26.1rem !important;
}
.wrem-27-1 {
	width: 27.1rem !important;
}
.wrem-28-1 {
	width: 28.1rem !important;
}
.wrem-29-1 {
	width: 29.1rem !important;
}
.wrem-30-1 {
	width: 30.1rem !important;
}
.wrem-31-1 {
	width: 31.1rem !important;
}
.wrem-32-1 {
	width: 32.1rem !important;
}
.wrem-33-1 {
	width: 33.1rem !important;
}
.wrem-34-1 {
	width: 34.1rem !important;
}
.wrem-35-1 {
	width: 35.1rem !important;
}
.wrem-36-1 {
	width: 36.1rem !important;
}
.wrem-37-1 {
	width: 37.1rem !important;
}
.wrem-38-1 {
	width: 38.1rem !important;
}
.wrem-39-1 {
	width: 39.1rem !important;
}
.wrem-40-1 {
	width: 40.1rem !important;
}
.wrem-41-1 {
	width: 41.1rem !important;
}
.wrem-42-1 {
	width: 42.1rem !important;
}
.wrem-43-1 {
	width: 43.1rem !important;
}
.wrem-44-1 {
	width: 44.1rem !important;
}
.wrem-45-1 {
	width: 45.1rem !important;
}
.wrem-46-1 {
	width: 46.1rem !important;
}
.wrem-47-1 {
	width: 47.1rem !important;
}
.wrem-48-1 {
	width: 48.1rem !important;
}
.wrem-49-1 {
	width: 49.1rem !important;
}
.wrem-50-1 {
	width: 50.1rem !important;
}
.wrem-51-1 {
	width: 51.1rem !important;
}
.wrem-52-1 {
	width: 52.1rem !important;
}
.wrem-53-1 {
	width: 53.1rem !important;
}
.wrem-54-1 {
	width: 54.1rem !important;
}
.wrem-55-1 {
	width: 55.1rem !important;
}
.wrem-56-1 {
	width: 56.1rem !important;
}
.wrem-57-1 {
	width: 57.1rem !important;
}
.wrem-58-1 {
	width: 58.1rem !important;
}
.wrem-59-1 {
	width: 59.1rem !important;
}
.wrem-60-1 {
	width: 60.1rem !important;
}
.wrem-61-1 {
	width: 61.1rem !important;
}
.wrem-62-1 {
	width: 62.1rem !important;
}
.wrem-63-1 {
	width: 63.1rem !important;
}
.wrem-64-1 {
	width: 64.1rem !important;
}
.wrem-65-1 {
	width: 65.1rem !important;
}
.wrem-66-1 {
	width: 66.1rem !important;
}
.wrem-67-1 {
	width: 67.1rem !important;
}
.wrem-68-1 {
	width: 68.1rem !important;
}
.wrem-69-1 {
	width: 69.1rem !important;
}
.wrem-70-1 {
	width: 70.1rem !important;
}
.wrem-71-1 {
	width: 71.1rem !important;
}
.wrem-72-1 {
	width: 72.1rem !important;
}
.wrem-73-1 {
	width: 73.1rem !important;
}
.wrem-74-1 {
	width: 74.1rem !important;
}
.wrem-75-1 {
	width: 75.1rem !important;
}
.wrem-76-1 {
	width: 76.1rem !important;
}
.wrem-77-1 {
	width: 77.1rem !important;
}
.wrem-78-1 {
	width: 78.1rem !important;
}
.wrem-79-1 {
	width: 79.1rem !important;
}
.wrem-80-1 {
	width: 80.1rem !important;
}
.wrem-81-1 {
	width: 81.1rem !important;
}
.wrem-82-1 {
	width: 82.1rem !important;
}
.wrem-83-1 {
	width: 83.1rem !important;
}
.wrem-84-1 {
	width: 84.1rem !important;
}
.wrem-85-1 {
	width: 85.1rem !important;
}
.wrem-86-1 {
	width: 86.1rem !important;
}
.wrem-87-1 {
	width: 87.1rem !important;
}
.wrem-88-1 {
	width: 88.1rem !important;
}
.wrem-89-1 {
	width: 89.1rem !important;
}
.wrem-90-1 {
	width: 90.1rem !important;
}
.wrem-91-1 {
	width: 91.1rem !important;
}
.wrem-92-1 {
	width: 92.1rem !important;
}
.wrem-93-1 {
	width: 93.1rem !important;
}
.wrem-94-1 {
	width: 94.1rem !important;
}
.wrem-95-1 {
	width: 95.1rem !important;
}
.wrem-96-1 {
	width: 96.1rem !important;
}
.wrem-97-1 {
	width: 97.1rem !important;
}
.wrem-98-1 {
	width: 98.1rem !important;
}
.wrem-99-1 {
	width: 99.1rem !important;
}

.wrem-1-15 {
	width: 1.15rem !important;
}
.wrem-2-15 {
	width: 2.15rem !important;
}
.wrem-3-15 {
	width: 3.15rem !important;
}
.wrem-4-15 {
	width: 4.15rem !important;
}
.wrem-5-15 {
	width: 5.15rem !important;
}
.wrem-6-15 {
	width: 6.15rem !important;
}
.wrem-7-15 {
	width: 7.15rem !important;
}
.wrem-8-15 {
	width: 8.15rem !important;
}
.wrem-9-15 {
	width: 9.15rem !important;
}
.wrem-10-15 {
	width: 10.15rem !important;
}
.wrem-15-15 {
	width: 15.15rem !important;
}
.wrem-20-15 {
	width: 20.15rem !important;
}
.wrem-21-15 {
	width: 21.15rem !important;
}
.wrem-22-15 {
	width: 22.15rem !important;
}
.wrem-23-15 {
	width: 23.15rem !important;
}
.wrem-24-15 {
	width: 24.15rem !important;
}
.wrem-25-15 {
	width: 25.15rem !important;
}
.wrem-26-15 {
	width: 26.15rem !important;
}
.wrem-27-15 {
	width: 27.15rem !important;
}
.wrem-28-15 {
	width: 28.15rem !important;
}
.wrem-29-15 {
	width: 29.15rem !important;
}
.wrem-30-15 {
	width: 30.15rem !important;
}
.wrem-31-15 {
	width: 31.15rem !important;
}
.wrem-32-15 {
	width: 32.15rem !important;
}
.wrem-33-15 {
	width: 33.15rem !important;
}
.wrem-34-15 {
	width: 34.15rem !important;
}
.wrem-35-15 {
	width: 35.15rem !important;
}
.wrem-36-15 {
	width: 36.15rem !important;
}
.wrem-37-15 {
	width: 37.15rem !important;
}
.wrem-38-15 {
	width: 38.15rem !important;
}
.wrem-39-15 {
	width: 39.15rem !important;
}
.wrem-40-15 {
	width: 40.15rem !important;
}
.wrem-41-15 {
	width: 41.15rem !important;
}
.wrem-42-15 {
	width: 42.15rem !important;
}
.wrem-43-15 {
	width: 43.15rem !important;
}
.wrem-44-15 {
	width: 44.15rem !important;
}
.wrem-45-15 {
	width: 45.15rem !important;
}
.wrem-46-15 {
	width: 46.15rem !important;
}
.wrem-47-15 {
	width: 47.15rem !important;
}
.wrem-48-15 {
	width: 48.15rem !important;
}
.wrem-49-15 {
	width: 49.15rem !important;
}
.wrem-50-15 {
	width: 50.15rem !important;
}
.wrem-51-15 {
	width: 51.15rem !important;
}
.wrem-52-15 {
	width: 52.15rem !important;
}
.wrem-53-15 {
	width: 53.15rem !important;
}
.wrem-54-15 {
	width: 54.15rem !important;
}
.wrem-55-15 {
	width: 55.15rem !important;
}
.wrem-56-15 {
	width: 56.15rem !important;
}
.wrem-57-15 {
	width: 57.15rem !important;
}
.wrem-58-15 {
	width: 58.15rem !important;
}
.wrem-59-15 {
	width: 59.15rem !important;
}
.wrem-60-15 {
	width: 60.15rem !important;
}
.wrem-61-15 {
	width: 61.15rem !important;
}
.wrem-62-15 {
	width: 62.15rem !important;
}
.wrem-63-15 {
	width: 63.15rem !important;
}
.wrem-64-15 {
	width: 64.15rem !important;
}
.wrem-65-15 {
	width: 65.15rem !important;
}
.wrem-66-15 {
	width: 66.15rem !important;
}
.wrem-67-15 {
	width: 67.15rem !important;
}
.wrem-68-15 {
	width: 68.15rem !important;
}
.wrem-69-15 {
	width: 69.15rem !important;
}
.wrem-70-15 {
	width: 70.15rem !important;
}
.wrem-71-15 {
	width: 71.15rem !important;
}
.wrem-72-15 {
	width: 72.15rem !important;
}
.wrem-73-15 {
	width: 73.15rem !important;
}
.wrem-74-15 {
	width: 74.15rem !important;
}
.wrem-75-15 {
	width: 75.15rem !important;
}
.wrem-76-15 {
	width: 76.15rem !important;
}
.wrem-77-15 {
	width: 77.15rem !important;
}
.wrem-78-15 {
	width: 78.15rem !important;
}
.wrem-79-15 {
	width: 79.15rem !important;
}
.wrem-80-15 {
	width: 80.15rem !important;
}
.wrem-81-15 {
	width: 81.15rem !important;
}
.wrem-82-15 {
	width: 82.15rem !important;
}
.wrem-83-15 {
	width: 83.15rem !important;
}
.wrem-84-15 {
	width: 84.15rem !important;
}
.wrem-85-15 {
	width: 85.15rem !important;
}
.wrem-86-15 {
	width: 86.15rem !important;
}
.wrem-87-15 {
	width: 87.15rem !important;
}
.wrem-88-15 {
	width: 88.15rem !important;
}
.wrem-89-15 {
	width: 89.15rem !important;
}
.wrem-90-15 {
	width: 90.15rem !important;
}
.wrem-91-15 {
	width: 91.15rem !important;
}
.wrem-92-15 {
	width: 92.15rem !important;
}
.wrem-93-15 {
	width: 93.15rem !important;
}
.wrem-94-15 {
	width: 94.15rem !important;
}
.wrem-95-15 {
	width: 95.15rem !important;
}
.wrem-96-15 {
	width: 96.15rem !important;
}
.wrem-97-15 {
	width: 97.15rem !important;
}
.wrem-98-15 {
	width: 98.15rem !important;
}
.wrem-99-15 {
	width: 99.15rem !important;
}

.wrem-1-2 {
	width: 1.2rem !important;
}
.wrem-2-2 {
	width: 2.2rem !important;
}
.wrem-3-2 {
	width: 3.2rem !important;
}
.wrem-4-2 {
	width: 4.2rem !important;
}
.wrem-5-2 {
	width: 5.2rem !important;
}
.wrem-6-2 {
	width: 6.2rem !important;
}
.wrem-7-2 {
	width: 7.2rem !important;
}
.wrem-8-2 {
	width: 8.2rem !important;
}
.wrem-9-2 {
	width: 9.2rem !important;
}
.wrem-10-2 {
	width: 10.2rem !important;
}
.wrem-15-2 {
	width: 15.2rem !important;
}
.wrem-20-2 {
	width: 20.2rem !important;
}
.wrem-21-2 {
	width: 21.2rem !important;
}
.wrem-22-2 {
	width: 22.2rem !important;
}
.wrem-23-2 {
	width: 23.2rem !important;
}
.wrem-24-2 {
	width: 24.2rem !important;
}
.wrem-25-2 {
	width: 25.2rem !important;
}
.wrem-26-2 {
	width: 26.2rem !important;
}
.wrem-27-2 {
	width: 27.2rem !important;
}
.wrem-28-2 {
	width: 28.2rem !important;
}
.wrem-29-2 {
	width: 29.2rem !important;
}
.wrem-30-2 {
	width: 30.2rem !important;
}
.wrem-31-2 {
	width: 31.2rem !important;
}
.wrem-32-2 {
	width: 32.2rem !important;
}
.wrem-33-2 {
	width: 33.2rem !important;
}
.wrem-34-2 {
	width: 34.2rem !important;
}
.wrem-35-2 {
	width: 35.2rem !important;
}
.wrem-36-2 {
	width: 36.2rem !important;
}
.wrem-37-2 {
	width: 37.2rem !important;
}
.wrem-38-2 {
	width: 38.2rem !important;
}
.wrem-39-2 {
	width: 39.2rem !important;
}
.wrem-40-2 {
	width: 40.2rem !important;
}
.wrem-41-2 {
	width: 41.2rem !important;
}
.wrem-42-2 {
	width: 42.2rem !important;
}
.wrem-43-2 {
	width: 43.2rem !important;
}
.wrem-44-2 {
	width: 44.2rem !important;
}
.wrem-45-2 {
	width: 45.2rem !important;
}
.wrem-46-2 {
	width: 46.2rem !important;
}
.wrem-47-2 {
	width: 47.2rem !important;
}
.wrem-48-2 {
	width: 48.2rem !important;
}
.wrem-49-2 {
	width: 49.2rem !important;
}
.wrem-50-2 {
	width: 50.2rem !important;
}
.wrem-51-2 {
	width: 51.2rem !important;
}
.wrem-52-2 {
	width: 52.2rem !important;
}
.wrem-53-2 {
	width: 53.2rem !important;
}
.wrem-54-2 {
	width: 54.2rem !important;
}
.wrem-55-2 {
	width: 55.2rem !important;
}
.wrem-56-2 {
	width: 56.2rem !important;
}
.wrem-57-2 {
	width: 57.2rem !important;
}
.wrem-58-2 {
	width: 58.2rem !important;
}
.wrem-59-2 {
	width: 59.2rem !important;
}
.wrem-60-2 {
	width: 60.2rem !important;
}
.wrem-61-2 {
	width: 61.2rem !important;
}
.wrem-62-2 {
	width: 62.2rem !important;
}
.wrem-63-2 {
	width: 63.2rem !important;
}
.wrem-64-2 {
	width: 64.2rem !important;
}
.wrem-65-2 {
	width: 65.2rem !important;
}
.wrem-66-2 {
	width: 66.2rem !important;
}
.wrem-67-2 {
	width: 67.2rem !important;
}
.wrem-68-2 {
	width: 68.2rem !important;
}
.wrem-69-2 {
	width: 69.2rem !important;
}
.wrem-70-2 {
	width: 70.2rem !important;
}
.wrem-71-2 {
	width: 71.2rem !important;
}
.wrem-72-2 {
	width: 72.2rem !important;
}
.wrem-73-2 {
	width: 73.2rem !important;
}
.wrem-74-2 {
	width: 74.2rem !important;
}
.wrem-75-2 {
	width: 75.2rem !important;
}
.wrem-76-2 {
	width: 76.2rem !important;
}
.wrem-77-2 {
	width: 77.2rem !important;
}
.wrem-78-2 {
	width: 78.2rem !important;
}
.wrem-79-2 {
	width: 79.2rem !important;
}
.wrem-80-2 {
	width: 80.2rem !important;
}
.wrem-81-2 {
	width: 81.2rem !important;
}
.wrem-82-2 {
	width: 82.2rem !important;
}
.wrem-83-2 {
	width: 83.2rem !important;
}
.wrem-84-2 {
	width: 84.2rem !important;
}
.wrem-85-2 {
	width: 85.2rem !important;
}
.wrem-86-2 {
	width: 86.2rem !important;
}
.wrem-87-2 {
	width: 87.2rem !important;
}
.wrem-88-2 {
	width: 88.2rem !important;
}
.wrem-89-2 {
	width: 89.2rem !important;
}
.wrem-90-2 {
	width: 90.2rem !important;
}
.wrem-91-2 {
	width: 91.2rem !important;
}
.wrem-92-2 {
	width: 92.2rem !important;
}
.wrem-93-2 {
	width: 93.2rem !important;
}
.wrem-94-2 {
	width: 94.2rem !important;
}
.wrem-95-2 {
	width: 95.2rem !important;
}
.wrem-96-2 {
	width: 96.2rem !important;
}
.wrem-97-2 {
	width: 97.2rem !important;
}
.wrem-98-2 {
	width: 98.2rem !important;
}
.wrem-99-2 {
	width: 99.2rem !important;
}

.wrem-1-25 {
	width: 1.25rem !important;
}
.wrem-2-25 {
	width: 2.25rem !important;
}
.wrem-3-25 {
	width: 3.25rem !important;
}
.wrem-4-25 {
	width: 4.25rem !important;
}
.wrem-5-25 {
	width: 5.25rem !important;
}
.wrem-6-25 {
	width: 6.25rem !important;
}
.wrem-7-25 {
	width: 7.25rem !important;
}
.wrem-8-25 {
	width: 8.25rem !important;
}
.wrem-9-25 {
	width: 9.25rem !important;
}
.wrem-10-25 {
	width: 10.25rem !important;
}
.wrem-15-25 {
	width: 15.25rem !important;
}
.wrem-20-25 {
	width: 20.25rem !important;
}
.wrem-21-25 {
	width: 21.25rem !important;
}
.wrem-22-25 {
	width: 22.25rem !important;
}
.wrem-23-25 {
	width: 23.25rem !important;
}
.wrem-24-25 {
	width: 24.25rem !important;
}
.wrem-25-25 {
	width: 25.25rem !important;
}
.wrem-26-25 {
	width: 26.25rem !important;
}
.wrem-27-25 {
	width: 27.25rem !important;
}
.wrem-28-25 {
	width: 28.25rem !important;
}
.wrem-29-25 {
	width: 29.25rem !important;
}
.wrem-30-25 {
	width: 30.25rem !important;
}
.wrem-31-25 {
	width: 31.25rem !important;
}
.wrem-32-25 {
	width: 32.25rem !important;
}
.wrem-33-25 {
	width: 33.25rem !important;
}
.wrem-34-25 {
	width: 34.25rem !important;
}
.wrem-35-25 {
	width: 35.25rem !important;
}
.wrem-36-25 {
	width: 36.25rem !important;
}
.wrem-37-25 {
	width: 37.25rem !important;
}
.wrem-38-25 {
	width: 38.25rem !important;
}
.wrem-39-25 {
	width: 39.25rem !important;
}
.wrem-40-25 {
	width: 40.25rem !important;
}
.wrem-41-25 {
	width: 41.25rem !important;
}
.wrem-42-25 {
	width: 42.25rem !important;
}
.wrem-43-25 {
	width: 43.25rem !important;
}
.wrem-44-25 {
	width: 44.25rem !important;
}
.wrem-45-25 {
	width: 45.25rem !important;
}
.wrem-46-25 {
	width: 46.25rem !important;
}
.wrem-47-25 {
	width: 47.25rem !important;
}
.wrem-48-25 {
	width: 48.25rem !important;
}
.wrem-49-25 {
	width: 49.25rem !important;
}
.wrem-50-25 {
	width: 50.25rem !important;
}
.wrem-51-25 {
	width: 51.25rem !important;
}
.wrem-52-25 {
	width: 52.25rem !important;
}
.wrem-53-25 {
	width: 53.25rem !important;
}
.wrem-54-25 {
	width: 54.25rem !important;
}
.wrem-55-25 {
	width: 55.25rem !important;
}
.wrem-56-25 {
	width: 56.25rem !important;
}
.wrem-57-25 {
	width: 57.25rem !important;
}
.wrem-58-25 {
	width: 58.25rem !important;
}
.wrem-59-25 {
	width: 59.25rem !important;
}
.wrem-60-25 {
	width: 60.25rem !important;
}
.wrem-61-25 {
	width: 61.25rem !important;
}
.wrem-62-25 {
	width: 62.25rem !important;
}
.wrem-63-25 {
	width: 63.25rem !important;
}
.wrem-64-25 {
	width: 64.25rem !important;
}
.wrem-65-25 {
	width: 65.25rem !important;
}
.wrem-66-25 {
	width: 66.25rem !important;
}
.wrem-67-25 {
	width: 67.25rem !important;
}
.wrem-68-25 {
	width: 68.25rem !important;
}
.wrem-69-25 {
	width: 69.25rem !important;
}
.wrem-70-25 {
	width: 70.25rem !important;
}
.wrem-71-25 {
	width: 71.25rem !important;
}
.wrem-72-25 {
	width: 72.25rem !important;
}
.wrem-73-25 {
	width: 73.25rem !important;
}
.wrem-74-25 {
	width: 74.25rem !important;
}
.wrem-75-25 {
	width: 75.25rem !important;
}
.wrem-76-25 {
	width: 76.25rem !important;
}
.wrem-77-25 {
	width: 77.25rem !important;
}
.wrem-78-25 {
	width: 78.25rem !important;
}
.wrem-79-25 {
	width: 79.25rem !important;
}
.wrem-80-25 {
	width: 80.25rem !important;
}
.wrem-81-25 {
	width: 81.25rem !important;
}
.wrem-82-25 {
	width: 82.25rem !important;
}
.wrem-83-25 {
	width: 83.25rem !important;
}
.wrem-84-25 {
	width: 84.25rem !important;
}
.wrem-85-25 {
	width: 85.25rem !important;
}
.wrem-86-25 {
	width: 86.25rem !important;
}
.wrem-87-25 {
	width: 87.25rem !important;
}
.wrem-88-25 {
	width: 88.25rem !important;
}
.wrem-89-25 {
	width: 89.25rem !important;
}
.wrem-90-25 {
	width: 90.25rem !important;
}
.wrem-91-25 {
	width: 91.25rem !important;
}
.wrem-92-25 {
	width: 92.25rem !important;
}
.wrem-93-25 {
	width: 93.25rem !important;
}
.wrem-94-25 {
	width: 94.25rem !important;
}
.wrem-95-25 {
	width: 95.25rem !important;
}
.wrem-96-25 {
	width: 96.25rem !important;
}
.wrem-97-25 {
	width: 97.25rem !important;
}
.wrem-98-25 {
	width: 98.25rem !important;
}
.wrem-99-25 {
	width: 99.25rem !important;
}

.wrem-1-3 {
	width: 1.3rem !important;
}
.wrem-2-3 {
	width: 2.3rem !important;
}
.wrem-3-3 {
	width: 3.3rem !important;
}
.wrem-4-3 {
	width: 4.3rem !important;
}
.wrem-5-3 {
	width: 5.3rem !important;
}
.wrem-6-3 {
	width: 6.3rem !important;
}
.wrem-7-3 {
	width: 7.3rem !important;
}
.wrem-8-3 {
	width: 8.3rem !important;
}
.wrem-9-3 {
	width: 9.3rem !important;
}
.wrem-10-3 {
	width: 10.3rem !important;
}
.wrem-15-3 {
	width: 15.3rem !important;
}
.wrem-20-3 {
	width: 20.3rem !important;
}
.wrem-21-3 {
	width: 21.3rem !important;
}
.wrem-22-3 {
	width: 22.3rem !important;
}
.wrem-23-3 {
	width: 23.3rem !important;
}
.wrem-24-3 {
	width: 24.3rem !important;
}
.wrem-25-3 {
	width: 25.3rem !important;
}
.wrem-26-3 {
	width: 26.3rem !important;
}
.wrem-27-3 {
	width: 27.3rem !important;
}
.wrem-28-3 {
	width: 28.3rem !important;
}
.wrem-29-3 {
	width: 29.3rem !important;
}
.wrem-30-3 {
	width: 30.3rem !important;
}
.wrem-31-3 {
	width: 31.3rem !important;
}
.wrem-32-3 {
	width: 32.3rem !important;
}
.wrem-33-3 {
	width: 33.3rem !important;
}
.wrem-34-3 {
	width: 34.3rem !important;
}
.wrem-35-3 {
	width: 35.3rem !important;
}
.wrem-36-3 {
	width: 36.3rem !important;
}
.wrem-37-3 {
	width: 37.3rem !important;
}
.wrem-38-3 {
	width: 38.3rem !important;
}
.wrem-39-3 {
	width: 39.3rem !important;
}
.wrem-40-3 {
	width: 40.3rem !important;
}
.wrem-41-3 {
	width: 41.3rem !important;
}
.wrem-42-3 {
	width: 42.3rem !important;
}
.wrem-43-3 {
	width: 43.3rem !important;
}
.wrem-44-3 {
	width: 44.3rem !important;
}
.wrem-45-3 {
	width: 45.3rem !important;
}
.wrem-46-3 {
	width: 46.3rem !important;
}
.wrem-47-3 {
	width: 47.3rem !important;
}
.wrem-48-3 {
	width: 48.3rem !important;
}
.wrem-49-3 {
	width: 49.3rem !important;
}
.wrem-50-3 {
	width: 50.3rem !important;
}
.wrem-51-3 {
	width: 51.3rem !important;
}
.wrem-52-3 {
	width: 52.3rem !important;
}
.wrem-53-3 {
	width: 53.3rem !important;
}
.wrem-54-3 {
	width: 54.3rem !important;
}
.wrem-55-3 {
	width: 55.3rem !important;
}
.wrem-56-3 {
	width: 56.3rem !important;
}
.wrem-57-3 {
	width: 57.3rem !important;
}
.wrem-58-3 {
	width: 58.3rem !important;
}
.wrem-59-3 {
	width: 59.3rem !important;
}
.wrem-60-3 {
	width: 60.3rem !important;
}
.wrem-61-3 {
	width: 61.3rem !important;
}
.wrem-62-3 {
	width: 62.3rem !important;
}
.wrem-63-3 {
	width: 63.3rem !important;
}
.wrem-64-3 {
	width: 64.3rem !important;
}
.wrem-65-3 {
	width: 65.3rem !important;
}
.wrem-66-3 {
	width: 66.3rem !important;
}
.wrem-67-3 {
	width: 67.3rem !important;
}
.wrem-68-3 {
	width: 68.3rem !important;
}
.wrem-69-3 {
	width: 69.3rem !important;
}
.wrem-70-3 {
	width: 70.3rem !important;
}
.wrem-71-3 {
	width: 71.3rem !important;
}
.wrem-72-3 {
	width: 72.3rem !important;
}
.wrem-73-3 {
	width: 73.3rem !important;
}
.wrem-74-3 {
	width: 74.3rem !important;
}
.wrem-75-3 {
	width: 75.3rem !important;
}
.wrem-76-3 {
	width: 76.3rem !important;
}
.wrem-77-3 {
	width: 77.3rem !important;
}
.wrem-78-3 {
	width: 78.3rem !important;
}
.wrem-79-3 {
	width: 79.3rem !important;
}
.wrem-80-3 {
	width: 80.3rem !important;
}
.wrem-81-3 {
	width: 81.3rem !important;
}
.wrem-82-3 {
	width: 82.3rem !important;
}
.wrem-83-3 {
	width: 83.3rem !important;
}
.wrem-84-3 {
	width: 84.3rem !important;
}
.wrem-85-3 {
	width: 85.3rem !important;
}
.wrem-86-3 {
	width: 86.3rem !important;
}
.wrem-87-3 {
	width: 87.3rem !important;
}
.wrem-88-3 {
	width: 88.3rem !important;
}
.wrem-89-3 {
	width: 89.3rem !important;
}
.wrem-90-3 {
	width: 90.3rem !important;
}
.wrem-91-3 {
	width: 91.3rem !important;
}
.wrem-92-3 {
	width: 92.3rem !important;
}
.wrem-93-3 {
	width: 93.3rem !important;
}
.wrem-94-3 {
	width: 94.3rem !important;
}
.wrem-95-3 {
	width: 95.3rem !important;
}
.wrem-96-3 {
	width: 96.3rem !important;
}
.wrem-97-3 {
	width: 97.3rem !important;
}
.wrem-98-3 {
	width: 98.3rem !important;
}
.wrem-99-3 {
	width: 99.3rem !important;
}

.wrem-1-35 {
	width: 1.35rem !important;
}
.wrem-2-35 {
	width: 2.35rem !important;
}
.wrem-3-35 {
	width: 3.35rem !important;
}
.wrem-4-35 {
	width: 4.35rem !important;
}
.wrem-5-35 {
	width: 5.35rem !important;
}
.wrem-6-35 {
	width: 6.35rem !important;
}
.wrem-7-35 {
	width: 7.35rem !important;
}
.wrem-8-35 {
	width: 8.35rem !important;
}
.wrem-9-35 {
	width: 9.35rem !important;
}
.wrem-10-35 {
	width: 10.35rem !important;
}
.wrem-15-35 {
	width: 15.35rem !important;
}
.wrem-20-35 {
	width: 20.35rem !important;
}
.wrem-21-35 {
	width: 21.35rem !important;
}
.wrem-22-35 {
	width: 22.35rem !important;
}
.wrem-23-35 {
	width: 23.35rem !important;
}
.wrem-24-35 {
	width: 24.35rem !important;
}
.wrem-25-35 {
	width: 25.35rem !important;
}
.wrem-26-35 {
	width: 26.35rem !important;
}
.wrem-27-35 {
	width: 27.35rem !important;
}
.wrem-28-35 {
	width: 28.35rem !important;
}
.wrem-29-35 {
	width: 29.35rem !important;
}
.wrem-30-35 {
	width: 30.35rem !important;
}
.wrem-31-35 {
	width: 31.35rem !important;
}
.wrem-32-35 {
	width: 32.35rem !important;
}
.wrem-33-35 {
	width: 33.35rem !important;
}
.wrem-34-35 {
	width: 34.35rem !important;
}
.wrem-35-35 {
	width: 35.35rem !important;
}
.wrem-36-35 {
	width: 36.35rem !important;
}
.wrem-37-35 {
	width: 37.35rem !important;
}
.wrem-38-35 {
	width: 38.35rem !important;
}
.wrem-39-35 {
	width: 39.35rem !important;
}
.wrem-40-35 {
	width: 40.35rem !important;
}
.wrem-41-35 {
	width: 41.35rem !important;
}
.wrem-42-35 {
	width: 42.35rem !important;
}
.wrem-43-35 {
	width: 43.35rem !important;
}
.wrem-44-35 {
	width: 44.35rem !important;
}
.wrem-45-35 {
	width: 45.35rem !important;
}
.wrem-46-35 {
	width: 46.35rem !important;
}
.wrem-47-35 {
	width: 47.35rem !important;
}
.wrem-48-35 {
	width: 48.35rem !important;
}
.wrem-49-35 {
	width: 49.35rem !important;
}
.wrem-50-35 {
	width: 50.35rem !important;
}
.wrem-51-35 {
	width: 51.35rem !important;
}
.wrem-52-35 {
	width: 52.35rem !important;
}
.wrem-53-35 {
	width: 53.35rem !important;
}
.wrem-54-35 {
	width: 54.35rem !important;
}
.wrem-55-35 {
	width: 55.35rem !important;
}
.wrem-56-35 {
	width: 56.35rem !important;
}
.wrem-57-35 {
	width: 57.35rem !important;
}
.wrem-58-35 {
	width: 58.35rem !important;
}
.wrem-59-35 {
	width: 59.35rem !important;
}
.wrem-60-35 {
	width: 60.35rem !important;
}
.wrem-61-35 {
	width: 61.35rem !important;
}
.wrem-62-35 {
	width: 62.35rem !important;
}
.wrem-63-35 {
	width: 63.35rem !important;
}
.wrem-64-35 {
	width: 64.35rem !important;
}
.wrem-65-35 {
	width: 65.35rem !important;
}
.wrem-66-35 {
	width: 66.35rem !important;
}
.wrem-67-35 {
	width: 67.35rem !important;
}
.wrem-68-35 {
	width: 68.35rem !important;
}
.wrem-69-35 {
	width: 69.35rem !important;
}
.wrem-70-35 {
	width: 70.35rem !important;
}
.wrem-71-35 {
	width: 71.35rem !important;
}
.wrem-72-35 {
	width: 72.35rem !important;
}
.wrem-73-35 {
	width: 73.35rem !important;
}
.wrem-74-35 {
	width: 74.35rem !important;
}
.wrem-75-35 {
	width: 75.35rem !important;
}
.wrem-76-35 {
	width: 76.35rem !important;
}
.wrem-77-35 {
	width: 77.35rem !important;
}
.wrem-78-35 {
	width: 78.35rem !important;
}
.wrem-79-35 {
	width: 79.35rem !important;
}
.wrem-80-35 {
	width: 80.35rem !important;
}
.wrem-81-35 {
	width: 81.35rem !important;
}
.wrem-82-35 {
	width: 82.35rem !important;
}
.wrem-83-35 {
	width: 83.35rem !important;
}
.wrem-84-35 {
	width: 84.35rem !important;
}
.wrem-85-35 {
	width: 85.35rem !important;
}
.wrem-86-35 {
	width: 86.35rem !important;
}
.wrem-87-35 {
	width: 87.35rem !important;
}
.wrem-88-35 {
	width: 88.35rem !important;
}
.wrem-89-35 {
	width: 89.35rem !important;
}
.wrem-90-35 {
	width: 90.35rem !important;
}
.wrem-91-35 {
	width: 91.35rem !important;
}
.wrem-92-35 {
	width: 92.35rem !important;
}
.wrem-93-35 {
	width: 93.35rem !important;
}
.wrem-94-35 {
	width: 94.35rem !important;
}
.wrem-95-35 {
	width: 95.35rem !important;
}
.wrem-96-35 {
	width: 96.35rem !important;
}
.wrem-97-35 {
	width: 97.35rem !important;
}
.wrem-98-35 {
	width: 98.35rem !important;
}
.wrem-99-35 {
	width: 99.35rem !important;
}

.wrem-1-4 {
	width: 1.4rem !important;
}
.wrem-2-4 {
	width: 2.4rem !important;
}
.wrem-3-4 {
	width: 3.4rem !important;
}
.wrem-4-4 {
	width: 4.4rem !important;
}
.wrem-5-4 {
	width: 5.4rem !important;
}
.wrem-6-4 {
	width: 6.4rem !important;
}
.wrem-7-4 {
	width: 7.4rem !important;
}
.wrem-8-4 {
	width: 8.4rem !important;
}
.wrem-9-4 {
	width: 9.4rem !important;
}
.wrem-10-4 {
	width: 10.4rem !important;
}
.wrem-15-4 {
	width: 15.4rem !important;
}
.wrem-20-4 {
	width: 20.4rem !important;
}
.wrem-21-4 {
	width: 21.4rem !important;
}
.wrem-22-4 {
	width: 22.4rem !important;
}
.wrem-23-4 {
	width: 23.4rem !important;
}
.wrem-24-4 {
	width: 24.4rem !important;
}
.wrem-25-4 {
	width: 25.4rem !important;
}
.wrem-26-4 {
	width: 26.4rem !important;
}
.wrem-27-4 {
	width: 27.4rem !important;
}
.wrem-28-4 {
	width: 28.4rem !important;
}
.wrem-29-4 {
	width: 29.4rem !important;
}
.wrem-30-4 {
	width: 30.4rem !important;
}
.wrem-31-4 {
	width: 31.4rem !important;
}
.wrem-32-4 {
	width: 32.4rem !important;
}
.wrem-33-4 {
	width: 33.4rem !important;
}
.wrem-34-4 {
	width: 34.4rem !important;
}
.wrem-35-4 {
	width: 35.4rem !important;
}
.wrem-36-4 {
	width: 36.4rem !important;
}
.wrem-37-4 {
	width: 37.4rem !important;
}
.wrem-38-4 {
	width: 38.4rem !important;
}
.wrem-39-4 {
	width: 39.4rem !important;
}
.wrem-40-4 {
	width: 40.4rem !important;
}
.wrem-41-4 {
	width: 41.4rem !important;
}
.wrem-42-4 {
	width: 42.4rem !important;
}
.wrem-43-4 {
	width: 43.4rem !important;
}
.wrem-44-4 {
	width: 44.4rem !important;
}
.wrem-45-4 {
	width: 45.4rem !important;
}
.wrem-46-4 {
	width: 46.4rem !important;
}
.wrem-47-4 {
	width: 47.4rem !important;
}
.wrem-48-4 {
	width: 48.4rem !important;
}
.wrem-49-4 {
	width: 49.4rem !important;
}
.wrem-50-4 {
	width: 50.4rem !important;
}
.wrem-51-4 {
	width: 51.4rem !important;
}
.wrem-52-4 {
	width: 52.4rem !important;
}
.wrem-53-4 {
	width: 53.4rem !important;
}
.wrem-54-4 {
	width: 54.4rem !important;
}
.wrem-55-4 {
	width: 55.4rem !important;
}
.wrem-56-4 {
	width: 56.4rem !important;
}
.wrem-57-4 {
	width: 57.4rem !important;
}
.wrem-58-4 {
	width: 58.4rem !important;
}
.wrem-59-4 {
	width: 59.4rem !important;
}
.wrem-60-4 {
	width: 60.4rem !important;
}
.wrem-61-4 {
	width: 61.4rem !important;
}
.wrem-62-4 {
	width: 62.4rem !important;
}
.wrem-63-4 {
	width: 63.4rem !important;
}
.wrem-64-4 {
	width: 64.4rem !important;
}
.wrem-65-4 {
	width: 65.4rem !important;
}
.wrem-66-4 {
	width: 66.4rem !important;
}
.wrem-67-4 {
	width: 67.4rem !important;
}
.wrem-68-4 {
	width: 68.4rem !important;
}
.wrem-69-4 {
	width: 69.4rem !important;
}
.wrem-70-4 {
	width: 70.4rem !important;
}
.wrem-71-4 {
	width: 71.4rem !important;
}
.wrem-72-4 {
	width: 72.4rem !important;
}
.wrem-73-4 {
	width: 73.4rem !important;
}
.wrem-74-4 {
	width: 74.4rem !important;
}
.wrem-75-4 {
	width: 75.4rem !important;
}
.wrem-76-4 {
	width: 76.4rem !important;
}
.wrem-77-4 {
	width: 77.4rem !important;
}
.wrem-78-4 {
	width: 78.4rem !important;
}
.wrem-79-4 {
	width: 79.4rem !important;
}
.wrem-80-4 {
	width: 80.4rem !important;
}
.wrem-81-4 {
	width: 81.4rem !important;
}
.wrem-82-4 {
	width: 82.4rem !important;
}
.wrem-83-4 {
	width: 83.4rem !important;
}
.wrem-84-4 {
	width: 84.4rem !important;
}
.wrem-85-4 {
	width: 85.4rem !important;
}
.wrem-86-4 {
	width: 86.4rem !important;
}
.wrem-87-4 {
	width: 87.4rem !important;
}
.wrem-88-4 {
	width: 88.4rem !important;
}
.wrem-89-4 {
	width: 89.4rem !important;
}
.wrem-90-4 {
	width: 90.4rem !important;
}
.wrem-91-4 {
	width: 91.4rem !important;
}
.wrem-92-4 {
	width: 92.4rem !important;
}
.wrem-93-4 {
	width: 93.4rem !important;
}
.wrem-94-4 {
	width: 94.4rem !important;
}
.wrem-95-4 {
	width: 95.4rem !important;
}
.wrem-96-4 {
	width: 96.4rem !important;
}
.wrem-97-4 {
	width: 97.4rem !important;
}
.wrem-98-4 {
	width: 98.4rem !important;
}
.wrem-99-4 {
	width: 99.4rem !important;
}

.wrem-1-45 {
	width: 1.45rem !important;
}
.wrem-2-45 {
	width: 2.45rem !important;
}
.wrem-3-45 {
	width: 3.45rem !important;
}
.wrem-4-45 {
	width: 4.45rem !important;
}
.wrem-5-45 {
	width: 5.45rem !important;
}
.wrem-6-45 {
	width: 6.45rem !important;
}
.wrem-7-45 {
	width: 7.45rem !important;
}
.wrem-8-45 {
	width: 8.45rem !important;
}
.wrem-9-45 {
	width: 9.45rem !important;
}
.wrem-10-45 {
	width: 10.45rem !important;
}
.wrem-15-45 {
	width: 15.45rem !important;
}
.wrem-20-45 {
	width: 20.45rem !important;
}
.wrem-21-45 {
	width: 21.45rem !important;
}
.wrem-22-45 {
	width: 22.45rem !important;
}
.wrem-23-45 {
	width: 23.45rem !important;
}
.wrem-24-45 {
	width: 24.45rem !important;
}
.wrem-25-45 {
	width: 25.45rem !important;
}
.wrem-26-45 {
	width: 26.45rem !important;
}
.wrem-27-45 {
	width: 27.45rem !important;
}
.wrem-28-45 {
	width: 28.45rem !important;
}
.wrem-29-45 {
	width: 29.45rem !important;
}
.wrem-30-45 {
	width: 30.45rem !important;
}
.wrem-31-45 {
	width: 31.45rem !important;
}
.wrem-32-45 {
	width: 32.45rem !important;
}
.wrem-33-45 {
	width: 33.45rem !important;
}
.wrem-34-45 {
	width: 34.45rem !important;
}
.wrem-35-45 {
	width: 35.45rem !important;
}
.wrem-36-45 {
	width: 36.45rem !important;
}
.wrem-37-45 {
	width: 37.45rem !important;
}
.wrem-38-45 {
	width: 38.45rem !important;
}
.wrem-39-45 {
	width: 39.45rem !important;
}
.wrem-40-45 {
	width: 40.45rem !important;
}
.wrem-41-45 {
	width: 41.45rem !important;
}
.wrem-42-45 {
	width: 42.45rem !important;
}
.wrem-43-45 {
	width: 43.45rem !important;
}
.wrem-44-45 {
	width: 44.45rem !important;
}
.wrem-45-45 {
	width: 45.45rem !important;
}
.wrem-46-45 {
	width: 46.45rem !important;
}
.wrem-47-45 {
	width: 47.45rem !important;
}
.wrem-48-45 {
	width: 48.45rem !important;
}
.wrem-49-45 {
	width: 49.45rem !important;
}
.wrem-50-45 {
	width: 50.45rem !important;
}
.wrem-51-45 {
	width: 51.45rem !important;
}
.wrem-52-45 {
	width: 52.45rem !important;
}
.wrem-53-45 {
	width: 53.45rem !important;
}
.wrem-54-45 {
	width: 54.45rem !important;
}
.wrem-55-45 {
	width: 55.45rem !important;
}
.wrem-56-45 {
	width: 56.45rem !important;
}
.wrem-57-45 {
	width: 57.45rem !important;
}
.wrem-58-45 {
	width: 58.45rem !important;
}
.wrem-59-45 {
	width: 59.45rem !important;
}
.wrem-60-45 {
	width: 60.45rem !important;
}
.wrem-61-45 {
	width: 61.45rem !important;
}
.wrem-62-45 {
	width: 62.45rem !important;
}
.wrem-63-45 {
	width: 63.45rem !important;
}
.wrem-64-45 {
	width: 64.45rem !important;
}
.wrem-65-45 {
	width: 65.45rem !important;
}
.wrem-66-45 {
	width: 66.45rem !important;
}
.wrem-67-45 {
	width: 67.45rem !important;
}
.wrem-68-45 {
	width: 68.45rem !important;
}
.wrem-69-45 {
	width: 69.45rem !important;
}
.wrem-70-45 {
	width: 70.45rem !important;
}
.wrem-71-45 {
	width: 71.45rem !important;
}
.wrem-72-45 {
	width: 72.45rem !important;
}
.wrem-73-45 {
	width: 73.45rem !important;
}
.wrem-74-45 {
	width: 74.45rem !important;
}
.wrem-75-45 {
	width: 75.45rem !important;
}
.wrem-76-45 {
	width: 76.45rem !important;
}
.wrem-77-45 {
	width: 77.45rem !important;
}
.wrem-78-45 {
	width: 78.45rem !important;
}
.wrem-79-45 {
	width: 79.45rem !important;
}
.wrem-80-45 {
	width: 80.45rem !important;
}
.wrem-81-45 {
	width: 81.45rem !important;
}
.wrem-82-45 {
	width: 82.45rem !important;
}
.wrem-83-45 {
	width: 83.45rem !important;
}
.wrem-84-45 {
	width: 84.45rem !important;
}
.wrem-85-45 {
	width: 85.45rem !important;
}
.wrem-86-45 {
	width: 86.45rem !important;
}
.wrem-87-45 {
	width: 87.45rem !important;
}
.wrem-88-45 {
	width: 88.45rem !important;
}
.wrem-89-45 {
	width: 89.45rem !important;
}
.wrem-90-45 {
	width: 90.45rem !important;
}
.wrem-91-45 {
	width: 91.45rem !important;
}
.wrem-92-45 {
	width: 92.45rem !important;
}
.wrem-93-45 {
	width: 93.45rem !important;
}
.wrem-94-45 {
	width: 94.45rem !important;
}
.wrem-95-45 {
	width: 95.45rem !important;
}
.wrem-96-45 {
	width: 96.45rem !important;
}
.wrem-97-45 {
	width: 97.45rem !important;
}
.wrem-98-45 {
	width: 98.45rem !important;
}
.wrem-99-45 {
	width: 99.45rem !important;
}

.wrem-1-5 {
	width: 1.5rem !important;
}
.wrem-2-5 {
	width: 2.5rem !important;
}
.wrem-3-5 {
	width: 3.5rem !important;
}
.wrem-4-5 {
	width: 4.5rem !important;
}
.wrem-5-5 {
	width: 5.5rem !important;
}
.wrem-6-5 {
	width: 6.5rem !important;
}
.wrem-7-5 {
	width: 7.5rem !important;
}
.wrem-8-5 {
	width: 8.5rem !important;
}
.wrem-9-5 {
	width: 9.5rem !important;
}
.wrem-10-5 {
	width: 10.5rem !important;
}
.wrem-15-5 {
	width: 15.5rem !important;
}
.wrem-20-5 {
	width: 20.5rem !important;
}
.wrem-21-5 {
	width: 21.5rem !important;
}
.wrem-22-5 {
	width: 22.5rem !important;
}
.wrem-23-5 {
	width: 23.5rem !important;
}
.wrem-24-5 {
	width: 24.5rem !important;
}
.wrem-25-5 {
	width: 25.5rem !important;
}
.wrem-26-5 {
	width: 26.5rem !important;
}
.wrem-27-5 {
	width: 27.5rem !important;
}
.wrem-28-5 {
	width: 28.5rem !important;
}
.wrem-29-5 {
	width: 29.5rem !important;
}
.wrem-30-5 {
	width: 30.5rem !important;
}
.wrem-31-5 {
	width: 31.5rem !important;
}
.wrem-32-5 {
	width: 32.5rem !important;
}
.wrem-33-5 {
	width: 33.5rem !important;
}
.wrem-34-5 {
	width: 34.5rem !important;
}
.wrem-35-5 {
	width: 35.5rem !important;
}
.wrem-36-5 {
	width: 36.5rem !important;
}
.wrem-37-5 {
	width: 37.5rem !important;
}
.wrem-38-5 {
	width: 38.5rem !important;
}
.wrem-39-5 {
	width: 39.5rem !important;
}
.wrem-40-5 {
	width: 40.5rem !important;
}
.wrem-41-5 {
	width: 41.5rem !important;
}
.wrem-42-5 {
	width: 42.5rem !important;
}
.wrem-43-5 {
	width: 43.5rem !important;
}
.wrem-44-5 {
	width: 44.5rem !important;
}
.wrem-45-5 {
	width: 45.5rem !important;
}
.wrem-46-5 {
	width: 46.5rem !important;
}
.wrem-47-5 {
	width: 47.5rem !important;
}
.wrem-48-5 {
	width: 48.5rem !important;
}
.wrem-49-5 {
	width: 49.5rem !important;
}
.wrem-50-5 {
	width: 50.5rem !important;
}
.wrem-51-5 {
	width: 51.5rem !important;
}
.wrem-52-5 {
	width: 52.5rem !important;
}
.wrem-53-5 {
	width: 53.5rem !important;
}
.wrem-54-5 {
	width: 54.5rem !important;
}
.wrem-55-5 {
	width: 55.5rem !important;
}
.wrem-56-5 {
	width: 56.5rem !important;
}
.wrem-57-5 {
	width: 57.5rem !important;
}
.wrem-58-5 {
	width: 58.5rem !important;
}
.wrem-59-5 {
	width: 59.5rem !important;
}
.wrem-60-5 {
	width: 60.5rem !important;
}
.wrem-61-5 {
	width: 61.5rem !important;
}
.wrem-62-5 {
	width: 62.5rem !important;
}
.wrem-63-5 {
	width: 63.5rem !important;
}
.wrem-64-5 {
	width: 64.5rem !important;
}
.wrem-65-5 {
	width: 65.5rem !important;
}
.wrem-66-5 {
	width: 66.5rem !important;
}
.wrem-67-5 {
	width: 67.5rem !important;
}
.wrem-68-5 {
	width: 68.5rem !important;
}
.wrem-69-5 {
	width: 69.5rem !important;
}
.wrem-70-5 {
	width: 70.5rem !important;
}
.wrem-71-5 {
	width: 71.5rem !important;
}
.wrem-72-5 {
	width: 72.5rem !important;
}
.wrem-73-5 {
	width: 73.5rem !important;
}
.wrem-74-5 {
	width: 74.5rem !important;
}
.wrem-75-5 {
	width: 75.5rem !important;
}
.wrem-76-5 {
	width: 76.5rem !important;
}
.wrem-77-5 {
	width: 77.5rem !important;
}
.wrem-78-5 {
	width: 78.5rem !important;
}
.wrem-79-5 {
	width: 79.5rem !important;
}
.wrem-80-5 {
	width: 80.5rem !important;
}
.wrem-81-5 {
	width: 81.5rem !important;
}
.wrem-82-5 {
	width: 82.5rem !important;
}
.wrem-83-5 {
	width: 83.5rem !important;
}
.wrem-84-5 {
	width: 84.5rem !important;
}
.wrem-85-5 {
	width: 85.5rem !important;
}
.wrem-86-5 {
	width: 86.5rem !important;
}
.wrem-87-5 {
	width: 87.5rem !important;
}
.wrem-88-5 {
	width: 88.5rem !important;
}
.wrem-89-5 {
	width: 89.5rem !important;
}
.wrem-90-5 {
	width: 90.5rem !important;
}
.wrem-91-5 {
	width: 91.5rem !important;
}
.wrem-92-5 {
	width: 92.5rem !important;
}
.wrem-93-5 {
	width: 93.5rem !important;
}
.wrem-94-5 {
	width: 94.5rem !important;
}
.wrem-95-5 {
	width: 95.5rem !important;
}
.wrem-96-5 {
	width: 96.5rem !important;
}
.wrem-97-5 {
	width: 97.5rem !important;
}
.wrem-98-5 {
	width: 98.5rem !important;
}
.wrem-99-5 {
	width: 99.5rem !important;
}



.wrem-1-55 {
	width: 1.55rem !important;
}
.wrem-2-55 {
	width: 2.55rem !important;
}
.wrem-3-55 {
	width: 3.55rem !important;
}
.wrem-4-55 {
	width: 4.55rem !important;
}
.wrem-5-55 {
	width: 5.55rem !important;
}
.wrem-6-55 {
	width: 6.55rem !important;
}
.wrem-7-55 {
	width: 7.55rem !important;
}
.wrem-8-55 {
	width: 8.55rem !important;
}
.wrem-9-55 {
	width: 9.55rem !important;
}
.wrem-10-55 {
	width: 10.55rem !important;
}
.wrem-15-55 {
	width: 15.55rem !important;
}
.wrem-20-55 {
	width: 20.55rem !important;
}
.wrem-21-55 {
	width: 21.55rem !important;
}
.wrem-22-55 {
	width: 22.55rem !important;
}
.wrem-23-55 {
	width: 23.55rem !important;
}
.wrem-24-55 {
	width: 24.55rem !important;
}
.wrem-25-55 {
	width: 25.55rem !important;
}
.wrem-26-55 {
	width: 26.55rem !important;
}
.wrem-27-55 {
	width: 27.55rem !important;
}
.wrem-28-55 {
	width: 28.55rem !important;
}
.wrem-29-55 {
	width: 29.55rem !important;
}
.wrem-30-55 {
	width: 30.55rem !important;
}
.wrem-31-55 {
	width: 31.55rem !important;
}
.wrem-32-55 {
	width: 32.55rem !important;
}
.wrem-33-55 {
	width: 33.55rem !important;
}
.wrem-34-55 {
	width: 34.55rem !important;
}
.wrem-35-55 {
	width: 35.55rem !important;
}
.wrem-36-55 {
	width: 36.55rem !important;
}
.wrem-37-55 {
	width: 37.55rem !important;
}
.wrem-38-55 {
	width: 38.55rem !important;
}
.wrem-39-55 {
	width: 39.55rem !important;
}
.wrem-40-55 {
	width: 40.55rem !important;
}
.wrem-41-55 {
	width: 41.55rem !important;
}
.wrem-42-55 {
	width: 42.55rem !important;
}
.wrem-43-55 {
	width: 43.55rem !important;
}
.wrem-44-55 {
	width: 44.55rem !important;
}
.wrem-45-55 {
	width: 45.55rem !important;
}
.wrem-46-55 {
	width: 46.55rem !important;
}
.wrem-47-55 {
	width: 47.55rem !important;
}
.wrem-48-55 {
	width: 48.55rem !important;
}
.wrem-49-55 {
	width: 49.55rem !important;
}
.wrem-50-55 {
	width: 50.55rem !important;
}
.wrem-51-55 {
	width: 51.55rem !important;
}
.wrem-52-55 {
	width: 52.55rem !important;
}
.wrem-53-55 {
	width: 53.55rem !important;
}
.wrem-54-55 {
	width: 54.55rem !important;
}
.wrem-55-55 {
	width: 55.55rem !important;
}
.wrem-56-55 {
	width: 56.55rem !important;
}
.wrem-57-55 {
	width: 57.55rem !important;
}
.wrem-58-55 {
	width: 58.55rem !important;
}
.wrem-59-55 {
	width: 59.55rem !important;
}
.wrem-60-55 {
	width: 60.55rem !important;
}
.wrem-61-55 {
	width: 61.55rem !important;
}
.wrem-62-55 {
	width: 62.55rem !important;
}
.wrem-63-55 {
	width: 63.55rem !important;
}
.wrem-64-55 {
	width: 64.55rem !important;
}
.wrem-65-55 {
	width: 65.55rem !important;
}
.wrem-66-55 {
	width: 66.55rem !important;
}
.wrem-67-55 {
	width: 67.55rem !important;
}
.wrem-68-55 {
	width: 68.55rem !important;
}
.wrem-69-55 {
	width: 69.55rem !important;
}
.wrem-70-55 {
	width: 70.55rem !important;
}
.wrem-71-55 {
	width: 71.55rem !important;
}
.wrem-72-55 {
	width: 72.55rem !important;
}
.wrem-73-55 {
	width: 73.55rem !important;
}
.wrem-74-55 {
	width: 74.55rem !important;
}
.wrem-75-55 {
	width: 75.55rem !important;
}
.wrem-76-55 {
	width: 76.55rem !important;
}
.wrem-77-55 {
	width: 77.55rem !important;
}
.wrem-78-55 {
	width: 78.55rem !important;
}
.wrem-79-55 {
	width: 79.55rem !important;
}
.wrem-80-55 {
	width: 80.55rem !important;
}
.wrem-81-55 {
	width: 81.55rem !important;
}
.wrem-82-55 {
	width: 82.55rem !important;
}
.wrem-83-55 {
	width: 83.55rem !important;
}
.wrem-84-55 {
	width: 84.55rem !important;
}
.wrem-85-55 {
	width: 85.55rem !important;
}
.wrem-86-55 {
	width: 86.55rem !important;
}
.wrem-87-55 {
	width: 87.55rem !important;
}
.wrem-88-55 {
	width: 88.55rem !important;
}
.wrem-89-55 {
	width: 89.55rem !important;
}
.wrem-90-55 {
	width: 90.55rem !important;
}
.wrem-91-55 {
	width: 91.55rem !important;
}
.wrem-92-55 {
	width: 92.55rem !important;
}
.wrem-93-55 {
	width: 93.55rem !important;
}
.wrem-94-55 {
	width: 94.55rem !important;
}
.wrem-95-55 {
	width: 95.55rem !important;
}
.wrem-96-55 {
	width: 96.55rem !important;
}
.wrem-97-55 {
	width: 97.55rem !important;
}
.wrem-98-55 {
	width: 98.55rem !important;
}
.wrem-99-55 {
	width: 99.55rem !important;
}



.wrem-1-6 {
	width: 1.6rem !important;
}
.wrem-2-6 {
	width: 2.6rem !important;
}
.wrem-3-6 {
	width: 3.6rem !important;
}
.wrem-4-6 {
	width: 4.6rem !important;
}
.wrem-5-6 {
	width: 5.6rem !important;
}
.wrem-6-6 {
	width: 6.6rem !important;
}
.wrem-7-6 {
	width: 7.6rem !important;
}
.wrem-8-6 {
	width: 8.6rem !important;
}
.wrem-9-6 {
	width: 9.6rem !important;
}
.wrem-10-6 {
	width: 10.6rem !important;
}
.wrem-15-6 {
	width: 15.6rem !important;
}
.wrem-20-6 {
	width: 20.6rem !important;
}
.wrem-21-6 {
	width: 21.6rem !important;
}
.wrem-22-6 {
	width: 22.6rem !important;
}
.wrem-23-6 {
	width: 23.6rem !important;
}
.wrem-24-6 {
	width: 24.6rem !important;
}
.wrem-25-6 {
	width: 25.6rem !important;
}
.wrem-26-6 {
	width: 26.6rem !important;
}
.wrem-27-6 {
	width: 27.6rem !important;
}
.wrem-28-6 {
	width: 28.6rem !important;
}
.wrem-29-6 {
	width: 29.6rem !important;
}
.wrem-30-6 {
	width: 30.6rem !important;
}
.wrem-31-6 {
	width: 31.6rem !important;
}
.wrem-32-6 {
	width: 32.6rem !important;
}
.wrem-33-6 {
	width: 33.6rem !important;
}
.wrem-34-6 {
	width: 34.6rem !important;
}
.wrem-35-6 {
	width: 35.6rem !important;
}
.wrem-36-6 {
	width: 36.6rem !important;
}
.wrem-37-6 {
	width: 37.6rem !important;
}
.wrem-38-6 {
	width: 38.6rem !important;
}
.wrem-39-6 {
	width: 39.6rem !important;
}
.wrem-40-6 {
	width: 40.6rem !important;
}
.wrem-41-6 {
	width: 41.6rem !important;
}
.wrem-42-6 {
	width: 42.6rem !important;
}
.wrem-43-6 {
	width: 43.6rem !important;
}
.wrem-44-6 {
	width: 44.6rem !important;
}
.wrem-45-6 {
	width: 45.6rem !important;
}
.wrem-46-6 {
	width: 46.6rem !important;
}
.wrem-47-6 {
	width: 47.6rem !important;
}
.wrem-48-6 {
	width: 48.6rem !important;
}
.wrem-49-6 {
	width: 49.6rem !important;
}
.wrem-50-6 {
	width: 50.6rem !important;
}
.wrem-51-6 {
	width: 51.6rem !important;
}
.wrem-52-6 {
	width: 52.6rem !important;
}
.wrem-53-6 {
	width: 53.6rem !important;
}
.wrem-54-6 {
	width: 54.6rem !important;
}
.wrem-55-6 {
	width: 55.6rem !important;
}
.wrem-56-6 {
	width: 56.6rem !important;
}
.wrem-57-6 {
	width: 57.6rem !important;
}
.wrem-58-6 {
	width: 58.6rem !important;
}
.wrem-59-6 {
	width: 59.6rem !important;
}
.wrem-60-6 {
	width: 60.6rem !important;
}
.wrem-61-6 {
	width: 61.6rem !important;
}
.wrem-62-6 {
	width: 62.6rem !important;
}
.wrem-63-6 {
	width: 63.6rem !important;
}
.wrem-64-6 {
	width: 64.6rem !important;
}
.wrem-65-6 {
	width: 65.6rem !important;
}
.wrem-66-6 {
	width: 66.6rem !important;
}
.wrem-67-6 {
	width: 67.6rem !important;
}
.wrem-68-6 {
	width: 68.6rem !important;
}
.wrem-69-6 {
	width: 69.6rem !important;
}
.wrem-70-6 {
	width: 70.6rem !important;
}
.wrem-71-6 {
	width: 71.6rem !important;
}
.wrem-72-6 {
	width: 72.6rem !important;
}
.wrem-73-6 {
	width: 73.6rem !important;
}
.wrem-74-6 {
	width: 74.6rem !important;
}
.wrem-75-6 {
	width: 75.6rem !important;
}
.wrem-76-6 {
	width: 76.6rem !important;
}
.wrem-77-6 {
	width: 77.6rem !important;
}
.wrem-78-6 {
	width: 78.6rem !important;
}
.wrem-79-6 {
	width: 79.6rem !important;
}
.wrem-80-6 {
	width: 80.6rem !important;
}
.wrem-81-6 {
	width: 81.6rem !important;
}
.wrem-82-6 {
	width: 82.6rem !important;
}
.wrem-83-6 {
	width: 83.6rem !important;
}
.wrem-84-6 {
	width: 84.6rem !important;
}
.wrem-85-6 {
	width: 85.6rem !important;
}
.wrem-86-6 {
	width: 86.6rem !important;
}
.wrem-87-6 {
	width: 87.6rem !important;
}
.wrem-88-6 {
	width: 88.6rem !important;
}
.wrem-89-6 {
	width: 89.6rem !important;
}
.wrem-90-6 {
	width: 90.6rem !important;
}
.wrem-91-6 {
	width: 91.6rem !important;
}
.wrem-92-6 {
	width: 92.6rem !important;
}
.wrem-93-6 {
	width: 93.6rem !important;
}
.wrem-94-6 {
	width: 94.6rem !important;
}
.wrem-95-6 {
	width: 95.6rem !important;
}
.wrem-96-6 {
	width: 96.6rem !important;
}
.wrem-97-6 {
	width: 97.6rem !important;
}
.wrem-98-6 {
	width: 98.6rem !important;
}
.wrem-99-6 {
	width: 99.6rem !important;
}



.wrem-1-65 {
	width: 1.65rem !important;
}
.wrem-2-65 {
	width: 2.65rem !important;
}
.wrem-3-65 {
	width: 3.65rem !important;
}
.wrem-4-65 {
	width: 4.65rem !important;
}
.wrem-5-65 {
	width: 5.65rem !important;
}
.wrem-6-65 {
	width: 6.65rem !important;
}
.wrem-7-65 {
	width: 7.65rem !important;
}
.wrem-8-65 {
	width: 8.65rem !important;
}
.wrem-9-65 {
	width: 9.65rem !important;
}
.wrem-10-65 {
	width: 10.65rem !important;
}
.wrem-15-65 {
	width: 15.65rem !important;
}
.wrem-20-65 {
	width: 20.65rem !important;
}
.wrem-21-65 {
	width: 21.65rem !important;
}
.wrem-22-65 {
	width: 22.65rem !important;
}
.wrem-23-65 {
	width: 23.65rem !important;
}
.wrem-24-65 {
	width: 24.65rem !important;
}
.wrem-25-65 {
	width: 25.65rem !important;
}
.wrem-26-65 {
	width: 26.65rem !important;
}
.wrem-27-65 {
	width: 27.65rem !important;
}
.wrem-28-65 {
	width: 28.65rem !important;
}
.wrem-29-65 {
	width: 29.65rem !important;
}
.wrem-30-65 {
	width: 30.65rem !important;
}
.wrem-31-65 {
	width: 31.65rem !important;
}
.wrem-32-65 {
	width: 32.65rem !important;
}
.wrem-33-65 {
	width: 33.65rem !important;
}
.wrem-34-65 {
	width: 34.65rem !important;
}
.wrem-35-65 {
	width: 35.65rem !important;
}
.wrem-36-65 {
	width: 36.65rem !important;
}
.wrem-37-65 {
	width: 37.65rem !important;
}
.wrem-38-65 {
	width: 38.65rem !important;
}
.wrem-39-65 {
	width: 39.65rem !important;
}
.wrem-40-65 {
	width: 40.65rem !important;
}
.wrem-41-65 {
	width: 41.65rem !important;
}
.wrem-42-65 {
	width: 42.65rem !important;
}
.wrem-43-65 {
	width: 43.65rem !important;
}
.wrem-44-65 {
	width: 44.65rem !important;
}
.wrem-45-65 {
	width: 45.65rem !important;
}
.wrem-46-65 {
	width: 46.65rem !important;
}
.wrem-47-65 {
	width: 47.65rem !important;
}
.wrem-48-65 {
	width: 48.65rem !important;
}
.wrem-49-65 {
	width: 49.65rem !important;
}
.wrem-50-65 {
	width: 50.65rem !important;
}
.wrem-51-65 {
	width: 51.65rem !important;
}
.wrem-52-65 {
	width: 52.65rem !important;
}
.wrem-53-65 {
	width: 53.65rem !important;
}
.wrem-54-65 {
	width: 54.65rem !important;
}
.wrem-55-65 {
	width: 55.65rem !important;
}
.wrem-56-65 {
	width: 56.65rem !important;
}
.wrem-57-65 {
	width: 57.65rem !important;
}
.wrem-58-65 {
	width: 58.65rem !important;
}
.wrem-59-65 {
	width: 59.65rem !important;
}
.wrem-60-65 {
	width: 60.65rem !important;
}
.wrem-61-65 {
	width: 61.65rem !important;
}
.wrem-62-65 {
	width: 62.65rem !important;
}
.wrem-63-65 {
	width: 63.65rem !important;
}
.wrem-64-65 {
	width: 64.65rem !important;
}
.wrem-65-65 {
	width: 65.65rem !important;
}
.wrem-66-65 {
	width: 66.65rem !important;
}
.wrem-67-65 {
	width: 67.65rem !important;
}
.wrem-68-65 {
	width: 68.65rem !important;
}
.wrem-69-65 {
	width: 69.65rem !important;
}
.wrem-70-65 {
	width: 70.65rem !important;
}
.wrem-71-65 {
	width: 71.65rem !important;
}
.wrem-72-65 {
	width: 72.65rem !important;
}
.wrem-73-65 {
	width: 73.65rem !important;
}
.wrem-74-65 {
	width: 74.65rem !important;
}
.wrem-75-65 {
	width: 75.65rem !important;
}
.wrem-76-65 {
	width: 76.65rem !important;
}
.wrem-77-65 {
	width: 77.65rem !important;
}
.wrem-78-65 {
	width: 78.65rem !important;
}
.wrem-79-65 {
	width: 79.65rem !important;
}
.wrem-80-65 {
	width: 80.65rem !important;
}
.wrem-81-65 {
	width: 81.65rem !important;
}
.wrem-82-65 {
	width: 82.65rem !important;
}
.wrem-83-65 {
	width: 83.65rem !important;
}
.wrem-84-65 {
	width: 84.65rem !important;
}
.wrem-85-65 {
	width: 85.65rem !important;
}
.wrem-86-65 {
	width: 86.65rem !important;
}
.wrem-87-65 {
	width: 87.65rem !important;
}
.wrem-88-65 {
	width: 88.65rem !important;
}
.wrem-89-65 {
	width: 89.65rem !important;
}
.wrem-90-65 {
	width: 90.65rem !important;
}
.wrem-91-65 {
	width: 91.65rem !important;
}
.wrem-92-65 {
	width: 92.65rem !important;
}
.wrem-93-65 {
	width: 93.65rem !important;
}
.wrem-94-65 {
	width: 94.65rem !important;
}
.wrem-95-65 {
	width: 95.65rem !important;
}
.wrem-96-65 {
	width: 96.65rem !important;
}
.wrem-97-65 {
	width: 97.65rem !important;
}
.wrem-98-65 {
	width: 98.65rem !important;
}
.wrem-99-65 {
	width: 99.65rem !important;
}


.wrem-1-7 {
	width: 1.7rem !important;
}
.wrem-2-7 {
	width: 2.7rem !important;
}
.wrem-3-7 {
	width: 3.7rem !important;
}
.wrem-4-7 {
	width: 4.7rem !important;
}
.wrem-5-7 {
	width: 5.7rem !important;
}
.wrem-6-7 {
	width: 6.7rem !important;
}
.wrem-7-7 {
	width: 7.7rem !important;
}
.wrem-8-7 {
	width: 8.7rem !important;
}
.wrem-9-7 {
	width: 9.7rem !important;
}
.wrem-10-7 {
	width: 10.7rem !important;
}
.wrem-15-7 {
	width: 15.7rem !important;
}
.wrem-20-7 {
	width: 20.7rem !important;
}
.wrem-21-7 {
	width: 21.7rem !important;
}
.wrem-22-7 {
	width: 22.7rem !important;
}
.wrem-23-7 {
	width: 23.7rem !important;
}
.wrem-24-7 {
	width: 24.7rem !important;
}
.wrem-25-7 {
	width: 25.7rem !important;
}
.wrem-26-7 {
	width: 26.7rem !important;
}
.wrem-27-7 {
	width: 27.7rem !important;
}
.wrem-28-7 {
	width: 28.7rem !important;
}
.wrem-29-7 {
	width: 29.7rem !important;
}
.wrem-30-7 {
	width: 30.7rem !important;
}
.wrem-31-7 {
	width: 31.7rem !important;
}
.wrem-32-7 {
	width: 32.7rem !important;
}
.wrem-33-7 {
	width: 33.7rem !important;
}
.wrem-34-7 {
	width: 34.7rem !important;
}
.wrem-35-7 {
	width: 35.7rem !important;
}
.wrem-36-7 {
	width: 36.7rem !important;
}
.wrem-37-7 {
	width: 37.7rem !important;
}
.wrem-38-7 {
	width: 38.7rem !important;
}
.wrem-39-7 {
	width: 39.7rem !important;
}
.wrem-40-7 {
	width: 40.7rem !important;
}
.wrem-41-7 {
	width: 41.7rem !important;
}
.wrem-42-7 {
	width: 42.7rem !important;
}
.wrem-43-7 {
	width: 43.7rem !important;
}
.wrem-44-7 {
	width: 44.7rem !important;
}
.wrem-45-7 {
	width: 45.7rem !important;
}
.wrem-46-7 {
	width: 46.7rem !important;
}
.wrem-47-7 {
	width: 47.7rem !important;
}
.wrem-48-7 {
	width: 48.7rem !important;
}
.wrem-49-7 {
	width: 49.7rem !important;
}
.wrem-50-7 {
	width: 50.7rem !important;
}
.wrem-51-7 {
	width: 51.7rem !important;
}
.wrem-52-7 {
	width: 52.7rem !important;
}
.wrem-53-7 {
	width: 53.7rem !important;
}
.wrem-54-7 {
	width: 54.7rem !important;
}
.wrem-55-7 {
	width: 55.7rem !important;
}
.wrem-56-7 {
	width: 56.7rem !important;
}
.wrem-57-7 {
	width: 57.7rem !important;
}
.wrem-58-7 {
	width: 58.7rem !important;
}
.wrem-59-7 {
	width: 59.7rem !important;
}
.wrem-60-7 {
	width: 60.7rem !important;
}
.wrem-61-7 {
	width: 61.7rem !important;
}
.wrem-62-7 {
	width: 62.7rem !important;
}
.wrem-63-7 {
	width: 63.7rem !important;
}
.wrem-64-7 {
	width: 64.7rem !important;
}
.wrem-65-7 {
	width: 65.7rem !important;
}
.wrem-66-7 {
	width: 66.7rem !important;
}
.wrem-67-7 {
	width: 67.7rem !important;
}
.wrem-68-7 {
	width: 68.7rem !important;
}
.wrem-69-7 {
	width: 69.7rem !important;
}
.wrem-70-7 {
	width: 70.7rem !important;
}
.wrem-71-7 {
	width: 71.7rem !important;
}
.wrem-72-7 {
	width: 72.7rem !important;
}
.wrem-73-7 {
	width: 73.7rem !important;
}
.wrem-74-7 {
	width: 74.7rem !important;
}
.wrem-75-7 {
	width: 75.7rem !important;
}
.wrem-76-7 {
	width: 76.7rem !important;
}
.wrem-77-7 {
	width: 77.7rem !important;
}
.wrem-78-7 {
	width: 78.7rem !important;
}
.wrem-79-7 {
	width: 79.7rem !important;
}
.wrem-80-7 {
	width: 80.7rem !important;
}
.wrem-81-7 {
	width: 81.7rem !important;
}
.wrem-82-7 {
	width: 82.7rem !important;
}
.wrem-83-7 {
	width: 83.7rem !important;
}
.wrem-84-7 {
	width: 84.7rem !important;
}
.wrem-85-7 {
	width: 85.7rem !important;
}
.wrem-86-7 {
	width: 86.7rem !important;
}
.wrem-87-7 {
	width: 87.7rem !important;
}
.wrem-88-7 {
	width: 88.7rem !important;
}
.wrem-89-7 {
	width: 89.7rem !important;
}
.wrem-90-7 {
	width: 90.7rem !important;
}
.wrem-91-7 {
	width: 91.7rem !important;
}
.wrem-92-7 {
	width: 92.7rem !important;
}
.wrem-93-7 {
	width: 93.7rem !important;
}
.wrem-94-7 {
	width: 94.7rem !important;
}
.wrem-95-7 {
	width: 95.7rem !important;
}
.wrem-96-7 {
	width: 96.7rem !important;
}
.wrem-97-7 {
	width: 97.7rem !important;
}
.wrem-98-7 {
	width: 98.7rem !important;
}
.wrem-99-7 {
	width: 99.7rem !important;
}


.wrem-1-75 {
	width: 1.75rem !important;
}
.wrem-2-75 {
	width: 2.75rem !important;
}
.wrem-3-75 {
	width: 3.75rem !important;
}
.wrem-4-75 {
	width: 4.75rem !important;
}
.wrem-5-75 {
	width: 5.75rem !important;
}
.wrem-6-75 {
	width: 6.75rem !important;
}
.wrem-7-75 {
	width: 7.75rem !important;
}
.wrem-8-75 {
	width: 8.75rem !important;
}
.wrem-9-75 {
	width: 9.75rem !important;
}
.wrem-10-75 {
	width: 10.75rem !important;
}
.wrem-15-75 {
	width: 15.75rem !important;
}
.wrem-20-75 {
	width: 20.75rem !important;
}
.wrem-21-75 {
	width: 21.75rem !important;
}
.wrem-22-75 {
	width: 22.75rem !important;
}
.wrem-23-75 {
	width: 23.75rem !important;
}
.wrem-24-75 {
	width: 24.75rem !important;
}
.wrem-25-75 {
	width: 25.75rem !important;
}
.wrem-26-75 {
	width: 26.75rem !important;
}
.wrem-27-75 {
	width: 27.75rem !important;
}
.wrem-28-75 {
	width: 28.75rem !important;
}
.wrem-29-75 {
	width: 29.75rem !important;
}
.wrem-30-75 {
	width: 30.75rem !important;
}
.wrem-31-75 {
	width: 31.75rem !important;
}
.wrem-32-75 {
	width: 32.75rem !important;
}
.wrem-33-75 {
	width: 33.75rem !important;
}
.wrem-34-75 {
	width: 34.75rem !important;
}
.wrem-35-75 {
	width: 35.75rem !important;
}
.wrem-36-75 {
	width: 36.75rem !important;
}
.wrem-37-75 {
	width: 37.75rem !important;
}
.wrem-38-75 {
	width: 38.75rem !important;
}
.wrem-39-75 {
	width: 39.75rem !important;
}
.wrem-40-75 {
	width: 40.75rem !important;
}
.wrem-41-75 {
	width: 41.75rem !important;
}
.wrem-42-75 {
	width: 42.75rem !important;
}
.wrem-43-75 {
	width: 43.75rem !important;
}
.wrem-44-75 {
	width: 44.75rem !important;
}
.wrem-45-75 {
	width: 45.75rem !important;
}
.wrem-46-75 {
	width: 46.75rem !important;
}
.wrem-47-75 {
	width: 47.75rem !important;
}
.wrem-48-75 {
	width: 48.75rem !important;
}
.wrem-49-75 {
	width: 49.75rem !important;
}
.wrem-50-75 {
	width: 50.75rem !important;
}
.wrem-51-75 {
	width: 51.75rem !important;
}
.wrem-52-75 {
	width: 52.75rem !important;
}
.wrem-53-75 {
	width: 53.75rem !important;
}
.wrem-54-75 {
	width: 54.75rem !important;
}
.wrem-55-75 {
	width: 55.75rem !important;
}
.wrem-56-75 {
	width: 56.75rem !important;
}
.wrem-57-75 {
	width: 57.75rem !important;
}
.wrem-58-75 {
	width: 58.75rem !important;
}
.wrem-59-75 {
	width: 59.75rem !important;
}
.wrem-60-75 {
	width: 60.75rem !important;
}
.wrem-61-75 {
	width: 61.75rem !important;
}
.wrem-62-75 {
	width: 62.75rem !important;
}
.wrem-63-75 {
	width: 63.75rem !important;
}
.wrem-64-75 {
	width: 64.75rem !important;
}
.wrem-65-75 {
	width: 65.75rem !important;
}
.wrem-66-75 {
	width: 66.75rem !important;
}
.wrem-67-75 {
	width: 67.75rem !important;
}
.wrem-68-75 {
	width: 68.75rem !important;
}
.wrem-69-75 {
	width: 69.75rem !important;
}
.wrem-70-75 {
	width: 70.75rem !important;
}
.wrem-71-75 {
	width: 71.75rem !important;
}
.wrem-72-75 {
	width: 72.75rem !important;
}
.wrem-73-75 {
	width: 73.75rem !important;
}
.wrem-74-75 {
	width: 74.75rem !important;
}
.wrem-75-75 {
	width: 75.75rem !important;
}
.wrem-76-75 {
	width: 76.75rem !important;
}
.wrem-77-75 {
	width: 77.75rem !important;
}
.wrem-78-75 {
	width: 78.75rem !important;
}
.wrem-79-75 {
	width: 79.75rem !important;
}
.wrem-80-75 {
	width: 80.75rem !important;
}
.wrem-81-75 {
	width: 81.75rem !important;
}
.wrem-82-75 {
	width: 82.75rem !important;
}
.wrem-83-75 {
	width: 83.75rem !important;
}
.wrem-84-75 {
	width: 84.75rem !important;
}
.wrem-85-75 {
	width: 85.75rem !important;
}
.wrem-86-75 {
	width: 86.75rem !important;
}
.wrem-87-75 {
	width: 87.75rem !important;
}
.wrem-88-75 {
	width: 88.75rem !important;
}
.wrem-89-75 {
	width: 89.75rem !important;
}
.wrem-90-75 {
	width: 90.75rem !important;
}
.wrem-91-75 {
	width: 91.75rem !important;
}
.wrem-92-75 {
	width: 92.75rem !important;
}
.wrem-93-75 {
	width: 93.75rem !important;
}
.wrem-94-75 {
	width: 94.75rem !important;
}
.wrem-95-75 {
	width: 95.75rem !important;
}
.wrem-96-75 {
	width: 96.75rem !important;
}
.wrem-97-75 {
	width: 97.75rem !important;
}
.wrem-98-75 {
	width: 98.75rem !important;
}
.wrem-99-75 {
	width: 99.75rem !important;
}


.wrem-1-8 {
	width: 1.8rem !important;
}
.wrem-2-8 {
	width: 2.8rem !important;
}
.wrem-3-8 {
	width: 3.8rem !important;
}
.wrem-4-8 {
	width: 4.8rem !important;
}
.wrem-5-8 {
	width: 5.8rem !important;
}
.wrem-6-8 {
	width: 6.8rem !important;
}
.wrem-7-8 {
	width: 7.8rem !important;
}
.wrem-8-8 {
	width: 8.8rem !important;
}
.wrem-9-8 {
	width: 9.8rem !important;
}
.wrem-10-8 {
	width: 10.8rem !important;
}
.wrem-15-8 {
	width: 15.8rem !important;
}
.wrem-20-8 {
	width: 20.8rem !important;
}
.wrem-21-8 {
	width: 21.8rem !important;
}
.wrem-22-8 {
	width: 22.8rem !important;
}
.wrem-23-8 {
	width: 23.8rem !important;
}
.wrem-24-8 {
	width: 24.8rem !important;
}
.wrem-25-8 {
	width: 25.8rem !important;
}
.wrem-26-8 {
	width: 26.8rem !important;
}
.wrem-27-8 {
	width: 27.8rem !important;
}
.wrem-28-8 {
	width: 28.8rem !important;
}
.wrem-29-8 {
	width: 29.8rem !important;
}
.wrem-30-8 {
	width: 30.8rem !important;
}
.wrem-31-8 {
	width: 31.8rem !important;
}
.wrem-32-8 {
	width: 32.8rem !important;
}
.wrem-33-8 {
	width: 33.8rem !important;
}
.wrem-34-8 {
	width: 34.8rem !important;
}
.wrem-35-8 {
	width: 35.8rem !important;
}
.wrem-36-8 {
	width: 36.8rem !important;
}
.wrem-37-8 {
	width: 37.8rem !important;
}
.wrem-38-8 {
	width: 38.8rem !important;
}
.wrem-39-8 {
	width: 39.8rem !important;
}
.wrem-40-8 {
	width: 40.8rem !important;
}
.wrem-41-8 {
	width: 41.8rem !important;
}
.wrem-42-8 {
	width: 42.8rem !important;
}
.wrem-43-8 {
	width: 43.8rem !important;
}
.wrem-44-8 {
	width: 44.8rem !important;
}
.wrem-45-8 {
	width: 45.8rem !important;
}
.wrem-46-8 {
	width: 46.8rem !important;
}
.wrem-47-8 {
	width: 47.8rem !important;
}
.wrem-48-8 {
	width: 48.8rem !important;
}
.wrem-49-8 {
	width: 49.8rem !important;
}
.wrem-50-8 {
	width: 50.8rem !important;
}
.wrem-51-8 {
	width: 51.8rem !important;
}
.wrem-52-8 {
	width: 52.8rem !important;
}
.wrem-53-8 {
	width: 53.8rem !important;
}
.wrem-54-8 {
	width: 54.8rem !important;
}
.wrem-55-8 {
	width: 55.8rem !important;
}
.wrem-56-8 {
	width: 56.8rem !important;
}
.wrem-57-8 {
	width: 57.8rem !important;
}
.wrem-58-8 {
	width: 58.8rem !important;
}
.wrem-59-8 {
	width: 59.8rem !important;
}
.wrem-60-8 {
	width: 60.8rem !important;
}
.wrem-61-8 {
	width: 61.8rem !important;
}
.wrem-62-8 {
	width: 62.8rem !important;
}
.wrem-63-8 {
	width: 63.8rem !important;
}
.wrem-64-8 {
	width: 64.8rem !important;
}
.wrem-65-8 {
	width: 65.8rem !important;
}
.wrem-66-8 {
	width: 66.8rem !important;
}
.wrem-67-8 {
	width: 67.8rem !important;
}
.wrem-68-8 {
	width: 68.8rem !important;
}
.wrem-69-8 {
	width: 69.8rem !important;
}
.wrem-70-8 {
	width: 70.8rem !important;
}
.wrem-71-8 {
	width: 71.8rem !important;
}
.wrem-72-8 {
	width: 72.8rem !important;
}
.wrem-73-8 {
	width: 73.8rem !important;
}
.wrem-74-8 {
	width: 74.8rem !important;
}
.wrem-75-8 {
	width: 75.8rem !important;
}
.wrem-76-8 {
	width: 76.8rem !important;
}
.wrem-77-8 {
	width: 77.8rem !important;
}
.wrem-78-8 {
	width: 78.8rem !important;
}
.wrem-79-8 {
	width: 79.8rem !important;
}
.wrem-80-8 {
	width: 80.8rem !important;
}
.wrem-81-8 {
	width: 81.8rem !important;
}
.wrem-82-8 {
	width: 82.8rem !important;
}
.wrem-83-8 {
	width: 83.8rem !important;
}
.wrem-84-8 {
	width: 84.8rem !important;
}
.wrem-85-8 {
	width: 85.8rem !important;
}
.wrem-86-8 {
	width: 86.8rem !important;
}
.wrem-87-8 {
	width: 87.8rem !important;
}
.wrem-88-8 {
	width: 88.8rem !important;
}
.wrem-89-8 {
	width: 89.8rem !important;
}
.wrem-90-8 {
	width: 90.8rem !important;
}
.wrem-91-8 {
	width: 91.8rem !important;
}
.wrem-92-8 {
	width: 92.8rem !important;
}
.wrem-93-8 {
	width: 93.8rem !important;
}
.wrem-94-8 {
	width: 94.8rem !important;
}
.wrem-95-8 {
	width: 95.8rem !important;
}
.wrem-96-8 {
	width: 96.8rem !important;
}
.wrem-97-8 {
	width: 97.8rem !important;
}
.wrem-98-8 {
	width: 98.8rem !important;
}
.wrem-99-8 {
	width: 99.8rem !important;
}


.wrem-1-85 {
	width: 1.85rem !important;
}
.wrem-2-85 {
	width: 2.85rem !important;
}
.wrem-3-85 {
	width: 3.85rem !important;
}
.wrem-4-85 {
	width: 4.85rem !important;
}
.wrem-5-85 {
	width: 5.85rem !important;
}
.wrem-6-85 {
	width: 6.85rem !important;
}
.wrem-7-85 {
	width: 7.85rem !important;
}
.wrem-8-85 {
	width: 8.85rem !important;
}
.wrem-9-85 {
	width: 9.85rem !important;
}
.wrem-10-85 {
	width: 10.85rem !important;
}
.wrem-15-85 {
	width: 15.85rem !important;
}
.wrem-20-85 {
	width: 20.85rem !important;
}
.wrem-21-85 {
	width: 21.85rem !important;
}
.wrem-22-85 {
	width: 22.85rem !important;
}
.wrem-23-85 {
	width: 23.85rem !important;
}
.wrem-24-85 {
	width: 24.85rem !important;
}
.wrem-25-85 {
	width: 25.85rem !important;
}
.wrem-26-85 {
	width: 26.85rem !important;
}
.wrem-27-85 {
	width: 27.85rem !important;
}
.wrem-28-85 {
	width: 28.85rem !important;
}
.wrem-29-85 {
	width: 29.85rem !important;
}
.wrem-30-85 {
	width: 30.85rem !important;
}
.wrem-31-85 {
	width: 31.85rem !important;
}
.wrem-32-85 {
	width: 32.85rem !important;
}
.wrem-33-85 {
	width: 33.85rem !important;
}
.wrem-34-85 {
	width: 34.85rem !important;
}
.wrem-35-85 {
	width: 35.85rem !important;
}
.wrem-36-85 {
	width: 36.85rem !important;
}
.wrem-37-85 {
	width: 37.85rem !important;
}
.wrem-38-85 {
	width: 38.85rem !important;
}
.wrem-39-85 {
	width: 39.85rem !important;
}
.wrem-40-85 {
	width: 40.85rem !important;
}
.wrem-41-85 {
	width: 41.85rem !important;
}
.wrem-42-85 {
	width: 42.85rem !important;
}
.wrem-43-85 {
	width: 43.85rem !important;
}
.wrem-44-85 {
	width: 44.85rem !important;
}
.wrem-45-85 {
	width: 45.85rem !important;
}
.wrem-46-85 {
	width: 46.85rem !important;
}
.wrem-47-85 {
	width: 47.85rem !important;
}
.wrem-48-85 {
	width: 48.85rem !important;
}
.wrem-49-85 {
	width: 49.85rem !important;
}
.wrem-50-85 {
	width: 50.85rem !important;
}
.wrem-51-85 {
	width: 51.85rem !important;
}
.wrem-52-85 {
	width: 52.85rem !important;
}
.wrem-53-85 {
	width: 53.85rem !important;
}
.wrem-54-85 {
	width: 54.85rem !important;
}
.wrem-55-85 {
	width: 55.85rem !important;
}
.wrem-56-85 {
	width: 56.85rem !important;
}
.wrem-57-85 {
	width: 57.85rem !important;
}
.wrem-58-85 {
	width: 58.85rem !important;
}
.wrem-59-85 {
	width: 59.85rem !important;
}
.wrem-60-85 {
	width: 60.85rem !important;
}
.wrem-61-85 {
	width: 61.85rem !important;
}
.wrem-62-85 {
	width: 62.85rem !important;
}
.wrem-63-85 {
	width: 63.85rem !important;
}
.wrem-64-85 {
	width: 64.85rem !important;
}
.wrem-65-85 {
	width: 65.85rem !important;
}
.wrem-66-85 {
	width: 66.85rem !important;
}
.wrem-67-85 {
	width: 67.85rem !important;
}
.wrem-68-85 {
	width: 68.85rem !important;
}
.wrem-69-85 {
	width: 69.85rem !important;
}
.wrem-70-85 {
	width: 70.85rem !important;
}
.wrem-71-85 {
	width: 71.85rem !important;
}
.wrem-72-85 {
	width: 72.85rem !important;
}
.wrem-73-85 {
	width: 73.85rem !important;
}
.wrem-74-85 {
	width: 74.85rem !important;
}
.wrem-75-85 {
	width: 75.85rem !important;
}
.wrem-76-85 {
	width: 76.85rem !important;
}
.wrem-77-85 {
	width: 77.85rem !important;
}
.wrem-78-85 {
	width: 78.85rem !important;
}
.wrem-79-85 {
	width: 79.85rem !important;
}
.wrem-80-85 {
	width: 80.85rem !important;
}
.wrem-81-85 {
	width: 81.85rem !important;
}
.wrem-82-85 {
	width: 82.85rem !important;
}
.wrem-83-85 {
	width: 83.85rem !important;
}
.wrem-84-85 {
	width: 84.85rem !important;
}
.wrem-85-85 {
	width: 85.85rem !important;
}
.wrem-86-85 {
	width: 86.85rem !important;
}
.wrem-87-85 {
	width: 87.85rem !important;
}
.wrem-88-85 {
	width: 88.85rem !important;
}
.wrem-89-85 {
	width: 89.85rem !important;
}
.wrem-90-85 {
	width: 90.85rem !important;
}
.wrem-91-85 {
	width: 91.85rem !important;
}
.wrem-92-85 {
	width: 92.85rem !important;
}
.wrem-93-85 {
	width: 93.85rem !important;
}
.wrem-94-85 {
	width: 94.85rem !important;
}
.wrem-95-85 {
	width: 95.85rem !important;
}
.wrem-96-85 {
	width: 96.85rem !important;
}
.wrem-97-85 {
	width: 97.85rem !important;
}
.wrem-98-85 {
	width: 98.85rem !important;
}
.wrem-99-85 {
	width: 99.85rem !important;
}

.wrem-1-9 {
	width: 1.9rem !important;
}
.wrem-2-9 {
	width: 2.9rem !important;
}
.wrem-3-9 {
	width: 3.9rem !important;
}
.wrem-4-9 {
	width: 4.9rem !important;
}
.wrem-5-9 {
	width: 5.9rem !important;
}
.wrem-6-9 {
	width: 6.9rem !important;
}
.wrem-7-9 {
	width: 7.9rem !important;
}
.wrem-8-9 {
	width: 8.9rem !important;
}
.wrem-9-9 {
	width: 9.9rem !important;
}
.wrem-10-9 {
	width: 10.9rem !important;
}
.wrem-15-9 {
	width: 15.9rem !important;
}
.wrem-20-9 {
	width: 20.9rem !important;
}
.wrem-21-9 {
	width: 21.9rem !important;
}
.wrem-22-9 {
	width: 22.9rem !important;
}
.wrem-23-9 {
	width: 23.9rem !important;
}
.wrem-24-9 {
	width: 24.9rem !important;
}
.wrem-25-9 {
	width: 25.9rem !important;
}
.wrem-26-9 {
	width: 26.9rem !important;
}
.wrem-27-9 {
	width: 27.9rem !important;
}
.wrem-28-9 {
	width: 28.9rem !important;
}
.wrem-29-9 {
	width: 29.9rem !important;
}
.wrem-30-9 {
	width: 30.9rem !important;
}
.wrem-31-9 {
	width: 31.9rem !important;
}
.wrem-32-9 {
	width: 32.9rem !important;
}
.wrem-33-9 {
	width: 33.9rem !important;
}
.wrem-34-9 {
	width: 34.9rem !important;
}
.wrem-35-9 {
	width: 35.9rem !important;
}
.wrem-36-9 {
	width: 36.9rem !important;
}
.wrem-37-9 {
	width: 37.9rem !important;
}
.wrem-38-9 {
	width: 38.9rem !important;
}
.wrem-39-9 {
	width: 39.9rem !important;
}
.wrem-40-9 {
	width: 40.9rem !important;
}
.wrem-41-9 {
	width: 41.9rem !important;
}
.wrem-42-9 {
	width: 42.9rem !important;
}
.wrem-43-9 {
	width: 43.9rem !important;
}
.wrem-44-9 {
	width: 44.9rem !important;
}
.wrem-45-9 {
	width: 45.9rem !important;
}
.wrem-46-9 {
	width: 46.9rem !important;
}
.wrem-47-9 {
	width: 47.9rem !important;
}
.wrem-48-9 {
	width: 48.9rem !important;
}
.wrem-49-9 {
	width: 49.9rem !important;
}
.wrem-50-9 {
	width: 50.9rem !important;
}
.wrem-51-9 {
	width: 51.9rem !important;
}
.wrem-52-9 {
	width: 52.9rem !important;
}
.wrem-53-9 {
	width: 53.9rem !important;
}
.wrem-54-9 {
	width: 54.9rem !important;
}
.wrem-55-9 {
	width: 55.9rem !important;
}
.wrem-56-9 {
	width: 56.9rem !important;
}
.wrem-57-9 {
	width: 57.9rem !important;
}
.wrem-58-9 {
	width: 58.9rem !important;
}
.wrem-59-9 {
	width: 59.9rem !important;
}
.wrem-60-9 {
	width: 60.9rem !important;
}
.wrem-61-9 {
	width: 61.9rem !important;
}
.wrem-62-9 {
	width: 62.9rem !important;
}
.wrem-63-9 {
	width: 63.9rem !important;
}
.wrem-64-9 {
	width: 64.9rem !important;
}
.wrem-65-9 {
	width: 65.9rem !important;
}
.wrem-66-9 {
	width: 66.9rem !important;
}
.wrem-67-9 {
	width: 67.9rem !important;
}
.wrem-68-9 {
	width: 68.9rem !important;
}
.wrem-69-9 {
	width: 69.9rem !important;
}
.wrem-70-9 {
	width: 70.9rem !important;
}
.wrem-71-9 {
	width: 71.9rem !important;
}
.wrem-72-9 {
	width: 72.9rem !important;
}
.wrem-73-9 {
	width: 73.9rem !important;
}
.wrem-74-9 {
	width: 74.9rem !important;
}
.wrem-75-9 {
	width: 75.9rem !important;
}
.wrem-76-9 {
	width: 76.9rem !important;
}
.wrem-77-9 {
	width: 77.9rem !important;
}
.wrem-78-9 {
	width: 78.9rem !important;
}
.wrem-79-9 {
	width: 79.9rem !important;
}
.wrem-80-9 {
	width: 80.9rem !important;
}
.wrem-81-9 {
	width: 81.9rem !important;
}
.wrem-82-9 {
	width: 82.9rem !important;
}
.wrem-83-9 {
	width: 83.9rem !important;
}
.wrem-84-9 {
	width: 84.9rem !important;
}
.wrem-85-9 {
	width: 85.9rem !important;
}
.wrem-86-9 {
	width: 86.9rem !important;
}
.wrem-87-9 {
	width: 87.9rem !important;
}
.wrem-88-9 {
	width: 88.9rem !important;
}
.wrem-89-9 {
	width: 89.9rem !important;
}
.wrem-90-9 {
	width: 90.9rem !important;
}
.wrem-91-9 {
	width: 91.9rem !important;
}
.wrem-92-9 {
	width: 92.9rem !important;
}
.wrem-93-9 {
	width: 93.9rem !important;
}
.wrem-94-9 {
	width: 94.9rem !important;
}
.wrem-95-9 {
	width: 95.9rem !important;
}
.wrem-96-9 {
	width: 96.9rem !important;
}
.wrem-97-9 {
	width: 97.9rem !important;
}
.wrem-98-9 {
	width: 98.9rem !important;
}
.wrem-99-9 {
	width: 99.9rem !important;
}

.wrem-1-95 {
	width: 1.95rem !important;
}
.wrem-2-95 {
	width: 2.95rem !important;
}
.wrem-3-95 {
	width: 3.95rem !important;
}
.wrem-4-95 {
	width: 4.95rem !important;
}
.wrem-5-95 {
	width: 5.95rem !important;
}
.wrem-6-95 {
	width: 6.95rem !important;
}
.wrem-7-95 {
	width: 7.95rem !important;
}
.wrem-8-95 {
	width: 8.95rem !important;
}
.wrem-9-95 {
	width: 9.95rem !important;
}
.wrem-10-95 {
	width: 10.95rem !important;
}
.wrem-15-95 {
	width: 15.95rem !important;
}
.wrem-20-95 {
	width: 20.95rem !important;
}
.wrem-21-95 {
	width: 21.95rem !important;
}
.wrem-22-95 {
	width: 22.95rem !important;
}
.wrem-23-95 {
	width: 23.95rem !important;
}
.wrem-24-95 {
	width: 24.95rem !important;
}
.wrem-25-95 {
	width: 25.95rem !important;
}
.wrem-26-95 {
	width: 26.95rem !important;
}
.wrem-27-95 {
	width: 27.95rem !important;
}
.wrem-28-95 {
	width: 28.95rem !important;
}
.wrem-29-95 {
	width: 29.95rem !important;
}
.wrem-30-95 {
	width: 30.95rem !important;
}
.wrem-31-95 {
	width: 31.95rem !important;
}
.wrem-32-95 {
	width: 32.95rem !important;
}
.wrem-33-95 {
	width: 33.95rem !important;
}
.wrem-34-95 {
	width: 34.95rem !important;
}
.wrem-35-95 {
	width: 35.95rem !important;
}
.wrem-36-95 {
	width: 36.95rem !important;
}
.wrem-37-95 {
	width: 37.95rem !important;
}
.wrem-38-95 {
	width: 38.95rem !important;
}
.wrem-39-95 {
	width: 39.95rem !important;
}
.wrem-40-95 {
	width: 40.95rem !important;
}
.wrem-41-95 {
	width: 41.95rem !important;
}
.wrem-42-95 {
	width: 42.95rem !important;
}
.wrem-43-95 {
	width: 43.95rem !important;
}
.wrem-44-95 {
	width: 44.95rem !important;
}
.wrem-45-95 {
	width: 45.95rem !important;
}
.wrem-46-95 {
	width: 46.95rem !important;
}
.wrem-47-95 {
	width: 47.95rem !important;
}
.wrem-48-95 {
	width: 48.95rem !important;
}
.wrem-49-95 {
	width: 49.95rem !important;
}
.wrem-50-95 {
	width: 50.95rem !important;
}
.wrem-51-95 {
	width: 51.95rem !important;
}
.wrem-52-95 {
	width: 52.95rem !important;
}
.wrem-53-95 {
	width: 53.95rem !important;
}
.wrem-54-95 {
	width: 54.95rem !important;
}
.wrem-55-95 {
	width: 55.95rem !important;
}
.wrem-56-95 {
	width: 56.95rem !important;
}
.wrem-57-95 {
	width: 57.95rem !important;
}
.wrem-58-95 {
	width: 58.95rem !important;
}
.wrem-59-95 {
	width: 59.95rem !important;
}
.wrem-60-95 {
	width: 60.95rem !important;
}
.wrem-61-95 {
	width: 61.95rem !important;
}
.wrem-62-95 {
	width: 62.95rem !important;
}
.wrem-63-95 {
	width: 63.95rem !important;
}
.wrem-64-95 {
	width: 64.95rem !important;
}
.wrem-65-95 {
	width: 65.95rem !important;
}
.wrem-66-95 {
	width: 66.95rem !important;
}
.wrem-67-95 {
	width: 67.95rem !important;
}
.wrem-68-95 {
	width: 68.95rem !important;
}
.wrem-69-95 {
	width: 69.95rem !important;
}
.wrem-70-95 {
	width: 70.95rem !important;
}
.wrem-71-95 {
	width: 71.95rem !important;
}
.wrem-72-95 {
	width: 72.95rem !important;
}
.wrem-73-95 {
	width: 73.95rem !important;
}
.wrem-74-95 {
	width: 74.95rem !important;
}
.wrem-75-95 {
	width: 75.95rem !important;
}
.wrem-76-95 {
	width: 76.95rem !important;
}
.wrem-77-95 {
	width: 77.95rem !important;
}
.wrem-78-95 {
	width: 78.95rem !important;
}
.wrem-79-95 {
	width: 79.95rem !important;
}
.wrem-80-95 {
	width: 80.95rem !important;
}
.wrem-81-95 {
	width: 81.95rem !important;
}
.wrem-82-95 {
	width: 82.95rem !important;
}
.wrem-83-95 {
	width: 83.95rem !important;
}
.wrem-84-95 {
	width: 84.95rem !important;
}
.wrem-85-95 {
	width: 85.95rem !important;
}
.wrem-86-95 {
	width: 86.95rem !important;
}
.wrem-87-95 {
	width: 87.95rem !important;
}
.wrem-88-95 {
	width: 88.95rem !important;
}
.wrem-89-95 {
	width: 89.95rem !important;
}
.wrem-90-95 {
	width: 90.95rem !important;
}
.wrem-91-95 {
	width: 91.95rem !important;
}
.wrem-92-95 {
	width: 92.95rem !important;
}
.wrem-93-95 {
	width: 93.95rem !important;
}
.wrem-94-95 {
	width: 94.95rem !important;
}
.wrem-95-95 {
	width: 95.95rem !important;
}
.wrem-96-95 {
	width: 96.95rem !important;
}
.wrem-97-95 {
	width: 97.95rem !important;
}
.wrem-98-95 {
	width: 98.95rem !important;
}
.wrem-99-95 {
	width: 99.95rem !important;
}



@media (min-width: 480px) {
	.wd-xs-5 {
	  width: 5px;
	}
  
	.wd-xs-5p {
	  width: 5%;
	}
  
	.mx-wd-xs-5p {
	  max-width: 5%;
	}
  
	.mn-wd-xs-5p {
	  min-width: 5%;
	}
  
	.wd-xs-5-f {
	  width: 5px !important;
	}
  
	.wd-xs-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-xs-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-xs-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-xs-10 {
	  width: 10px;
	}
  
	.wd-xs-10p {
	  width: 10%;
	}
  
	.mx-wd-xs-10p {
	  max-width: 10%;
	}
  
	.mn-wd-xs-10p {
	  min-width: 10%;
	}
  
	.wd-xs-10-f {
	  width: 10px !important;
	}
  
	.wd-xs-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-xs-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-xs-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-xs-15 {
	  width: 15px;
	}
  
	.wd-xs-15p {
	  width: 15%;
	}
  
	.mx-wd-xs-15p {
	  max-width: 15%;
	}
  
	.mn-wd-xs-15p {
	  min-width: 15%;
	}
  
	.wd-xs-15-f {
	  width: 15px !important;
	}
  
	.wd-xs-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-xs-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-xs-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-xs-20 {
	  width: 20px;
	}
  
	.wd-xs-20p {
	  width: 20%;
	}
  
	.mx-wd-xs-20p {
	  max-width: 20%;
	}
  
	.mn-wd-xs-20p {
	  min-width: 20%;
	}
  
	.wd-xs-20-f {
	  width: 20px !important;
	}
  
	.wd-xs-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-xs-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-xs-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-xs-25 {
	  width: 25px;
	}
  
	.wd-xs-25p {
	  width: 25%;
	}
  
	.mx-wd-xs-25p {
	  max-width: 25%;
	}
  
	.mn-wd-xs-25p {
	  min-width: 25%;
	}
  
	.wd-xs-25-f {
	  width: 25px !important;
	}
  
	.wd-xs-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-xs-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-xs-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-xs-30 {
	  width: 30px;
	}
  
	.wd-xs-30p {
	  width: 30%;
	}
  
	.mx-wd-xs-30p {
	  max-width: 30%;
	}
  
	.mn-wd-xs-30p {
	  min-width: 30%;
	}
  
	.wd-xs-30-f {
	  width: 30px !important;
	}
  
	.wd-xs-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-xs-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-xs-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-xs-35 {
	  width: 35px;
	}
  
	.wd-xs-35p {
	  width: 35%;
	}
  
	.mx-wd-xs-35p {
	  max-width: 35%;
	}
  
	.mn-wd-xs-35p {
	  min-width: 35%;
	}
  
	.wd-xs-35-f {
	  width: 35px !important;
	}
  
	.wd-xs-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-xs-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-xs-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-xs-40 {
	  width: 40px;
	}
  
	.wd-xs-40p {
	  width: 40%;
	}
  
	.mx-wd-xs-40p {
	  max-width: 40%;
	}
  
	.mn-wd-xs-40p {
	  min-width: 40%;
	}
  
	.wd-xs-40-f {
	  width: 40px !important;
	}
  
	.wd-xs-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-xs-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-xs-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-xs-45 {
	  width: 45px;
	}
  
	.wd-xs-45p {
	  width: 45%;
	}
  
	.mx-wd-xs-45p {
	  max-width: 45%;
	}
  
	.mn-wd-xs-45p {
	  min-width: 45%;
	}
  
	.wd-xs-45-f {
	  width: 45px !important;
	}
  
	.wd-xs-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-xs-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-xs-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-xs-50 {
	  width: 50px;
	}
  
	.wd-xs-50p {
	  width: 50%;
	}
  
	.mx-wd-xs-50p {
	  max-width: 50%;
	}
  
	.mn-wd-xs-50p {
	  min-width: 50%;
	}
  
	.wd-xs-50-f {
	  width: 50px !important;
	}
  
	.wd-xs-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-xs-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-xs-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-xs-55 {
	  width: 55px;
	}
  
	.wd-xs-55p {
	  width: 55%;
	}
  
	.mx-wd-xs-55p {
	  max-width: 55%;
	}
  
	.mn-wd-xs-55p {
	  min-width: 55%;
	}
  
	.wd-xs-55-f {
	  width: 55px !important;
	}
  
	.wd-xs-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-xs-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-xs-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-xs-60 {
	  width: 60px;
	}
  
	.wd-xs-60p {
	  width: 60%;
	}
  
	.mx-wd-xs-60p {
	  max-width: 60%;
	}
  
	.mn-wd-xs-60p {
	  min-width: 60%;
	}
  
	.wd-xs-60-f {
	  width: 60px !important;
	}
  
	.wd-xs-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-xs-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-xs-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-xs-65 {
	  width: 65px;
	}
  
	.wd-xs-65p {
	  width: 65%;
	}
  
	.mx-wd-xs-65p {
	  max-width: 65%;
	}
  
	.mn-wd-xs-65p {
	  min-width: 65%;
	}
  
	.wd-xs-65-f {
	  width: 65px !important;
	}
  
	.wd-xs-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-xs-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-xs-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-xs-70 {
	  width: 70px;
	}
  
	.wd-xs-70p {
	  width: 70%;
	}
  
	.mx-wd-xs-70p {
	  max-width: 70%;
	}
  
	.mn-wd-xs-70p {
	  min-width: 70%;
	}
  
	.wd-xs-70-f {
	  width: 70px !important;
	}
  
	.wd-xs-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-xs-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-xs-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-xs-75 {
	  width: 75px;
	}
  
	.wd-xs-75p {
	  width: 75%;
	}
  
	.mx-wd-xs-75p {
	  max-width: 75%;
	}
  
	.mn-wd-xs-75p {
	  min-width: 75%;
	}
  
	.wd-xs-75-f {
	  width: 75px !important;
	}
  
	.wd-xs-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-xs-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-xs-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-xs-80 {
	  width: 80px;
	}
  
	.wd-xs-80p {
	  width: 80%;
	}
  
	.mx-wd-xs-80p {
	  max-width: 80%;
	}
  
	.mn-wd-xs-80p {
	  min-width: 80%;
	}
  
	.wd-xs-80-f {
	  width: 80px !important;
	}
  
	.wd-xs-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-xs-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-xs-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-xs-85 {
	  width: 85px;
	}
  
	.wd-xs-85p {
	  width: 85%;
	}
  
	.mx-wd-xs-85p {
	  max-width: 85%;
	}
  
	.mn-wd-xs-85p {
	  min-width: 85%;
	}
  
	.wd-xs-85-f {
	  width: 85px !important;
	}
  
	.wd-xs-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-xs-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-xs-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-xs-90 {
	  width: 90px;
	}
  
	.wd-xs-90p {
	  width: 90%;
	}
  
	.mx-wd-xs-90p {
	  max-width: 90%;
	}
  
	.mn-wd-xs-90p {
	  min-width: 90%;
	}
  
	.wd-xs-90-f {
	  width: 90px !important;
	}
  
	.wd-xs-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-xs-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-xs-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-xs-95 {
	  width: 95px;
	}
  
	.wd-xs-95p {
	  width: 95%;
	}
  
	.mx-wd-xs-95p {
	  max-width: 95%;
	}
  
	.mn-wd-xs-95p {
	  min-width: 95%;
	}
  
	.wd-xs-95-f {
	  width: 95px !important;
	}
  
	.wd-xs-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-xs-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-xs-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-xs-100 {
	  width: 100px;
	}
  
	.wd-xs-100p {
	  width: 100%;
	}
  
	.mx-wd-xs-100p {
	  max-width: 100%;
	}
  
	.mn-wd-xs-100p {
	  min-width: 100%;
	}
  
	.wd-xs-100-f {
	  width: 100px !important;
	}
  
	.wd-xs-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-xs-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-xs-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-xs-150 {
	  width: 150px;
	}
  
	.wd-xs-150p {
	  width: 150%;
	}
  
	.mx-wd-xs-150p {
	  max-width: 150%;
	}
  
	.mn-wd-xs-150p {
	  min-width: 150%;
	}
  
	.wd-xs-150-f {
	  width: 150px !important;
	}
  
	.wd-xs-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-xs-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-xs-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-xs-200 {
	  width: 200px;
	}
  
	.wd-xs-200p {
	  width: 200%;
	}
  
	.mx-wd-xs-200p {
	  max-width: 200%;
	}
  
	.mn-wd-xs-200p {
	  min-width: 200%;
	}
  
	.wd-xs-200-f {
	  width: 200px !important;
	}
  
	.wd-xs-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-xs-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-xs-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-xs-250 {
	  width: 250px;
	}
  
	.wd-xs-250p {
	  width: 250%;
	}
  
	.mx-wd-xs-250p {
	  max-width: 250%;
	}
  
	.mn-wd-xs-250p {
	  min-width: 250%;
	}
  
	.wd-xs-250-f {
	  width: 250px !important;
	}
  
	.wd-xs-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-xs-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-xs-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-xs-300 {
	  width: 300px;
	}
  
	.wd-xs-300p {
	  width: 300%;
	}
  
	.mx-wd-xs-300p {
	  max-width: 300%;
	}
  
	.mn-wd-xs-300p {
	  min-width: 300%;
	}
  
	.wd-xs-300-f {
	  width: 300px !important;
	}
  
	.wd-xs-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-xs-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-xs-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-xs-350 {
	  width: 350px;
	}
  
	.wd-xs-350p {
	  width: 350%;
	}
  
	.mx-wd-xs-350p {
	  max-width: 350%;
	}
  
	.mn-wd-xs-350p {
	  min-width: 350%;
	}
  
	.wd-xs-350-f {
	  width: 350px !important;
	}
  
	.wd-xs-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-xs-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-xs-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-xs-400 {
	  width: 400px;
	}
  
	.wd-xs-400p {
	  width: 400%;
	}
  
	.mx-wd-xs-400p {
	  max-width: 400%;
	}
  
	.mn-wd-xs-400p {
	  min-width: 400%;
	}
  
	.wd-xs-400-f {
	  width: 400px !important;
	}
  
	.wd-xs-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-xs-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-xs-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-xs-450 {
	  width: 450px;
	}
  
	.wd-xs-450p {
	  width: 450%;
	}
  
	.mx-wd-xs-450p {
	  max-width: 450%;
	}
  
	.mn-wd-xs-450p {
	  min-width: 450%;
	}
  
	.wd-xs-450-f {
	  width: 450px !important;
	}
  
	.wd-xs-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-xs-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-xs-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-xs-500 {
	  width: 500px;
	}
  
	.wd-xs-500p {
	  width: 500%;
	}
  
	.mx-wd-xs-500p {
	  max-width: 500%;
	}
  
	.mn-wd-xs-500p {
	  min-width: 500%;
	}
  
	.wd-xs-500-f {
	  width: 500px !important;
	}
  
	.wd-xs-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-xs-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-xs-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-xs-550 {
	  width: 550px;
	}
  
	.wd-xs-550p {
	  width: 550%;
	}
  
	.mx-wd-xs-550p {
	  max-width: 550%;
	}
  
	.mn-wd-xs-550p {
	  min-width: 550%;
	}
  
	.wd-xs-550-f {
	  width: 550px !important;
	}
  
	.wd-xs-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-xs-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-xs-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-xs-600 {
	  width: 600px;
	}
  
	.wd-xs-600p {
	  width: 600%;
	}
  
	.mx-wd-xs-600p {
	  max-width: 600%;
	}
  
	.mn-wd-xs-600p {
	  min-width: 600%;
	}
  
	.wd-xs-600-f {
	  width: 600px !important;
	}
  
	.wd-xs-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-xs-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-xs-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-xs-650 {
	  width: 650px;
	}
  
	.wd-xs-650p {
	  width: 650%;
	}
  
	.mx-wd-xs-650p {
	  max-width: 650%;
	}
  
	.mn-wd-xs-650p {
	  min-width: 650%;
	}
  
	.wd-xs-650-f {
	  width: 650px !important;
	}
  
	.wd-xs-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-xs-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-xs-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-xs-700 {
	  width: 700px;
	}
  
	.wd-xs-700p {
	  width: 700%;
	}
  
	.mx-wd-xs-700p {
	  max-width: 700%;
	}
  
	.mn-wd-xs-700p {
	  min-width: 700%;
	}
  
	.wd-xs-700-f {
	  width: 700px !important;
	}
  
	.wd-xs-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-xs-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-xs-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-xs-750 {
	  width: 750px;
	}
  
	.wd-xs-750p {
	  width: 750%;
	}
  
	.mx-wd-xs-750p {
	  max-width: 750%;
	}
  
	.mn-wd-xs-750p {
	  min-width: 750%;
	}
  
	.wd-xs-750-f {
	  width: 750px !important;
	}
  
	.wd-xs-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-xs-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-xs-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-xs-800 {
	  width: 800px;
	}
  
	.wd-xs-800p {
	  width: 800%;
	}
  
	.mx-wd-xs-800p {
	  max-width: 800%;
	}
  
	.mn-wd-xs-800p {
	  min-width: 800%;
	}
  
	.wd-xs-800-f {
	  width: 800px !important;
	}
  
	.wd-xs-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-xs-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-xs-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-xs-850 {
	  width: 850px;
	}
  
	.wd-xs-850p {
	  width: 850%;
	}
  
	.mx-wd-xs-850p {
	  max-width: 850%;
	}
  
	.mn-wd-xs-850p {
	  min-width: 850%;
	}
  
	.wd-xs-850-f {
	  width: 850px !important;
	}
  
	.wd-xs-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-xs-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-xs-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-xs-900 {
	  width: 900px;
	}
  
	.wd-xs-900p {
	  width: 900%;
	}
  
	.mx-wd-xs-900p {
	  max-width: 900%;
	}
  
	.mn-wd-xs-900p {
	  min-width: 900%;
	}
  
	.wd-xs-900-f {
	  width: 900px !important;
	}
  
	.wd-xs-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-xs-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-xs-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-xs-950 {
	  width: 950px;
	}
  
	.wd-xs-950p {
	  width: 950%;
	}
  
	.mx-wd-xs-950p {
	  max-width: 950%;
	}
  
	.mn-wd-xs-950p {
	  min-width: 950%;
	}
  
	.wd-xs-950-f {
	  width: 950px !important;
	}
  
	.wd-xs-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-xs-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-xs-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-xs-1000 {
	  width: 1000px;
	}
  
	.wd-xs-1000p {
	  width: 1000%;
	}
  
	.mx-wd-xs-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-xs-1000p {
	  min-width: 1000%;
	}
  
	.wd-xs-1000-f {
	  width: 1000px !important;
	}
  
	.wd-xs-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-xs-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-xs-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-xs-auto {
	  width: auto;
	}
  
	.wd-xs-auto-f {
	  width: auto !important;
	}
  }
  
  @media (min-width: 576px) {
	.wd-sm-5 {
	  width: 5px;
	}
  
	.wd-sm-5p {
	  width: 5%;
	}
  
	.mx-wd-sm-5p {
	  max-width: 5%;
	}
  
	.mn-wd-sm-5p {
	  min-width: 5%;
	}
  
	.wd-sm-5-f {
	  width: 5px !important;
	}
  
	.wd-sm-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-sm-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-sm-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-sm-10 {
	  width: 10px;
	}
  
	.wd-sm-10p {
	  width: 10%;
	}
  
	.mx-wd-sm-10p {
	  max-width: 10%;
	}
  
	.mn-wd-sm-10p {
	  min-width: 10%;
	}
  
	.wd-sm-10-f {
	  width: 10px !important;
	}
  
	.wd-sm-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-sm-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-sm-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-sm-15 {
	  width: 15px;
	}
  
	.wd-sm-15p {
	  width: 15%;
	}
  
	.mx-wd-sm-15p {
	  max-width: 15%;
	}
  
	.mn-wd-sm-15p {
	  min-width: 15%;
	}
  
	.wd-sm-15-f {
	  width: 15px !important;
	}
  
	.wd-sm-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-sm-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-sm-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-sm-20 {
	  width: 20px;
	}
  
	.wd-sm-20p {
	  width: 20%;
	}
  
	.mx-wd-sm-20p {
	  max-width: 20%;
	}
  
	.mn-wd-sm-20p {
	  min-width: 20%;
	}
  
	.wd-sm-20-f {
	  width: 20px !important;
	}
  
	.wd-sm-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-sm-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-sm-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-sm-25 {
	  width: 25px;
	}
  
	.wd-sm-25p {
	  width: 25%;
	}
  
	.mx-wd-sm-25p {
	  max-width: 25%;
	}
  
	.mn-wd-sm-25p {
	  min-width: 25%;
	}
  
	.wd-sm-25-f {
	  width: 25px !important;
	}
  
	.wd-sm-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-sm-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-sm-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-sm-30 {
	  width: 30px;
	}
  
	.wd-sm-30p {
	  width: 30%;
	}
  
	.mx-wd-sm-30p {
	  max-width: 30%;
	}
  
	.mn-wd-sm-30p {
	  min-width: 30%;
	}
  
	.wd-sm-30-f {
	  width: 30px !important;
	}
  
	.wd-sm-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-sm-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-sm-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-sm-35 {
	  width: 35px;
	}
  
	.wd-sm-35p {
	  width: 35%;
	}
  
	.mx-wd-sm-35p {
	  max-width: 35%;
	}
  
	.mn-wd-sm-35p {
	  min-width: 35%;
	}
  
	.wd-sm-35-f {
	  width: 35px !important;
	}
  
	.wd-sm-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-sm-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-sm-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-sm-40 {
	  width: 40px;
	}
  
	.wd-sm-40p {
	  width: 40%;
	}
  
	.mx-wd-sm-40p {
	  max-width: 40%;
	}
  
	.mn-wd-sm-40p {
	  min-width: 40%;
	}
  
	.wd-sm-40-f {
	  width: 40px !important;
	}
  
	.wd-sm-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-sm-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-sm-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-sm-45 {
	  width: 45px;
	}
  
	.wd-sm-45p {
	  width: 45%;
	}
  
	.mx-wd-sm-45p {
	  max-width: 45%;
	}
  
	.mn-wd-sm-45p {
	  min-width: 45%;
	}
  
	.wd-sm-45-f {
	  width: 45px !important;
	}
  
	.wd-sm-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-sm-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-sm-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-sm-50 {
	  width: 50px;
	}
  
	.wd-sm-50p {
	  width: 50%;
	}
  
	.mx-wd-sm-50p {
	  max-width: 50%;
	}
  
	.mn-wd-sm-50p {
	  min-width: 50%;
	}
  
	.wd-sm-50-f {
	  width: 50px !important;
	}
  
	.wd-sm-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-sm-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-sm-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-sm-55 {
	  width: 55px;
	}
  
	.wd-sm-55p {
	  width: 55%;
	}
  
	.mx-wd-sm-55p {
	  max-width: 55%;
	}
  
	.mn-wd-sm-55p {
	  min-width: 55%;
	}
  
	.wd-sm-55-f {
	  width: 55px !important;
	}
  
	.wd-sm-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-sm-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-sm-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-sm-60 {
	  width: 60px;
	}
  
	.wd-sm-60p {
	  width: 60%;
	}
  
	.mx-wd-sm-60p {
	  max-width: 60%;
	}
  
	.mn-wd-sm-60p {
	  min-width: 60%;
	}
  
	.wd-sm-60-f {
	  width: 60px !important;
	}
  
	.wd-sm-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-sm-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-sm-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-sm-65 {
	  width: 65px;
	}
  
	.wd-sm-65p {
	  width: 65%;
	}
  
	.mx-wd-sm-65p {
	  max-width: 65%;
	}
  
	.mn-wd-sm-65p {
	  min-width: 65%;
	}
  
	.wd-sm-65-f {
	  width: 65px !important;
	}
  
	.wd-sm-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-sm-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-sm-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-sm-70 {
	  width: 70px;
	}
  
	.wd-sm-70p {
	  width: 70%;
	}
  
	.mx-wd-sm-70p {
	  max-width: 70%;
	}
  
	.mn-wd-sm-70p {
	  min-width: 70%;
	}
  
	.wd-sm-70-f {
	  width: 70px !important;
	}
  
	.wd-sm-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-sm-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-sm-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-sm-75 {
	  width: 75px;
	}
  
	.wd-sm-75p {
	  width: 75%;
	}
  
	.mx-wd-sm-75p {
	  max-width: 75%;
	}
  
	.mn-wd-sm-75p {
	  min-width: 75%;
	}
  
	.wd-sm-75-f {
	  width: 75px !important;
	}
  
	.wd-sm-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-sm-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-sm-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-sm-80 {
	  width: 80px;
	}
  
	.wd-sm-80p {
	  width: 80%;
	}
  
	.mx-wd-sm-80p {
	  max-width: 80%;
	}
  
	.mn-wd-sm-80p {
	  min-width: 80%;
	}
  
	.wd-sm-80-f {
	  width: 80px !important;
	}
  
	.wd-sm-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-sm-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-sm-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-sm-85 {
	  width: 85px;
	}
  
	.wd-sm-85p {
	  width: 85%;
	}
  
	.mx-wd-sm-85p {
	  max-width: 85%;
	}
  
	.mn-wd-sm-85p {
	  min-width: 85%;
	}
  
	.wd-sm-85-f {
	  width: 85px !important;
	}
  
	.wd-sm-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-sm-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-sm-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-sm-90 {
	  width: 90px;
	}
  
	.wd-sm-90p {
	  width: 90%;
	}
  
	.mx-wd-sm-90p {
	  max-width: 90%;
	}
  
	.mn-wd-sm-90p {
	  min-width: 90%;
	}
  
	.wd-sm-90-f {
	  width: 90px !important;
	}
  
	.wd-sm-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-sm-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-sm-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-sm-95 {
	  width: 95px;
	}
  
	.wd-sm-95p {
	  width: 95%;
	}
  
	.mx-wd-sm-95p {
	  max-width: 95%;
	}
  
	.mn-wd-sm-95p {
	  min-width: 95%;
	}
  
	.wd-sm-95-f {
	  width: 95px !important;
	}
  
	.wd-sm-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-sm-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-sm-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-sm-100 {
	  width: 100px;
	}
  
	.wd-sm-100p {
	  width: 100%;
	}
  
	.mx-wd-sm-100p {
	  max-width: 100%;
	}
  
	.mn-wd-sm-100p {
	  min-width: 100%;
	}
  
	.wd-sm-100-f {
	  width: 100px !important;
	}
  
	.wd-sm-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-sm-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-sm-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-sm-150 {
	  width: 150px;
	}
  
	.wd-sm-150p {
	  width: 150%;
	}
  
	.mx-wd-sm-150p {
	  max-width: 150%;
	}
  
	.mn-wd-sm-150p {
	  min-width: 150%;
	}
  
	.wd-sm-150-f {
	  width: 150px !important;
	}
  
	.wd-sm-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-sm-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-sm-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-sm-200 {
	  width: 200px;
	}
  
	.wd-sm-200p {
	  width: 200%;
	}
  
	.mx-wd-sm-200p {
	  max-width: 200%;
	}
  
	.mn-wd-sm-200p {
	  min-width: 200%;
	}
  
	.wd-sm-200-f {
	  width: 200px !important;
	}
  
	.wd-sm-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-sm-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-sm-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-sm-250 {
	  width: 250px;
	}
  
	.wd-sm-250p {
	  width: 250%;
	}
  
	.mx-wd-sm-250p {
	  max-width: 250%;
	}
  
	.mn-wd-sm-250p {
	  min-width: 250%;
	}
  
	.wd-sm-250-f {
	  width: 250px !important;
	}
  
	.wd-sm-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-sm-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-sm-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-sm-300 {
	  width: 300px;
	}
  
	.wd-sm-300p {
	  width: 300%;
	}
  
	.mx-wd-sm-300p {
	  max-width: 300%;
	}
  
	.mn-wd-sm-300p {
	  min-width: 300%;
	}
  
	.wd-sm-300-f {
	  width: 300px !important;
	}
  
	.wd-sm-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-sm-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-sm-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-sm-350 {
	  width: 350px;
	}
  
	.wd-sm-350p {
	  width: 350%;
	}
  
	.mx-wd-sm-350p {
	  max-width: 350%;
	}
  
	.mn-wd-sm-350p {
	  min-width: 350%;
	}
  
	.wd-sm-350-f {
	  width: 350px !important;
	}
  
	.wd-sm-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-sm-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-sm-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-sm-400 {
	  width: 400px;
	}
  
	.wd-sm-400p {
	  width: 400%;
	}
  
	.mx-wd-sm-400p {
	  max-width: 400%;
	}
  
	.mn-wd-sm-400p {
	  min-width: 400%;
	}
  
	.wd-sm-400-f {
	  width: 400px !important;
	}
  
	.wd-sm-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-sm-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-sm-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-sm-450 {
	  width: 450px;
	}
  
	.wd-sm-450p {
	  width: 450%;
	}
  
	.mx-wd-sm-450p {
	  max-width: 450%;
	}
  
	.mn-wd-sm-450p {
	  min-width: 450%;
	}
  
	.wd-sm-450-f {
	  width: 450px !important;
	}
  
	.wd-sm-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-sm-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-sm-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-sm-500 {
	  width: 500px;
	}
  
	.wd-sm-500p {
	  width: 500%;
	}
  
	.mx-wd-sm-500p {
	  max-width: 500%;
	}
  
	.mn-wd-sm-500p {
	  min-width: 500%;
	}
  
	.wd-sm-500-f {
	  width: 500px !important;
	}
  
	.wd-sm-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-sm-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-sm-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-sm-550 {
	  width: 550px;
	}
  
	.wd-sm-550p {
	  width: 550%;
	}
  
	.mx-wd-sm-550p {
	  max-width: 550%;
	}
  
	.mn-wd-sm-550p {
	  min-width: 550%;
	}
  
	.wd-sm-550-f {
	  width: 550px !important;
	}
  
	.wd-sm-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-sm-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-sm-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-sm-600 {
	  width: 600px;
	}
  
	.wd-sm-600p {
	  width: 600%;
	}
  
	.mx-wd-sm-600p {
	  max-width: 600%;
	}
  
	.mn-wd-sm-600p {
	  min-width: 600%;
	}
  
	.wd-sm-600-f {
	  width: 600px !important;
	}
  
	.wd-sm-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-sm-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-sm-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-sm-650 {
	  width: 650px;
	}
  
	.wd-sm-650p {
	  width: 650%;
	}
  
	.mx-wd-sm-650p {
	  max-width: 650%;
	}
  
	.mn-wd-sm-650p {
	  min-width: 650%;
	}
  
	.wd-sm-650-f {
	  width: 650px !important;
	}
  
	.wd-sm-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-sm-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-sm-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-sm-700 {
	  width: 700px;
	}
  
	.wd-sm-700p {
	  width: 700%;
	}
  
	.mx-wd-sm-700p {
	  max-width: 700%;
	}
  
	.mn-wd-sm-700p {
	  min-width: 700%;
	}
  
	.wd-sm-700-f {
	  width: 700px !important;
	}
  
	.wd-sm-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-sm-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-sm-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-sm-750 {
	  width: 750px;
	}
  
	.wd-sm-750p {
	  width: 750%;
	}
  
	.mx-wd-sm-750p {
	  max-width: 750%;
	}
  
	.mn-wd-sm-750p {
	  min-width: 750%;
	}
  
	.wd-sm-750-f {
	  width: 750px !important;
	}
  
	.wd-sm-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-sm-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-sm-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-sm-800 {
	  width: 800px;
	}
  
	.wd-sm-800p {
	  width: 800%;
	}
  
	.mx-wd-sm-800p {
	  max-width: 800%;
	}
  
	.mn-wd-sm-800p {
	  min-width: 800%;
	}
  
	.wd-sm-800-f {
	  width: 800px !important;
	}
  
	.wd-sm-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-sm-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-sm-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-sm-850 {
	  width: 850px;
	}
  
	.wd-sm-850p {
	  width: 850%;
	}
  
	.mx-wd-sm-850p {
	  max-width: 850%;
	}
  
	.mn-wd-sm-850p {
	  min-width: 850%;
	}
  
	.wd-sm-850-f {
	  width: 850px !important;
	}
  
	.wd-sm-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-sm-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-sm-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-sm-900 {
	  width: 900px;
	}
  
	.wd-sm-900p {
	  width: 900%;
	}
  
	.mx-wd-sm-900p {
	  max-width: 900%;
	}
  
	.mn-wd-sm-900p {
	  min-width: 900%;
	}
  
	.wd-sm-900-f {
	  width: 900px !important;
	}
  
	.wd-sm-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-sm-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-sm-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-sm-950 {
	  width: 950px;
	}
  
	.wd-sm-950p {
	  width: 950%;
	}
  
	.mx-wd-sm-950p {
	  max-width: 950%;
	}
  
	.mn-wd-sm-950p {
	  min-width: 950%;
	}
  
	.wd-sm-950-f {
	  width: 950px !important;
	}
  
	.wd-sm-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-sm-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-sm-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-sm-1000 {
	  width: 1000px;
	}
  
	.wd-sm-1000p {
	  width: 1000%;
	}
  
	.mx-wd-sm-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-sm-1000p {
	  min-width: 1000%;
	}
  
	.wd-sm-1000-f {
	  width: 1000px !important;
	}
  
	.wd-sm-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-sm-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-sm-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-sm-auto {
	  width: auto;
	}
  
	.wd-sm-auto-f {
	  width: auto !important;
	}
  }
  
  @media (min-width: 768px) {
	.wd-md-5 {
	  width: 5px;
	}
  
	.wd-md-5p {
	  width: 5%;
	}
  
	.mx-wd-md-5p {
	  max-width: 5%;
	}
  
	.mn-wd-md-5p {
	  min-width: 5%;
	}
  
	.wd-md-5-f {
	  width: 5px !important;
	}
  
	.wd-md-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-md-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-md-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-md-10 {
	  width: 10px;
	}
  
	.wd-md-10p {
	  width: 10%;
	}
  
	.mx-wd-md-10p {
	  max-width: 10%;
	}
  
	.mn-wd-md-10p {
	  min-width: 10%;
	}
  
	.wd-md-10-f {
	  width: 10px !important;
	}
  
	.wd-md-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-md-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-md-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-md-15 {
	  width: 15px;
	}
  
	.wd-md-15p {
	  width: 15%;
	}
  
	.mx-wd-md-15p {
	  max-width: 15%;
	}
  
	.mn-wd-md-15p {
	  min-width: 15%;
	}
  
	.wd-md-15-f {
	  width: 15px !important;
	}
  
	.wd-md-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-md-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-md-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-md-20 {
	  width: 20px;
	}
  
	.wd-md-20p {
	  width: 20%;
	}
  
	.mx-wd-md-20p {
	  max-width: 20%;
	}
  
	.mn-wd-md-20p {
	  min-width: 20%;
	}
  
	.wd-md-20-f {
	  width: 20px !important;
	}
  
	.wd-md-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-md-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-md-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-md-25 {
	  width: 25px;
	}
  
	.wd-md-25p {
	  width: 25%;
	}
  
	.mx-wd-md-25p {
	  max-width: 25%;
	}
  
	.mn-wd-md-25p {
	  min-width: 25%;
	}
  
	.wd-md-25-f {
	  width: 25px !important;
	}
  
	.wd-md-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-md-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-md-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-md-30 {
	  width: 30px;
	}
  
	.wd-md-30p {
	  width: 30%;
	}
  
	.mx-wd-md-30p {
	  max-width: 30%;
	}
  
	.mn-wd-md-30p {
	  min-width: 30%;
	}
  
	.wd-md-30-f {
	  width: 30px !important;
	}
  
	.wd-md-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-md-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-md-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-md-35 {
	  width: 35px;
	}
  
	.wd-md-35p {
	  width: 35%;
	}
  
	.mx-wd-md-35p {
	  max-width: 35%;
	}
  
	.mn-wd-md-35p {
	  min-width: 35%;
	}
  
	.wd-md-35-f {
	  width: 35px !important;
	}
  
	.wd-md-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-md-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-md-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-md-40 {
	  width: 40px;
	}
  
	.wd-md-40p {
	  width: 40%;
	}
  
	.mx-wd-md-40p {
	  max-width: 40%;
	}
  
	.mn-wd-md-40p {
	  min-width: 40%;
	}
  
	.wd-md-40-f {
	  width: 40px !important;
	}
  
	.wd-md-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-md-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-md-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-md-45 {
	  width: 45px;
	}
  
	.wd-md-45p {
	  width: 45%;
	}
  
	.mx-wd-md-45p {
	  max-width: 45%;
	}
  
	.mn-wd-md-45p {
	  min-width: 45%;
	}
  
	.wd-md-45-f {
	  width: 45px !important;
	}
  
	.wd-md-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-md-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-md-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-md-50 {
	  width: 50px;
	}
  
	.wd-md-50p {
	  width: 50%;
	}
  
	.mx-wd-md-50p {
	  max-width: 50%;
	}
  
	.mn-wd-md-50p {
	  min-width: 50%;
	}
  
	.wd-md-50-f {
	  width: 50px !important;
	}
  
	.wd-md-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-md-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-md-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-md-55 {
	  width: 55px;
	}
  
	.wd-md-55p {
	  width: 55%;
	}
  
	.mx-wd-md-55p {
	  max-width: 55%;
	}
  
	.mn-wd-md-55p {
	  min-width: 55%;
	}
  
	.wd-md-55-f {
	  width: 55px !important;
	}
  
	.wd-md-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-md-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-md-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-md-60 {
	  width: 60px;
	}
  
	.wd-md-60p {
	  width: 60%;
	}
  
	.mx-wd-md-60p {
	  max-width: 60%;
	}
  
	.mn-wd-md-60p {
	  min-width: 60%;
	}
  
	.wd-md-60-f {
	  width: 60px !important;
	}
  
	.wd-md-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-md-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-md-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-md-65 {
	  width: 65px;
	}
  
	.wd-md-65p {
	  width: 65%;
	}
  
	.mx-wd-md-65p {
	  max-width: 65%;
	}
  
	.mn-wd-md-65p {
	  min-width: 65%;
	}
  
	.wd-md-65-f {
	  width: 65px !important;
	}
  
	.wd-md-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-md-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-md-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-md-70 {
	  width: 70px;
	}
  
	.wd-md-70p {
	  width: 70%;
	}
  
	.mx-wd-md-70p {
	  max-width: 70%;
	}
  
	.mn-wd-md-70p {
	  min-width: 70%;
	}
  
	.wd-md-70-f {
	  width: 70px !important;
	}
  
	.wd-md-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-md-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-md-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-md-75 {
	  width: 75px;
	}
  
	.wd-md-75p {
	  width: 75%;
	}
  
	.mx-wd-md-75p {
	  max-width: 75%;
	}
  
	.mn-wd-md-75p {
	  min-width: 75%;
	}
  
	.wd-md-75-f {
	  width: 75px !important;
	}
  
	.wd-md-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-md-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-md-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-md-80 {
	  width: 80px;
	}
  
	.wd-md-80p {
	  width: 80%;
	}
  
	.mx-wd-md-80p {
	  max-width: 80%;
	}
  
	.mn-wd-md-80p {
	  min-width: 80%;
	}
  
	.wd-md-80-f {
	  width: 80px !important;
	}
  
	.wd-md-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-md-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-md-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-md-85 {
	  width: 85px;
	}
  
	.wd-md-85p {
	  width: 85%;
	}
  
	.mx-wd-md-85p {
	  max-width: 85%;
	}
  
	.mn-wd-md-85p {
	  min-width: 85%;
	}
  
	.wd-md-85-f {
	  width: 85px !important;
	}
  
	.wd-md-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-md-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-md-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-md-90 {
	  width: 90px;
	}
  
	.wd-md-90p {
	  width: 90%;
	}
  
	.mx-wd-md-90p {
	  max-width: 90%;
	}
  
	.mn-wd-md-90p {
	  min-width: 90%;
	}
  
	.wd-md-90-f {
	  width: 90px !important;
	}
  
	.wd-md-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-md-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-md-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-md-95 {
	  width: 95px;
	}
  
	.wd-md-95p {
	  width: 95%;
	}
  
	.mx-wd-md-95p {
	  max-width: 95%;
	}
  
	.mn-wd-md-95p {
	  min-width: 95%;
	}
  
	.wd-md-95-f {
	  width: 95px !important;
	}
  
	.wd-md-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-md-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-md-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-md-100 {
	  width: 100px;
	}
  
	.wd-md-100p {
	  width: 100%;
	}
  
	.mx-wd-md-100p {
	  max-width: 100%;
	}
  
	.mn-wd-md-100p {
	  min-width: 100%;
	}
  
	.wd-md-100-f {
	  width: 100px !important;
	}
  
	.wd-md-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-md-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-md-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-md-150 {
	  width: 150px;
	}
  
	.wd-md-150p {
	  width: 150%;
	}
  
	.mx-wd-md-150p {
	  max-width: 150%;
	}
  
	.mn-wd-md-150p {
	  min-width: 150%;
	}
  
	.wd-md-150-f {
	  width: 150px !important;
	}
  
	.wd-md-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-md-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-md-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-md-200 {
	  width: 200px;
	}
  
	.wd-md-200p {
	  width: 200%;
	}
  
	.mx-wd-md-200p {
	  max-width: 200%;
	}
  
	.mn-wd-md-200p {
	  min-width: 200%;
	}
  
	.wd-md-200-f {
	  width: 200px !important;
	}
  
	.wd-md-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-md-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-md-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-md-250 {
	  width: 250px;
	}
  
	.wd-md-250p {
	  width: 250%;
	}
  
	.mx-wd-md-250p {
	  max-width: 250%;
	}
  
	.mn-wd-md-250p {
	  min-width: 250%;
	}
  
	.wd-md-250-f {
	  width: 250px !important;
	}
  
	.wd-md-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-md-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-md-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-md-300 {
	  width: 300px;
	}
  
	.wd-md-300p {
	  width: 300%;
	}
  
	.mx-wd-md-300p {
	  max-width: 300%;
	}
  
	.mn-wd-md-300p {
	  min-width: 300%;
	}
  
	.wd-md-300-f {
	  width: 300px !important;
	}
  
	.wd-md-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-md-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-md-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-md-350 {
	  width: 350px;
	}
  
	.wd-md-350p {
	  width: 350%;
	}
  
	.mx-wd-md-350p {
	  max-width: 350%;
	}
  
	.mn-wd-md-350p {
	  min-width: 350%;
	}
  
	.wd-md-350-f {
	  width: 350px !important;
	}
  
	.wd-md-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-md-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-md-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-md-400 {
	  width: 400px;
	}
  
	.wd-md-400p {
	  width: 400%;
	}
  
	.mx-wd-md-400p {
	  max-width: 400%;
	}
  
	.mn-wd-md-400p {
	  min-width: 400%;
	}
  
	.wd-md-400-f {
	  width: 400px !important;
	}
  
	.wd-md-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-md-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-md-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-md-450 {
	  width: 450px;
	}
  
	.wd-md-450p {
	  width: 450%;
	}
  
	.mx-wd-md-450p {
	  max-width: 450%;
	}
  
	.mn-wd-md-450p {
	  min-width: 450%;
	}
  
	.wd-md-450-f {
	  width: 450px !important;
	}
  
	.wd-md-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-md-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-md-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-md-500 {
	  width: 500px;
	}
  
	.wd-md-500p {
	  width: 500%;
	}
  
	.mx-wd-md-500p {
	  max-width: 500%;
	}
  
	.mn-wd-md-500p {
	  min-width: 500%;
	}
  
	.wd-md-500-f {
	  width: 500px !important;
	}
  
	.wd-md-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-md-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-md-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-md-550 {
	  width: 550px;
	}
  
	.wd-md-550p {
	  width: 550%;
	}
  
	.mx-wd-md-550p {
	  max-width: 550%;
	}
  
	.mn-wd-md-550p {
	  min-width: 550%;
	}
  
	.wd-md-550-f {
	  width: 550px !important;
	}
  
	.wd-md-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-md-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-md-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-md-600 {
	  width: 600px;
	}
  
	.wd-md-600p {
	  width: 600%;
	}
  
	.mx-wd-md-600p {
	  max-width: 600%;
	}
  
	.mn-wd-md-600p {
	  min-width: 600%;
	}
  
	.wd-md-600-f {
	  width: 600px !important;
	}
  
	.wd-md-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-md-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-md-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-md-650 {
	  width: 650px;
	}
  
	.wd-md-650p {
	  width: 650%;
	}
  
	.mx-wd-md-650p {
	  max-width: 650%;
	}
  
	.mn-wd-md-650p {
	  min-width: 650%;
	}
  
	.wd-md-650-f {
	  width: 650px !important;
	}
  
	.wd-md-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-md-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-md-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-md-700 {
	  width: 700px;
	}
  
	.wd-md-700p {
	  width: 700%;
	}
  
	.mx-wd-md-700p {
	  max-width: 700%;
	}
  
	.mn-wd-md-700p {
	  min-width: 700%;
	}
  
	.wd-md-700-f {
	  width: 700px !important;
	}
  
	.wd-md-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-md-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-md-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-md-750 {
	  width: 750px;
	}
  
	.wd-md-750p {
	  width: 750%;
	}
  
	.mx-wd-md-750p {
	  max-width: 750%;
	}
  
	.mn-wd-md-750p {
	  min-width: 750%;
	}
  
	.wd-md-750-f {
	  width: 750px !important;
	}
  
	.wd-md-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-md-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-md-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-md-800 {
	  width: 800px;
	}
  
	.wd-md-800p {
	  width: 800%;
	}
  
	.mx-wd-md-800p {
	  max-width: 800%;
	}
  
	.mn-wd-md-800p {
	  min-width: 800%;
	}
  
	.wd-md-800-f {
	  width: 800px !important;
	}
  
	.wd-md-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-md-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-md-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-md-850 {
	  width: 850px;
	}
  
	.wd-md-850p {
	  width: 850%;
	}
  
	.mx-wd-md-850p {
	  max-width: 850%;
	}
  
	.mn-wd-md-850p {
	  min-width: 850%;
	}
  
	.wd-md-850-f {
	  width: 850px !important;
	}
  
	.wd-md-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-md-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-md-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-md-900 {
	  width: 900px;
	}
  
	.wd-md-900p {
	  width: 900%;
	}
  
	.mx-wd-md-900p {
	  max-width: 900%;
	}
  
	.mn-wd-md-900p {
	  min-width: 900%;
	}
  
	.wd-md-900-f {
	  width: 900px !important;
	}
  
	.wd-md-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-md-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-md-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-md-950 {
	  width: 950px;
	}
  
	.wd-md-950p {
	  width: 950%;
	}
  
	.mx-wd-md-950p {
	  max-width: 950%;
	}
  
	.mn-wd-md-950p {
	  min-width: 950%;
	}
  
	.wd-md-950-f {
	  width: 950px !important;
	}
  
	.wd-md-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-md-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-md-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-md-1000 {
	  width: 1000px;
	}
  
	.wd-md-1000p {
	  width: 1000%;
	}
  
	.mx-wd-md-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-md-1000p {
	  min-width: 1000%;
	}
  
	.wd-md-1000-f {
	  width: 1000px !important;
	}
  
	.wd-md-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-md-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-md-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-md-auto {
	  width: auto;
	}
  
	.wd-md-auto-f {
	  width: auto !important;
	}
  
	.wd-md-120 {
	  width: 120px;
	}
  }
  
	@media (min-width: 992px) {
	.wd-lg-5 {
	  width: 5px;
	}
  
	.wd-lg-5p {
	  width: 5%;
	}
  
	.mx-wd-lg-5p {
	  max-width: 5%;
	}
  
	.mn-wd-lg-5p {
	  min-width: 5%;
	}
  
	.wd-lg-5-f {
	  width: 5px !important;
	}
  
	.wd-lg-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-lg-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-lg-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-lg-10 {
	  width: 10px;
	}
  
	.wd-lg-10p {
	  width: 10%;
	}
  
	.mx-wd-lg-10p {
	  max-width: 10%;
	}
  
	.mn-wd-lg-10p {
	  min-width: 10%;
	}
  
	.wd-lg-10-f {
	  width: 10px !important;
	}
  
	.wd-lg-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-lg-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-lg-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-lg-15 {
	  width: 15px;
	}
  
	.wd-lg-15p {
	  width: 15%;
	}
  
	.mx-wd-lg-15p {
	  max-width: 15%;
	}
  
	.mn-wd-lg-15p {
	  min-width: 15%;
	}
  
	.wd-lg-15-f {
	  width: 15px !important;
	}
  
	.wd-lg-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-lg-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-lg-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-lg-20 {
	  width: 20px;
	}
  
	.wd-lg-20p {
	  width: 20%;
	}
  
	.mx-wd-lg-20p {
	  max-width: 20%;
	}
  
	.mn-wd-lg-20p {
	  min-width: 20%;
	}
  
	.wd-lg-20-f {
	  width: 20px !important;
	}
  
	.wd-lg-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-lg-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-lg-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-lg-25 {
	  width: 25px;
	}
  
	.wd-lg-25p {
	  width: 25%;
	}
  
	.mx-wd-lg-25p {
	  max-width: 25%;
	}
  
	.mn-wd-lg-25p {
	  min-width: 25%;
	}
  
	.wd-lg-25-f {
	  width: 25px !important;
	}
  
	.wd-lg-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-lg-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-lg-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-lg-30 {
	  width: 30px;
	}
  
	.wd-lg-30p {
	  width: 30%;
	}
  
	.mx-wd-lg-30p {
	  max-width: 30%;
	}
  
	.mn-wd-lg-30p {
	  min-width: 30%;
	}
  
	.wd-lg-30-f {
	  width: 30px !important;
	}
  
	.wd-lg-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-lg-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-lg-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-lg-35 {
	  width: 35px;
	}
  
	.wd-lg-35p {
	  width: 35%;
	}
  
	.mx-wd-lg-35p {
	  max-width: 35%;
	}
  
	.mn-wd-lg-35p {
	  min-width: 35%;
	}
  
	.wd-lg-35-f {
	  width: 35px !important;
	}
  
	.wd-lg-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-lg-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-lg-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-lg-40 {
	  width: 40px;
	}
  
	.wd-lg-40p {
	  width: 40%;
	}
  
	.mx-wd-lg-40p {
	  max-width: 40%;
	}
  
	.mn-wd-lg-40p {
	  min-width: 40%;
	}
  
	.wd-lg-40-f {
	  width: 40px !important;
	}
  
	.wd-lg-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-lg-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-lg-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-lg-45 {
	  width: 45px;
	}
  
	.wd-lg-45p {
	  width: 45%;
	}
  
	.mx-wd-lg-45p {
	  max-width: 45%;
	}
  
	.mn-wd-lg-45p {
	  min-width: 45%;
	}
  
	.wd-lg-45-f {
	  width: 45px !important;
	}
  
	.wd-lg-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-lg-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-lg-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-lg-50 {
	  width: 50px;
	}
  
	.wd-lg-50p {
	  width: 50%;
	}
  
	.mx-wd-lg-50p {
	  max-width: 50%;
	}
  
	.mn-wd-lg-50p {
	  min-width: 50%;
	}
  
	.wd-lg-50-f {
	  width: 50px !important;
	}
  
	.wd-lg-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-lg-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-lg-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-lg-55 {
	  width: 55px;
	}
  
	.wd-lg-55p {
	  width: 55%;
	}
  
	.mx-wd-lg-55p {
	  max-width: 55%;
	}
  
	.mn-wd-lg-55p {
	  min-width: 55%;
	}
  
	.wd-lg-55-f {
	  width: 55px !important;
	}
  
	.wd-lg-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-lg-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-lg-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-lg-60 {
	  width: 60px;
	}
  
	.wd-lg-60p {
	  width: 60%;
	}
  
	.mx-wd-lg-60p {
	  max-width: 60%;
	}
  
	.mn-wd-lg-60p {
	  min-width: 60%;
	}
  
	.wd-lg-60-f {
	  width: 60px !important;
	}
  
	.wd-lg-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-lg-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-lg-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-lg-65 {
	  width: 65px;
	}
  
	.wd-lg-65p {
	  width: 65%;
	}
  
	.mx-wd-lg-65p {
	  max-width: 65%;
	}
  
	.mn-wd-lg-65p {
	  min-width: 65%;
	}
  
	.wd-lg-65-f {
	  width: 65px !important;
	}
  
	.wd-lg-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-lg-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-lg-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-lg-70 {
	  width: 70px;
	}
  
	.wd-lg-70p {
	  width: 70%;
	}
  
	.mx-wd-lg-70p {
	  max-width: 70%;
	}
  
	.mn-wd-lg-70p {
	  min-width: 70%;
	}
  
	.wd-lg-70-f {
	  width: 70px !important;
	}
  
	.wd-lg-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-lg-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-lg-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-lg-75 {
	  width: 75px;
	}
  
	.wd-lg-75p {
	  width: 75%;
	}
  
	.mx-wd-lg-75p {
	  max-width: 75%;
	}
  
	.mn-wd-lg-75p {
	  min-width: 75%;
	}
  
	.wd-lg-75-f {
	  width: 75px !important;
	}
  
	.wd-lg-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-lg-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-lg-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-lg-80 {
	  width: 80px;
	}
  
	.wd-lg-80p {
	  width: 80%;
	}
  
	.mx-wd-lg-80p {
	  max-width: 80%;
	}
  
	.mn-wd-lg-80p {
	  min-width: 80%;
	}
  
	.wd-lg-80-f {
	  width: 80px !important;
	}
  
	.wd-lg-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-lg-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-lg-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-lg-85 {
	  width: 85px;
	}
  
	.wd-lg-85p {
	  width: 85%;
	}
  
	.mx-wd-lg-85p {
	  max-width: 85%;
	}
  
	.mn-wd-lg-85p {
	  min-width: 85%;
	}
  
	.wd-lg-85-f {
	  width: 85px !important;
	}
  
	.wd-lg-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-lg-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-lg-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-lg-90 {
	  width: 90px;
	}
  
	.wd-lg-90p {
	  width: 90%;
	}
  
	.mx-wd-lg-90p {
	  max-width: 90%;
	}
  
	.mn-wd-lg-90p {
	  min-width: 90%;
	}
  
	.wd-lg-90-f {
	  width: 90px !important;
	}
  
	.wd-lg-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-lg-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-lg-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-lg-95 {
	  width: 95px;
	}
  
	.wd-lg-95p {
	  width: 95%;
	}
  
	.mx-wd-lg-95p {
	  max-width: 95%;
	}
  
	.mn-wd-lg-95p {
	  min-width: 95%;
	}
  
	.wd-lg-95-f {
	  width: 95px !important;
	}
  
	.wd-lg-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-lg-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-lg-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-lg-100 {
	  width: 100px;
	}
  
	.wd-lg-100p {
	  width: 100%;
	}
  
	.mx-wd-lg-100p {
	  max-width: 100%;
	}
  
	.mn-wd-lg-100p {
	  min-width: 100%;
	}
  
	.wd-lg-100-f {
	  width: 100px !important;
	}
  
	.wd-lg-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-lg-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-lg-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-lg-150 {
	  width: 150px;
	}
  
	.wd-lg-150p {
	  width: 150%;
	}
  
	.mx-wd-lg-150p {
	  max-width: 150%;
	}
  
	.mn-wd-lg-150p {
	  min-width: 150%;
	}
  
	.wd-lg-150-f {
	  width: 150px !important;
	}
  
	.wd-lg-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-lg-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-lg-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-lg-200 {
	  width: 200px;
	}
  
	.wd-lg-200p {
	  width: 200%;
	}
  
	.mx-wd-lg-200p {
	  max-width: 200%;
	}
  
	.mn-wd-lg-200p {
	  min-width: 200%;
	}
  
	.wd-lg-200-f {
	  width: 200px !important;
	}
  
	.wd-lg-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-lg-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-lg-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-lg-250 {
	  width: 250px;
	}
  
	.wd-lg-250p {
	  width: 250%;
	}
  
	.mx-wd-lg-250p {
	  max-width: 250%;
	}
  
	.mn-wd-lg-250p {
	  min-width: 250%;
	}
  
	.wd-lg-250-f {
	  width: 250px !important;
	}
  
	.wd-lg-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-lg-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-lg-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-lg-300 {
	  width: 300px;
	}
  
	.wd-lg-300p {
	  width: 300%;
	}
  
	.mx-wd-lg-300p {
	  max-width: 300%;
	}
  
	.mn-wd-lg-300p {
	  min-width: 300%;
	}
  
	.wd-lg-300-f {
	  width: 300px !important;
	}
  
	.wd-lg-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-lg-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-lg-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-lg-350 {
	  width: 350px;
	}
  
	.wd-lg-350p {
	  width: 350%;
	}
  
	.mx-wd-lg-350p {
	  max-width: 350%;
	}
  
	.mn-wd-lg-350p {
	  min-width: 350%;
	}
  
	.wd-lg-350-f {
	  width: 350px !important;
	}
  
	.wd-lg-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-lg-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-lg-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-lg-400 {
	  width: 400px;
	}
  
	.wd-lg-400p {
	  width: 400%;
	}
  
	.mx-wd-lg-400p {
	  max-width: 400%;
	}
  
	.mn-wd-lg-400p {
	  min-width: 400%;
	}
  
	.wd-lg-400-f {
	  width: 400px !important;
	}
  
	.wd-lg-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-lg-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-lg-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-lg-450 {
	  width: 450px;
	}
  
	.wd-lg-450p {
	  width: 450%;
	}
  
	.mx-wd-lg-450p {
	  max-width: 450%;
	}
  
	.mn-wd-lg-450p {
	  min-width: 450%;
	}
  
	.wd-lg-450-f {
	  width: 450px !important;
	}
  
	.wd-lg-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-lg-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-lg-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-lg-500 {
	  width: 500px;
	}
  
	.wd-lg-500p {
	  width: 500%;
	}
  
	.mx-wd-lg-500p {
	  max-width: 500%;
	}
  
	.mn-wd-lg-500p {
	  min-width: 500%;
	}
  
	.wd-lg-500-f {
	  width: 500px !important;
	}
  
	.wd-lg-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-lg-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-lg-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-lg-550 {
	  width: 550px;
	}
  
	.wd-lg-550p {
	  width: 550%;
	}
  
	.mx-wd-lg-550p {
	  max-width: 550%;
	}
  
	.mn-wd-lg-550p {
	  min-width: 550%;
	}
  
	.wd-lg-550-f {
	  width: 550px !important;
	}
  
	.wd-lg-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-lg-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-lg-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-lg-600 {
	  width: 600px;
	}
  
	.wd-lg-600p {
	  width: 600%;
	}
  
	.mx-wd-lg-600p {
	  max-width: 600%;
	}
  
	.mn-wd-lg-600p {
	  min-width: 600%;
	}
  
	.wd-lg-600-f {
	  width: 600px !important;
	}
  
	.wd-lg-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-lg-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-lg-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-lg-650 {
	  width: 650px;
	}
  
	.wd-lg-650p {
	  width: 650%;
	}
  
	.mx-wd-lg-650p {
	  max-width: 650%;
	}
  
	.mn-wd-lg-650p {
	  min-width: 650%;
	}
  
	.wd-lg-650-f {
	  width: 650px !important;
	}
  
	.wd-lg-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-lg-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-lg-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-lg-700 {
	  width: 700px;
	}
  
	.wd-lg-700p {
	  width: 700%;
	}
  
	.mx-wd-lg-700p {
	  max-width: 700%;
	}
  
	.mn-wd-lg-700p {
	  min-width: 700%;
	}
  
	.wd-lg-700-f {
	  width: 700px !important;
	}
  
	.wd-lg-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-lg-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-lg-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-lg-750 {
	  width: 750px;
	}
  
	.wd-lg-750p {
	  width: 750%;
	}
  
	.mx-wd-lg-750p {
	  max-width: 750%;
	}
  
	.mn-wd-lg-750p {
	  min-width: 750%;
	}
  
	.wd-lg-750-f {
	  width: 750px !important;
	}
  
	.wd-lg-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-lg-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-lg-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-lg-800 {
	  width: 800px;
	}
  
	.wd-lg-800p {
	  width: 800%;
	}
  
	.mx-wd-lg-800p {
	  max-width: 800%;
	}
  
	.mn-wd-lg-800p {
	  min-width: 800%;
	}
  
	.wd-lg-800-f {
	  width: 800px !important;
	}
  
	.wd-lg-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-lg-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-lg-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-lg-850 {
	  width: 850px;
	}
  
	.wd-lg-850p {
	  width: 850%;
	}
  
	.mx-wd-lg-850p {
	  max-width: 850%;
	}
  
	.mn-wd-lg-850p {
	  min-width: 850%;
	}
  
	.wd-lg-850-f {
	  width: 850px !important;
	}
  
	.wd-lg-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-lg-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-lg-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-lg-900 {
	  width: 900px;
	}
  
	.wd-lg-900p {
	  width: 900%;
	}
  
	.mx-wd-lg-900p {
	  max-width: 900%;
	}
  
	.mn-wd-lg-900p {
	  min-width: 900%;
	}
  
	.wd-lg-900-f {
	  width: 900px !important;
	}
  
	.wd-lg-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-lg-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-lg-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-lg-950 {
	  width: 950px;
	}
  
	.wd-lg-950p {
	  width: 950%;
	}
  
	.mx-wd-lg-950p {
	  max-width: 950%;
	}
  
	.mn-wd-lg-950p {
	  min-width: 950%;
	}
  
	.wd-lg-950-f {
	  width: 950px !important;
	}
  
	.wd-lg-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-lg-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-lg-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-lg-1000 {
	  width: 1000px;
	}
  
	.wd-lg-1000p {
	  width: 1000%;
	}
  
	.mx-wd-lg-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-lg-1000p {
	  min-width: 1000%;
	}
  
	.wd-lg-1000-f {
	  width: 1000px !important;
	}
  
	.wd-lg-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-lg-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-lg-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-lg-auto {
	  width: auto;
	}
  
	.wd-lg-auto-f {
	  width: auto !important;
	}
}
  
  @media (min-width: 1200px) {
	.wd-xl-5 {
	  width: 5px;
	}
  
	.wd-xl-5p {
	  width: 5%;
	}
  
	.mx-wd-xl-5p {
	  max-width: 5%;
	}
  
	.mn-wd-xl-5p {
	  min-width: 5%;
	}
  
	.wd-xl-5-f {
	  width: 5px !important;
	}
  
	.wd-xl-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-xl-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-xl-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-xl-10 {
	  width: 10px;
	}
  
	.wd-xl-10p {
	  width: 10%;
	}
  
	.mx-wd-xl-10p {
	  max-width: 10%;
	}
  
	.mn-wd-xl-10p {
	  min-width: 10%;
	}
  
	.wd-xl-10-f {
	  width: 10px !important;
	}
  
	.wd-xl-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-xl-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-xl-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-xl-15 {
	  width: 15px;
	}
  
	.wd-xl-15p {
	  width: 15%;
	}
  
	.mx-wd-xl-15p {
	  max-width: 15%;
	}
  
	.mn-wd-xl-15p {
	  min-width: 15%;
	}
  
	.wd-xl-15-f {
	  width: 15px !important;
	}
  
	.wd-xl-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-xl-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-xl-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-xl-20 {
	  width: 20px;
	}
  
	.wd-xl-20p {
	  width: 20%;
	}
  
	.mx-wd-xl-20p {
	  max-width: 20%;
	}
  
	.mn-wd-xl-20p {
	  min-width: 20%;
	}
  
	.wd-xl-20-f {
	  width: 20px !important;
	}
  
	.wd-xl-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-xl-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-xl-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-xl-25 {
	  width: 25px;
	}
  
	.wd-xl-25p {
	  width: 25%;
	}
  
	.mx-wd-xl-25p {
	  max-width: 25%;
	}
  
	.mn-wd-xl-25p {
	  min-width: 25%;
	}
  
	.wd-xl-25-f {
	  width: 25px !important;
	}
  
	.wd-xl-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-xl-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-xl-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-xl-30 {
	  width: 30px;
	}
  
	.wd-xl-30p {
	  width: 30%;
	}
  
	.mx-wd-xl-30p {
	  max-width: 30%;
	}
  
	.mn-wd-xl-30p {
	  min-width: 30%;
	}
  
	.wd-xl-30-f {
	  width: 30px !important;
	}
  
	.wd-xl-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-xl-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-xl-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-xl-35 {
	  width: 35px;
	}
  
	.wd-xl-35p {
	  width: 35%;
	}
  
	.mx-wd-xl-35p {
	  max-width: 35%;
	}
  
	.mn-wd-xl-35p {
	  min-width: 35%;
	}
  
	.wd-xl-35-f {
	  width: 35px !important;
	}
  
	.wd-xl-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-xl-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-xl-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-xl-40 {
	  width: 40px;
	}
  
	.wd-xl-40p {
	  width: 40%;
	}
  
	.mx-wd-xl-40p {
	  max-width: 40%;
	}
  
	.mn-wd-xl-40p {
	  min-width: 40%;
	}
  
	.wd-xl-40-f {
	  width: 40px !important;
	}
  
	.wd-xl-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-xl-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-xl-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-xl-45 {
	  width: 45px;
	}
  
	.wd-xl-45p {
	  width: 45%;
	}
  
	.mx-wd-xl-45p {
	  max-width: 45%;
	}
  
	.mn-wd-xl-45p {
	  min-width: 45%;
	}
  
	.wd-xl-45-f {
	  width: 45px !important;
	}
  
	.wd-xl-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-xl-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-xl-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-xl-50 {
	  width: 50px;
	}
  
	.wd-xl-50p {
	  width: 50%;
	}
  
	.mx-wd-xl-50p {
	  max-width: 50%;
	}
  
	.mn-wd-xl-50p {
	  min-width: 50%;
	}
  
	.wd-xl-50-f {
	  width: 50px !important;
	}
  
	.wd-xl-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-xl-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-xl-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-xl-55 {
	  width: 55px;
	}
  
	.wd-xl-55p {
	  width: 55%;
	}
  
	.mx-wd-xl-55p {
	  max-width: 55%;
	}
  
	.mn-wd-xl-55p {
	  min-width: 55%;
	}
  
	.wd-xl-55-f {
	  width: 55px !important;
	}
  
	.wd-xl-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-xl-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-xl-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-xl-60 {
	  width: 60px;
	}
  
	.wd-xl-60p {
	  width: 60%;
	}
  
	.mx-wd-xl-60p {
	  max-width: 60%;
	}
  
	.mn-wd-xl-60p {
	  min-width: 60%;
	}
  
	.wd-xl-60-f {
	  width: 60px !important;
	}
  
	.wd-xl-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-xl-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-xl-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-xl-65 {
	  width: 65px;
	}
  
	.wd-xl-65p {
	  width: 65%;
	}
  
	.mx-wd-xl-65p {
	  max-width: 65%;
	}
  
	.mn-wd-xl-65p {
	  min-width: 65%;
	}
  
	.wd-xl-65-f {
	  width: 65px !important;
	}
  
	.wd-xl-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-xl-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-xl-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-xl-70 {
	  width: 70px;
	}
  
	.wd-xl-70p {
	  width: 70%;
	}
  
	.mx-wd-xl-70p {
	  max-width: 70%;
	}
  
	.mn-wd-xl-70p {
	  min-width: 70%;
	}
  
	.wd-xl-70-f {
	  width: 70px !important;
	}
  
	.wd-xl-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-xl-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-xl-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-xl-75 {
	  width: 75px;
	}
  
	.wd-xl-75p {
	  width: 75%;
	}
  
	.mx-wd-xl-75p {
	  max-width: 75%;
	}
  
	.mn-wd-xl-75p {
	  min-width: 75%;
	}
  
	.wd-xl-75-f {
	  width: 75px !important;
	}
  
	.wd-xl-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-xl-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-xl-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-xl-80 {
	  width: 80px;
	}
  
	.wd-xl-80p {
	  width: 80%;
	}
  
	.mx-wd-xl-80p {
	  max-width: 80%;
	}
  
	.mn-wd-xl-80p {
	  min-width: 80%;
	}
  
	.wd-xl-80-f {
	  width: 80px !important;
	}
  
	.wd-xl-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-xl-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-xl-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-xl-85 {
	  width: 85px;
	}
  
	.wd-xl-85p {
	  width: 85%;
	}
  
	.mx-wd-xl-85p {
	  max-width: 85%;
	}
  
	.mn-wd-xl-85p {
	  min-width: 85%;
	}
  
	.wd-xl-85-f {
	  width: 85px !important;
	}
  
	.wd-xl-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-xl-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-xl-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-xl-90 {
	  width: 90px;
	}
  
	.wd-xl-90p {
	  width: 90%;
	}
  
	.mx-wd-xl-90p {
	  max-width: 90%;
	}
  
	.mn-wd-xl-90p {
	  min-width: 90%;
	}
  
	.wd-xl-90-f {
	  width: 90px !important;
	}
  
	.wd-xl-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-xl-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-xl-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-xl-95 {
	  width: 95px;
	}
  
	.wd-xl-95p {
	  width: 95%;
	}
  
	.mx-wd-xl-95p {
	  max-width: 95%;
	}
  
	.mn-wd-xl-95p {
	  min-width: 95%;
	}
  
	.wd-xl-95-f {
	  width: 95px !important;
	}
  
	.wd-xl-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-xl-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-xl-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-xl-100 {
	  width: 100px;
	}
  
	.wd-xl-100p {
	  width: 100%;
	}
  
	.mx-wd-xl-100p {
	  max-width: 100%;
	}
  
	.mn-wd-xl-100p {
	  min-width: 100%;
	}
  
	.wd-xl-100-f {
	  width: 100px !important;
	}
  
	.wd-xl-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-xl-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-xl-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-xl-150 {
	  width: 150px;
	}
  
	.wd-xl-150p {
	  width: 150%;
	}
  
	.mx-wd-xl-150p {
	  max-width: 150%;
	}
  
	.mn-wd-xl-150p {
	  min-width: 150%;
	}
  
	.wd-xl-150-f {
	  width: 150px !important;
	}
  
	.wd-xl-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-xl-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-xl-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-xl-200 {
	  width: 200px;
	}
  
	.wd-xl-200p {
	  width: 200%;
	}
  
	.mx-wd-xl-200p {
	  max-width: 200%;
	}
  
	.mn-wd-xl-200p {
	  min-width: 200%;
	}
  
	.wd-xl-200-f {
	  width: 200px !important;
	}
  
	.wd-xl-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-xl-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-xl-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-xl-250 {
	  width: 250px;
	}
  
	.wd-xl-250p {
	  width: 250%;
	}
  
	.mx-wd-xl-250p {
	  max-width: 250%;
	}
  
	.mn-wd-xl-250p {
	  min-width: 250%;
	}
  
	.wd-xl-250-f {
	  width: 250px !important;
	}
  
	.wd-xl-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-xl-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-xl-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-xl-300 {
	  width: 300px;
	}
  
	.wd-xl-300p {
	  width: 300%;
	}
  
	.mx-wd-xl-300p {
	  max-width: 300%;
	}
  
	.mn-wd-xl-300p {
	  min-width: 300%;
	}
  
	.wd-xl-300-f {
	  width: 300px !important;
	}
  
	.wd-xl-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-xl-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-xl-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-xl-350 {
	  width: 350px;
	}
  
	.wd-xl-350p {
	  width: 350%;
	}
  
	.mx-wd-xl-350p {
	  max-width: 350%;
	}
  
	.mn-wd-xl-350p {
	  min-width: 350%;
	}
  
	.wd-xl-350-f {
	  width: 350px !important;
	}
  
	.wd-xl-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-xl-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-xl-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-xl-400 {
	  width: 400px;
	}
  
	.wd-xl-400p {
	  width: 400%;
	}
  
	.mx-wd-xl-400p {
	  max-width: 400%;
	}
  
	.mn-wd-xl-400p {
	  min-width: 400%;
	}
  
	.wd-xl-400-f {
	  width: 400px !important;
	}
  
	.wd-xl-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-xl-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-xl-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-xl-450 {
	  width: 450px;
	}
  
	.wd-xl-450p {
	  width: 450%;
	}
  
	.mx-wd-xl-450p {
	  max-width: 450%;
	}
  
	.mn-wd-xl-450p {
	  min-width: 450%;
	}
  
	.wd-xl-450-f {
	  width: 450px !important;
	}
  
	.wd-xl-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-xl-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-xl-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-xl-500 {
	  width: 500px;
	}
  
	.wd-xl-500p {
	  width: 500%;
	}
  
	.mx-wd-xl-500p {
	  max-width: 500%;
	}
  
	.mn-wd-xl-500p {
	  min-width: 500%;
	}
  
	.wd-xl-500-f {
	  width: 500px !important;
	}
  
	.wd-xl-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-xl-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-xl-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-xl-550 {
	  width: 550px;
	}
  
	.wd-xl-550p {
	  width: 550%;
	}
  
	.mx-wd-xl-550p {
	  max-width: 550%;
	}
  
	.mn-wd-xl-550p {
	  min-width: 550%;
	}
  
	.wd-xl-550-f {
	  width: 550px !important;
	}
  
	.wd-xl-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-xl-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-xl-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-xl-600 {
	  width: 600px;
	}
  
	.wd-xl-600p {
	  width: 600%;
	}
  
	.mx-wd-xl-600p {
	  max-width: 600%;
	}
  
	.mn-wd-xl-600p {
	  min-width: 600%;
	}
  
	.wd-xl-600-f {
	  width: 600px !important;
	}
  
	.wd-xl-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-xl-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-xl-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-xl-650 {
	  width: 650px;
	}
  
	.wd-xl-650p {
	  width: 650%;
	}
  
	.mx-wd-xl-650p {
	  max-width: 650%;
	}
  
	.mn-wd-xl-650p {
	  min-width: 650%;
	}
  
	.wd-xl-650-f {
	  width: 650px !important;
	}
  
	.wd-xl-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-xl-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-xl-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-xl-700 {
	  width: 700px;
	}
  
	.wd-xl-700p {
	  width: 700%;
	}
  
	.mx-wd-xl-700p {
	  max-width: 700%;
	}
  
	.mn-wd-xl-700p {
	  min-width: 700%;
	}
  
	.wd-xl-700-f {
	  width: 700px !important;
	}
  
	.wd-xl-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-xl-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-xl-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-xl-750 {
	  width: 750px;
	}
  
	.wd-xl-750p {
	  width: 750%;
	}
  
	.mx-wd-xl-750p {
	  max-width: 750%;
	}
  
	.mn-wd-xl-750p {
	  min-width: 750%;
	}
  
	.wd-xl-750-f {
	  width: 750px !important;
	}
  
	.wd-xl-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-xl-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-xl-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-xl-800 {
	  width: 800px;
	}
  
	.wd-xl-800p {
	  width: 800%;
	}
  
	.mx-wd-xl-800p {
	  max-width: 800%;
	}
  
	.mn-wd-xl-800p {
	  min-width: 800%;
	}
  
	.wd-xl-800-f {
	  width: 800px !important;
	}
  
	.wd-xl-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-xl-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-xl-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-xl-850 {
	  width: 850px;
	}
  
	.wd-xl-850p {
	  width: 850%;
	}
  
	.mx-wd-xl-850p {
	  max-width: 850%;
	}
  
	.mn-wd-xl-850p {
	  min-width: 850%;
	}
  
	.wd-xl-850-f {
	  width: 850px !important;
	}
  
	.wd-xl-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-xl-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-xl-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-xl-900 {
	  width: 900px;
	}
  
	.wd-xl-900p {
	  width: 900%;
	}
  
	.mx-wd-xl-900p {
	  max-width: 900%;
	}
  
	.mn-wd-xl-900p {
	  min-width: 900%;
	}
  
	.wd-xl-900-f {
	  width: 900px !important;
	}
  
	.wd-xl-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-xl-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-xl-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-xl-950 {
	  width: 950px;
	}
  
	.wd-xl-950p {
	  width: 950%;
	}
  
	.mx-wd-xl-950p {
	  max-width: 950%;
	}
  
	.mn-wd-xl-950p {
	  min-width: 950%;
	}
  
	.wd-xl-950-f {
	  width: 950px !important;
	}
  
	.wd-xl-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-xl-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-xl-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-xl-1000 {
	  width: 1000px;
	}
  
	.wd-xl-1000p {
	  width: 1000%;
	}
  
	.mx-wd-xl-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-xl-1000p {
	  min-width: 1000%;
	}
  
	.wd-xl-1000-f {
	  width: 1000px !important;
	}
  
	.wd-xl-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-xl-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-xl-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-xl-auto {
	  width: auto;
	  width: auto !important;
	}
}

@media (max-width: 576px) {
	.wd-sm-5-max {
	  width: 5px;
	}
  
	.wd-sm-5p-max {
	  width: 5%;
	}
  
	.mx-wd-sm-5p-max {
	  max-width: 5%;
	}
  
	.mn-wd-sm-5p-max {
	  min-width: 5%;
	}
  
	.wd-sm-5-f-max {
	  width: 5px !important;
	}
  
	.wd-sm-5p-f-max {
	  width: 5% !important;
	}
  
	.mx-wd-sm-5p-f-max {
	  max-width: 5% !important;
	}
  
	.mn-wd-sm-5p-f-max {
	  min-width: 5% !important;
	}
  
	.wd-sm-10-max {
	  width: 10px;
	}
  
	.wd-sm-10p-max {
	  width: 10%;
	}
  
	.mx-wd-sm-10p-max {
	  max-width: 10%;
	}
  
	.mn-wd-sm-10p-max {
	  min-width: 10%;
	}
  
	.wd-sm-10-f-max {
	  width: 10px !important;
	}
  
	.wd-sm-10p-f-max {
	  width: 10% !important;
	}
  
	.mx-wd-sm-10p-f-max {
	  max-width: 10% !important;
	}
  
	.mn-wd-sm-10p-f-max {
	  min-width: 10% !important;
	}
  
	.wd-sm-15-max {
	  width: 15px;
	}
  
	.wd-sm-15p-max {
	  width: 15%;
	}
  
	.mx-wd-sm-15p-max {
	  max-width: 15%;
	}
  
	.mn-wd-sm-15p-max {
	  min-width: 15%;
	}
  
	.wd-sm-15-f-max {
	  width: 15px !important;
	}
  
	.wd-sm-15p-f-max {
	  width: 15% !important;
	}
  
	.mx-wd-sm-15p-f-max {
	  max-width: 15% !important;
	}
  
	.mn-wd-sm-15p-f-max {
	  min-width: 15% !important;
	}
  
	.wd-sm-20-max {
	  width: 20px;
	}
  
	.wd-sm-20p-max {
	  width: 20%;
	}
  
	.mx-wd-sm-20p-max {
	  max-width: 20%;
	}
  
	.mn-wd-sm-20p-max {
	  min-width: 20%;
	}
  
	.wd-sm-20-f-max {
	  width: 20px !important;
	}
  
	.wd-sm-20p-f-max {
	  width: 20% !important;
	}
  
	.mx-wd-sm-20p-f-max {
	  max-width: 20% !important;
	}
  
	.mn-wd-sm-20p-f-max {
	  min-width: 20% !important;
	}
  
	.wd-sm-25-max {
	  width: 25px;
	}
  
	.wd-sm-25p-max {
	  width: 25%;
	}
  
	.mx-wd-sm-25p-max {
	  max-width: 25%;
	}
  
	.mn-wd-sm-25p-max {
	  min-width: 25%;
	}
  
	.wd-sm-25-f-max {
	  width: 25px !important;
	}
  
	.wd-sm-25p-f-max {
	  width: 25% !important;
	}
  
	.mx-wd-sm-25p-f-max {
	  max-width: 25% !important;
	}
  
	.mn-wd-sm-25p-f-max {
	  min-width: 25% !important;
	}
  
	.wd-sm-30-max {
	  width: 30px;
	}
  
	.wd-sm-30p-max {
	  width: 30%;
	}
  
	.mx-wd-sm-30p-max {
	  max-width: 30%;
	}
  
	.mn-wd-sm-30p-max {
	  min-width: 30%;
	}
  
	.wd-sm-30-f-max {
	  width: 30px !important;
	}
  
	.wd-sm-30p-f-max {
	  width: 30% !important;
	}
  
	.mx-wd-sm-30p-f-max {
	  max-width: 30% !important;
	}
  
	.mn-wd-sm-30p-f-max {
	  min-width: 30% !important;
	}
  
	.wd-sm-35-max {
	  width: 35px;
	}
  
	.wd-sm-35p-max {
	  width: 35%;
	}
  
	.mx-wd-sm-35p-max {
	  max-width: 35%;
	}
  
	.mn-wd-sm-35p-max {
	  min-width: 35%;
	}
  
	.wd-sm-35-f-max {
	  width: 35px !important;
	}
  
	.wd-sm-35p-f-max {
	  width: 35% !important;
	}
  
	.mx-wd-sm-35p-f-max {
	  max-width: 35% !important;
	}
  
	.mn-wd-sm-35p-f-max {
	  min-width: 35% !important;
	}
  
	.wd-sm-40-max {
	  width: 40px;
	}
  
	.wd-sm-40p-max {
	  width: 40%;
	}
  
	.mx-wd-sm-40p-max {
	  max-width: 40%;
	}
  
	.mn-wd-sm-40p-max {
	  min-width: 40%;
	}
  
	.wd-sm-40-f-max {
	  width: 40px !important;
	}
  
	.wd-sm-40p-f-max {
	  width: 40% !important;
	}
  
	.mx-wd-sm-40p-f-max {
	  max-width: 40% !important;
	}
  
	.mn-wd-sm-40p-f-max {
	  min-width: 40% !important;
	}
  
	.wd-sm-45-max {
	  width: 45px;
	}
  
	.wd-sm-45p-max {
	  width: 45%;
	}
  
	.mx-wd-sm-45p-max {
	  max-width: 45%;
	}
  
	.mn-wd-sm-45p-max {
	  min-width: 45%;
	}
  
	.wd-sm-45-f-max {
	  width: 45px !important;
	}
  
	.wd-sm-45p-f-max {
	  width: 45% !important;
	}
  
	.mx-wd-sm-45p-f-max {
	  max-width: 45% !important;
	}
  
	.mn-wd-sm-45p-f-max {
	  min-width: 45% !important;
	}
  
	.wd-sm-50-max {
	  width: 50px;
	}
  
	.wd-sm-50p-max {
	  width: 50%;
	}
  
	.mx-wd-sm-50p-max {
	  max-width: 50%;
	}
  
	.mn-wd-sm-50p-max {
	  min-width: 50%;
	}
  
	.wd-sm-50-f-max {
	  width: 50px !important;
	}
  
	.wd-sm-50p-f-max {
	  width: 50% !important;
	}
  
	.mx-wd-sm-50p-f-max {
	  max-width: 50% !important;
	}
  
	.mn-wd-sm-50p-f-max {
	  min-width: 50% !important;
	}
  
	.wd-sm-55-max {
	  width: 55px;
	}
  
	.wd-sm-55p-max {
	  width: 55%;
	}
  
	.mx-wd-sm-55p-max {
	  max-width: 55%;
	}
  
	.mn-wd-sm-55p-max {
	  min-width: 55%;
	}
  
	.wd-sm-55-f-max {
	  width: 55px !important;
	}
  
	.wd-sm-55p-f-max {
	  width: 55% !important;
	}
  
	.mx-wd-sm-55p-f-max {
	  max-width: 55% !important;
	}
  
	.mn-wd-sm-55p-f-max {
	  min-width: 55% !important;
	}
  
	.wd-sm-60-max {
	  width: 60px;
	}
  
	.wd-sm-60p-max {
	  width: 60%;
	}
  
	.mx-wd-sm-60p-max {
	  max-width: 60%;
	}
  
	.mn-wd-sm-60p-max {
	  min-width: 60%;
	}
  
	.wd-sm-60-f-max {
	  width: 60px !important;
	}
  
	.wd-sm-60p-f-max {
	  width: 60% !important;
	}
  
	.mx-wd-sm-60p-f-max {
	  max-width: 60% !important;
	}
  
	.mn-wd-sm-60p-f-max {
	  min-width: 60% !important;
	}
  
	.wd-sm-65-max {
	  width: 65px;
	}
  
	.wd-sm-65p-max {
	  width: 65%;
	}
  
	.mx-wd-sm-65p-max {
	  max-width: 65%;
	}
  
	.mn-wd-sm-65p-max {
	  min-width: 65%;
	}
  
	.wd-sm-65-f-max {
	  width: 65px !important;
	}
  
	.wd-sm-65p-f-max {
	  width: 65% !important;
	}
  
	.mx-wd-sm-65p-f-max {
	  max-width: 65% !important;
	}
  
	.mn-wd-sm-65p-f-max {
	  min-width: 65% !important;
	}
  
	.wd-sm-70-max {
	  width: 70px;
	}
  
	.wd-sm-70p-max {
	  width: 70%;
	}
  
	.mx-wd-sm-70p-max {
	  max-width: 70%;
	}
  
	.mn-wd-sm-70p-max {
	  min-width: 70%;
	}
  
	.wd-sm-70-f-max {
	  width: 70px !important;
	}
  
	.wd-sm-70p-f-max {
	  width: 70% !important;
	}
  
	.mx-wd-sm-70p-f-max {
	  max-width: 70% !important;
	}
  
	.mn-wd-sm-70p-f-max {
	  min-width: 70% !important;
	}
  
	.wd-sm-75-max {
	  width: 75px;
	}
  
	.wd-sm-75p-max {
	  width: 75%;
	}
  
	.mx-wd-sm-75p-max {
	  max-width: 75%;
	}
  
	.mn-wd-sm-75p-max {
	  min-width: 75%;
	}
  
	.wd-sm-75-f-max {
	  width: 75px !important;
	}
  
	.wd-sm-75p-f-max {
	  width: 75% !important;
	}
  
	.mx-wd-sm-75p-f-max {
	  max-width: 75% !important;
	}
  
	.mn-wd-sm-75p-f-max {
	  min-width: 75% !important;
	}
  
	.wd-sm-80-max {
	  width: 80px;
	}
  
	.wd-sm-80p-max {
	  width: 80%;
	}
  
	.mx-wd-sm-80p-max {
	  max-width: 80%;
	}
  
	.mn-wd-sm-80p-max {
	  min-width: 80%;
	}
  
	.wd-sm-80-f-max {
	  width: 80px !important;
	}
  
	.wd-sm-80p-f-max {
	  width: 80% !important;
	}
  
	.mx-wd-sm-80p-f-max {
	  max-width: 80% !important;
	}
  
	.mn-wd-sm-80p-f-max {
	  min-width: 80% !important;
	}
  
	.wd-sm-85-max {
	  width: 85px;
	}
  
	.wd-sm-85p-max {
	  width: 85%;
	}
  
	.mx-wd-sm-85p-max {
	  max-width: 85%;
	}
  
	.mn-wd-sm-85p-max {
	  min-width: 85%;
	}
  
	.wd-sm-85-f-max {
	  width: 85px !important;
	}
  
	.wd-sm-85p-f-max {
	  width: 85% !important;
	}
  
	.mx-wd-sm-85p-f-max {
	  max-width: 85% !important;
	}
  
	.mn-wd-sm-85p-f-max {
	  min-width: 85% !important;
	}
  
	.wd-sm-90-max {
	  width: 90px;
	}
  
	.wd-sm-90p-max {
	  width: 90%;
	}
  
	.mx-wd-sm-90p-max {
	  max-width: 90%;
	}
  
	.mn-wd-sm-90p-max {
	  min-width: 90%;
	}
  
	.wd-sm-90-f-max {
	  width: 90px !important;
	}
  
	.wd-sm-90p-f-max {
	  width: 90% !important;
	}
  
	.mx-wd-sm-90p-f-max {
	  max-width: 90% !important;
	}
  
	.mn-wd-sm-90p-f-max {
	  min-width: 90% !important;
	}
  
	.wd-sm-95-max {
	  width: 95px;
	}
  
	.wd-sm-95p-max {
	  width: 95%;
	}
  
	.mx-wd-sm-95p-max {
	  max-width: 95%;
	}
  
	.mn-wd-sm-95p-max {
	  min-width: 95%;
	}
  
	.wd-sm-95-f-max {
	  width: 95px !important;
	}
  
	.wd-sm-95p-f-max {
	  width: 95% !important;
	}
  
	.mx-wd-sm-95p-f-max {
	  max-width: 95% !important;
	}
  
	.mn-wd-sm-95p-f-max {
	  min-width: 95% !important;
	}
  
	.wd-sm-100-max {
	  width: 100px;
	}
  
	.wd-sm-100p-max {
	  width: 100%;
	}
  
	.mx-wd-sm-100p-max {
	  max-width: 100%;
	}
  
	.mn-wd-sm-100p-max {
	  min-width: 100%;
	}
  
	.wd-sm-100-f-max {
	  width: 100px !important;
	}
  
	.wd-sm-100p-f-max {
	  width: 100% !important;
	}
  
	.mx-wd-sm-100p-f-max {
	  max-width: 100% !important;
	}
  
	.mn-wd-sm-100p-f-max {
	  min-width: 100% !important;
	}
  
	.wd-sm-150-max {
	  width: 150px;
	}
  
	.wd-sm-150p-max {
	  width: 150%;
	}
  
	.mx-wd-sm-150p-max {
	  max-width: 150%;
	}
  
	.mn-wd-sm-150p-max {
	  min-width: 150%;
	}
  
	.wd-sm-150-f-max {
	  width: 150px !important;
	}
  
	.wd-sm-150p-f-max {
	  width: 150% !important;
	}
  
	.mx-wd-sm-150p-f-max {
	  max-width: 150% !important;
	}
  
	.mn-wd-sm-150p-f-max {
	  min-width: 150% !important;
	}
  
	.wd-sm-200-max {
	  width: 200px;
	}
  
	.wd-sm-200p-max {
	  width: 200%;
	}
  
	.mx-wd-sm-200p-max {
	  max-width: 200%;
	}
  
	.mn-wd-sm-200p-max {
	  min-width: 200%;
	}
  
	.wd-sm-200-f-max {
	  width: 200px !important;
	}
  
	.wd-sm-200p-f-max {
	  width: 200% !important;
	}
  
	.mx-wd-sm-200p-f-max {
	  max-width: 200% !important;
	}
  
	.mn-wd-sm-200p-f-max {
	  min-width: 200% !important;
	}
  
	.wd-sm-250-max {
	  width: 250px;
	}
  
	.wd-sm-250p-max {
	  width: 250%;
	}
  
	.mx-wd-sm-250p-max {
	  max-width: 250%;
	}
  
	.mn-wd-sm-250p-max {
	  min-width: 250%;
	}
  
	.wd-sm-250-f-max {
	  width: 250px !important;
	}
  
	.wd-sm-250p-f-max {
	  width: 250% !important;
	}
  
	.mx-wd-sm-250p-f-max {
	  max-width: 250% !important;
	}
  
	.mn-wd-sm-250p-f-max {
	  min-width: 250% !important;
	}
  
	.wd-sm-300-max {
	  width: 300px;
	}
  
	.wd-sm-300p-max {
	  width: 300%;
	}
  
	.mx-wd-sm-300p-max {
	  max-width: 300%;
	}
  
	.mn-wd-sm-300p-max {
	  min-width: 300%;
	}
  
	.wd-sm-300-f-max {
	  width: 300px !important;
	}
  
	.wd-sm-300p-f-max {
	  width: 300% !important;
	}
  
	.mx-wd-sm-300p-f-max {
	  max-width: 300% !important;
	}
  
	.mn-wd-sm-300p-f-max {
	  min-width: 300% !important;
	}
  
	.wd-sm-350-max {
	  width: 350px;
	}
  
	.wd-sm-350p-max {
	  width: 350%;
	}
  
	.mx-wd-sm-350p-max {
	  max-width: 350%;
	}
  
	.mn-wd-sm-350p-max {
	  min-width: 350%;
	}
  
	.wd-sm-350-f-max {
	  width: 350px !important;
	}
  
	.wd-sm-350p-f-max {
	  width: 350% !important;
	}
  
	.mx-wd-sm-350p-f-max {
	  max-width: 350% !important;
	}
  
	.mn-wd-sm-350p-f-max {
	  min-width: 350% !important;
	}
  
	.wd-sm-400-max {
	  width: 400px;
	}
  
	.wd-sm-400p-max {
	  width: 400%;
	}
  
	.mx-wd-sm-400p-max {
	  max-width: 400%;
	}
  
	.mn-wd-sm-400p-max {
	  min-width: 400%;
	}
  
	.wd-sm-400-f-max {
	  width: 400px !important;
	}
  
	.wd-sm-400p-f-max {
	  width: 400% !important;
	}
  
	.mx-wd-sm-400p-f-max {
	  max-width: 400% !important;
	}
  
	.mn-wd-sm-400p-f-max {
	  min-width: 400% !important;
	}
  
	.wd-sm-450-max {
	  width: 450px;
	}
  
	.wd-sm-450p-max {
	  width: 450%;
	}
  
	.mx-wd-sm-450p-max {
	  max-width: 450%;
	}
  
	.mn-wd-sm-450p-max {
	  min-width: 450%;
	}
  
	.wd-sm-450-f-max {
	  width: 450px !important;
	}
  
	.wd-sm-450p-f-max {
	  width: 450% !important;
	}
  
	.mx-wd-sm-450p-f-max {
	  max-width: 450% !important;
	}
  
	.mn-wd-sm-450p-f-max {
	  min-width: 450% !important;
	}
  
	.wd-sm-500-max {
	  width: 500px;
	}
  
	.wd-sm-500p-max {
	  width: 500%;
	}
  
	.mx-wd-sm-500p-max {
	  max-width: 500%;
	}
  
	.mn-wd-sm-500p-max {
	  min-width: 500%;
	}
  
	.wd-sm-500-f-max {
	  width: 500px !important;
	}
  
	.wd-sm-500p-f-max {
	  width: 500% !important;
	}
  
	.mx-wd-sm-500p-f-max {
	  max-width: 500% !important;
	}
  
	.mn-wd-sm-500p-f-max {
	  min-width: 500% !important;
	}
  
	.wd-sm-550-max {
	  width: 550px;
	}
  
	.wd-sm-550p-max {
	  width: 550%;
	}
  
	.mx-wd-sm-550p-max {
	  max-width: 550%;
	}
  
	.mn-wd-sm-550p-max {
	  min-width: 550%;
	}
  
	.wd-sm-550-f-max {
	  width: 550px !important;
	}
  
	.wd-sm-550p-f-max {
	  width: 550% !important;
	}
  
	.mx-wd-sm-550p-f-max {
	  max-width: 550% !important;
	}
  
	.mn-wd-sm-550p-f-max {
	  min-width: 550% !important;
	}
  
	.wd-sm-600-max {
	  width: 600px;
	}
  
	.wd-sm-600p-max {
	  width: 600%;
	}
  
	.mx-wd-sm-600p-max {
	  max-width: 600%;
	}
  
	.mn-wd-sm-600p-max {
	  min-width: 600%;
	}
  
	.wd-sm-600-f-max {
	  width: 600px !important;
	}
  
	.wd-sm-600p-f-max {
	  width: 600% !important;
	}
  
	.mx-wd-sm-600p-f-max {
	  max-width: 600% !important;
	}
  
	.mn-wd-sm-600p-f-max {
	  min-width: 600% !important;
	}
  
	.wd-sm-650-max {
	  width: 650px;
	}
  
	.wd-sm-650p-max {
	  width: 650%;
	}
  
	.mx-wd-sm-650p-max {
	  max-width: 650%;
	}
  
	.mn-wd-sm-650p-max {
	  min-width: 650%;
	}
  
	.wd-sm-650-f-max {
	  width: 650px !important;
	}
  
	.wd-sm-650p-f-max {
	  width: 650% !important;
	}
  
	.mx-wd-sm-650p-f-max {
	  max-width: 650% !important;
	}
  
	.mn-wd-sm-650p-f-max {
	  min-width: 650% !important;
	}
  
	.wd-sm-700-max {
	  width: 700px;
	}
  
	.wd-sm-700p-max {
	  width: 700%;
	}
  
	.mx-wd-sm-700p-max {
	  max-width: 700%;
	}
  
	.mn-wd-sm-700p-max {
	  min-width: 700%;
	}
  
	.wd-sm-700-f-max {
	  width: 700px !important;
	}
  
	.wd-sm-700p-f-max {
	  width: 700% !important;
	}
  
	.mx-wd-sm-700p-f-max {
	  max-width: 700% !important;
	}
  
	.mn-wd-sm-700p-f-max {
	  min-width: 700% !important;
	}
  
	.wd-sm-750-max {
	  width: 750px;
	}
  
	.wd-sm-750p-max {
	  width: 750%;
	}
  
	.mx-wd-sm-750p-max {
	  max-width: 750%;
	}
  
	.mn-wd-sm-750p-max {
	  min-width: 750%;
	}
  
	.wd-sm-750-f-max {
	  width: 750px !important;
	}
  
	.wd-sm-750p-f-max {
	  width: 750% !important;
	}
  
	.mx-wd-sm-750p-f-max {
	  max-width: 750% !important;
	}
  
	.mn-wd-sm-750p-f-max {
	  min-width: 750% !important;
	}
  
	.wd-sm-800-max {
	  width: 800px;
	}
  
	.wd-sm-800p-max {
	  width: 800%;
	}
  
	.mx-wd-sm-800p-max {
	  max-width: 800%;
	}
  
	.mn-wd-sm-800p-max {
	  min-width: 800%;
	}
  
	.wd-sm-800-f-max {
	  width: 800px !important;
	}
  
	.wd-sm-800p-f-max {
	  width: 800% !important;
	}
  
	.mx-wd-sm-800p-f-max {
	  max-width: 800% !important;
	}
  
	.mn-wd-sm-800p-f-max {
	  min-width: 800% !important;
	}
  
	.wd-sm-850-max {
	  width: 850px;
	}
  
	.wd-sm-850p-max {
	  width: 850%;
	}
  
	.mx-wd-sm-850p-max {
	  max-width: 850%;
	}
  
	.mn-wd-sm-850p-max {
	  min-width: 850%;
	}
  
	.wd-sm-850-f-max {
	  width: 850px !important;
	}
  
	.wd-sm-850p-f-max {
	  width: 850% !important;
	}
  
	.mx-wd-sm-850p-f-max {
	  max-width: 850% !important;
	}
  
	.mn-wd-sm-850p-f-max {
	  min-width: 850% !important;
	}
  
	.wd-sm-900-max {
	  width: 900px;
	}
  
	.wd-sm-900p-max {
	  width: 900%;
	}
  
	.mx-wd-sm-900p-max {
	  max-width: 900%;
	}
  
	.mn-wd-sm-900p-max {
	  min-width: 900%;
	}
  
	.wd-sm-900-f-max {
	  width: 900px !important;
	}
  
	.wd-sm-900p-f-max {
	  width: 900% !important;
	}
  
	.mx-wd-sm-900p-f-max {
	  max-width: 900% !important;
	}
  
	.mn-wd-sm-900p-f-max {
	  min-width: 900% !important;
	}
  
	.wd-sm-950-max {
	  width: 950px;
	}
  
	.wd-sm-950p-max {
	  width: 950%;
	}
  
	.mx-wd-sm-950p-max {
	  max-width: 950%;
	}
  
	.mn-wd-sm-950p-max {
	  min-width: 950%;
	}
  
	.wd-sm-950-f-max {
	  width: 950px !important;
	}
  
	.wd-sm-950p-f-max {
	  width: 950% !important;
	}
  
	.mx-wd-sm-950p-f-max {
	  max-width: 950% !important;
	}
  
	.mn-wd-sm-950p-f-max {
	  min-width: 950% !important;
	}
  
	.wd-sm-1000-max {
	  width: 1000px;
	}
  
	.wd-sm-1000p-max {
	  width: 1000%;
	}
  
	.mx-wd-sm-1000p-max {
	  max-width: 1000%;
	}
  
	.mn-wd-sm-1000p-max {
	  min-width: 1000%;
	}
  
	.wd-sm-1000-f-max {
	  width: 1000px !important;
	}
  
	.wd-sm-1000p-f-max {
	  width: 1000% !important;
	}
  
	.mx-wd-sm-1000p-f-max {
	  max-width: 1000% !important;
	}
  
	.mn-wd-sm-1000p-f-max {
	  min-width: 1000% !important;
	}
  
	.wd-sm-auto-max {
	  width: auto;
	}
  
	.wd-sm-auto-f-max {
	  width: auto !important;
	}
}
@media (max-width:575.98px) {
	.wp-sm-max-20 {
		width: 20% !important;
	}
}