.nav-sidebar .list-unstyled li:last-child {
  border-bottom: 0px;
}
.nav-item.show .dropdown-menu.dropdown-menu-arrow.show {
  top: -1px !important;
}
/*------ Navigation -------*/

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &.nav-pills.nav-pills-circle {
    .nav-link {
      &:hover,
      &:focus {
        color: $primary !important;
      }
      &.active {
        color: $white !important;
      }
    }
  }
}
.nav-link {
  display: block;
  padding: 0.2rem 0.5rem;
}
.nav-tabs {
  border-bottom: 1px solid #eaedf1;
  .nav-item {
    margin-bottom: -1px;
    position: relative;
  }
  .nav-link {
    &:hover,
    &:focus,
    &.active {
      border: 0;
    }
  }
  .nav-item.show .nav-link {
    border: 0;
  }
  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    color: $white;
  }
}
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.nav.nav-pills.nav-stacked.labels-info p {
  color: #9d9f9e;
  font-size: 11px;
  margin-bottom: 0;
  padding: 0 22px;
}

.nav-item1 {
  padding: 10px;
  min-width: 2rem;
  transition: 0.3s color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.nav1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 0;
  list-style: none;
  background: transparent;
  border: 1px solid $border;
  border-radius: 5px;

  &.nav-column {
    .nav-item1 {
      padding: 4px 0px;
      margin-top: 0;
      border-bottom: 1px solid $border;
    }
    .nav-link {
      border-right: none !important;
      width: 100%;
      padding: 6px;
      border-radius: 5px;

      svg {
        margin-right: 5px;
        transition: all ease 0.3s;
      }

      &:hover,
      &:focus,
      &:active {
        color: $secondary !important;
        svg {
          fill: $secondary !important;
        }
      }
      &.active {
        color: $secondary !important;
        background-color: $info;
        .option-dots {
          color: $secondary !important;
        }
        svg {
          fill: $secondary !important;
        }
      }
    }
  }

  &.colored {
    .nav-link {
      color: $gray-light;

      &.disabled {
        color: $white-4;

        &:hover,
        &:focus {
          cursor: not-allowed !important;
        }
      }
      &:hover,
      &:focus,
      &.active {
        color: $white;
      }
    }
  }
}
.dark-mode {
  .nav1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 0;
    list-style: none;
    background: transparent;
    border: 1px solid $border;
    border-radius: 5px;
  
    &.nav-column {

      .nav-link {

        &:hover,
        &:focus,
        &:active {
          color: $white !important;
          svg {
            fill: $white !important;
          }
        }
        &.active {
          background-color: $primary-1;
          color: $white !important;
          svg {
            fill: $white !important;
          }
        }
      }
    }
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: $primary !important;
}

.nav-item1 {
  &:hover:not(.disabled),
  &.active {
    color: $white;
  }
  .nav-link {
    &.disabled {
      opacity: 6;
      cursor: default-color;
      pointer-events: none;
    }
    &.disable {
      opacity: 3;
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid #eaedf1;
  .nav-item1 {
    margin-bottom: -1px;
    position: relative;
    &.nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .nav-link {
      border: 0;
      color: inherit;
      margin-bottom: -1px;
      color: #76839a;
      transition: 0.3s border-color;
      font-weight: 400;
      &:hover:not(.disabled),
      &.active {
        color: $white !important;
      }
      &.disabled {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }
    }
    i {
      margin-right: 0.25rem;
      line-height: 1;
      font-size: 0rem;
      width: 0.875rem;
      vertical-align: baseline;
      display: inline-block;
      margin-right: 10px;
    }
    &:hover .nav-submenu {
      display: block;
    }
  }
  .nav-submenu .nav-item1 {
    display: block;
    padding: 0.5rem 1rem;
    color: #9aa0ac;
    margin: 0 !important;
    cursor: pointer;
    transition: 0.3s background;
    &.active {
      color: #467fcf;
    }
    &:hover {
      color: #6e7687;
      text-decoration: none;
      background: rgba(0, 0, 0, 0.024);
    }
  }
}

.nav-unread {
  position: absolute;
  top: 0.4rem;
  background: #ecd938;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  &.badge {
    position: absolute;
    top: 4px;
    left: 26px;
    display: block !important;
    padding: 3px 5px !important;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    font-size: 11px;
  }
}

.nav-link {
  position: relative;
  &.is-active {
    &:before {
      content: "";
      position: absolute;
      left: 0.5rem;
      right: 0.5rem;
      bottom: -0.75rem;
      height: 0.125rem;
      background-color: $primary;
    }
  }
  &.icon {
    margin: 5px 0;
    padding: 12px;
    text-align: center;
    height: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    &.is-active {
      &:before {
        bottom: -1.0625rem;
      }
    }
    i {
      font-size: 20px;
    }
    svg {
      width: 1.3rem;
      fill: #294865 !important;
    }
  }
  &.disabled {
    &.hover,
    &.focus {
      color: $muted;
    }
  }
  min-width: 2rem;
  transition: 0.3s color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: $default-color;
  transition: all ease 0.3s;
}

.nav-basic {
  .nav-link {
    &.active,
    &:hover,
    &:focus {
      color: $primary !important;
    }
  }
}

.tabs-menu4 {
  .nav-link {
    &:hover {
      color: $primary !important;
    }
  }
}

@media (max-width: 768px) {
  .nav-link.icon {
    padding: 5px !important;
  }
}
.nav-item {
  min-width: 2rem;
  transition: 0.3s color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.nav-tabs {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $black;
  .nav-link {
    border: 0;
    color: inherit;
    color: #76839a;
    transition: 0.3s border-color;
    font-weight: 500;
    padding: 1rem 1.8rem;
    font-size: 13px;
    text-align: center;
    &:hover:not(.disabled),
    &.active {
      color: $white;
    }
    &.disabled {
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
      color: #868e96;
      background-color: transparent;
      border-color: transparent;
    }
  }
  .nav-item:hover .nav-submenu {
    display: block;
  }
}

@media (max-width: 1279px) and (min-width: 992px) {
  .nav-tabs .nav-link {
    padding: 1rem 1.8rem;
    font-size: 13px;
  }
}
.nav-tabs {
  .nav-item i {
    margin-right: 0.25rem;
    line-height: 1;
    font-size: 0rem;
    width: 0.875rem;
    vertical-align: baseline;
    display: inline-block;
    margin-right: 10px;
  }
  .nav-submenu {
    display: none;
    position: absolute;
    background: $white;
    border: 1px solid #eaedf1;
    border-top: none;
    z-index: 10;
    box-shadow: 0 1px 2px 0 $black-05;
    min-width: 10rem;
    border-radius: 0 0 3px 3px;
    .nav-item {
      display: block;
      padding: 0.5rem 1rem;
      color: #9aa0ac;
      margin: 0 !important;
      cursor: pointer;
      transition: 0.3s background;
      &.active {
        color: #467fcf;
      }
      &:hover {
        color: #6e7687;
        text-decoration: none;
        background: rgba(0, 0, 0, 0.024);
      }
    }
  }
}
.hor-menu .nav-badge {
  border-radius: 30px;
  position: absolute;
  top: 3px;
  right: 14px;
  padding: 0.2em 0.4em;
  font-size: 12px;
}
@media (max-width: 992px) {
  .hor-menu .nav-badge {
    right: auto !important;
  }
}
#smartwizard {
  .nav.nav-tabs.step-anchor {
    border-bottom: 0 !important;
    padding: 30px;
  }
  .nav-tabs {
    border-top: 0 !important;
  }
  .nav-link {
    padding: 8px 40px;
    border-radius: 5px !important;
  }
}
.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
  background: transparent;
}
#basicwizard .nav-tabs .nav-link {
  padding: 0.8rem 0.8rem;
}
.navbar-toggler:focus {
  box-shadow: none;
}
