/* jQuery Countdown styles 2.0.0. */

#launch_date {
  margin: 0px auto 20px auto;
}

.countdown-rtl {
  direction: rtl;
}

.countdown-holding span {
  color: #888;
}

.countdown-row {
  clear: both;
  width: 100%;
  padding: 0px 2px;
  text-align: center;
}

.countdown {
  li {
    display: inline-block;
    font-size: 1.83rem;
    font-weight: bold;
    min-width: 88px;
    height: 88px;
    border-radius: 3px;
    line-height: 22px;
    padding: 20px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 1);
    z-index: 1;
    border: 5px solid rgba(255, 255, 255, 1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #25252a;
  }

  .time {
    font-size: 13px;
    padding-top: 2px;
    font-weight: 300;
  }
}

@media (max-width: 991px) {
  .countdown li {
    margin: 20px 10px 10px 10px;
  }

  .is-countdown {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .countdown li {
    margin: 30px 10px 5px 10px;
  }
}
