/*------Drop Downs-------*/

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  &:empty::after {
    margin-left: 0;
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #294865;
  text-align: left;
  list-style: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #eaedf1;
}
.dropdown-menu-end {
  right: 0;
  left: auto;
}
.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }
    &:empty::after {
      margin-left: 0;
    }
  }
}
.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
    }
    &:empty::after {
      margin-left: 0;
    }
  }
}
.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
    }
    &::before {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent;
    }
    &:empty::after {
      margin-left: 0;
    }
    &::before {
      vertical-align: 0;
    }
  }
}
.dropdown-menu {
  &[data-popper-placement^="top"],
  &[data-popper-placement^="right"],
  &[data-popper-placement^="bottom"],
  &[data-popper-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.drop-heading {
  padding: 1rem 1rem;
  color: #8f9daf;
}
.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #74788e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    svg {
      fill: $primary;
    }
  }
  svg {
    margin-right: 5px;
    transition: all ease 0.3s;
  }

  .brround svg {
    margin-right: 0px;
  }
}
.drop-icon-wrap {
  text-align: center;
  li {
    display: inline-block;
  }
  .drop-icon-item {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    color: #878787;
    text-transform: capitalize;
    min-width: 75px;
  }
}
.drop-icon-item i {
  font-size: 20px;
  padding-bottom: 5px;
}
.drop-icon-wrap .drop-icon-item:hover {
  text-decoration: none;
  background-color: #f1f1f9;
}
.dropdown-item {
  &:hover .notification-label {
    color: $primary;
  }
  &.user {
    font-size: 18px;
    padding: 5px;
  }
  &:hover,
  &:focus,
  &.active,
  &:active {
    text-decoration: none;
    background-color: unset;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: transparent;
  }
}
.dropdown-menu.show {
  display: block;
  margin: 0;
  border: 1px solid #eaf2f6;
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.3);
  border-radius: 5px !important;
}
.header-search-icon .dropdown-menu {
  box-shadow: 0 10px 15px rgba(229, 228, 230, 0.5);
  margin-top: 13px !important;
  border: 1px solid #eaedf1 !important;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
/*-----List Of Dropdwons-----*/

btn.dropdown-toggle ~ .dropdown-menu {
  background-color: rgba(244, 244, 244, 1);
  background-color: rgba(255, 255, 255, 1);
  border: 0 solid rgba(66, 133, 244, 1);
  box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3);
  top: 0px;
  margin: 0px;
  padding: 0px;
}
ul.dropdown-menu li.dropdown ul.dropdown-menu {
  background-color: rgba(244, 244, 244, 1);
  background-color: rgba(255, 255, 255, 1);
  border: 0 solid rgba(66, 133, 244, 1);
  box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3);
  top: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100%;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 98;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: $white;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  border: 1px solid var(--border) !important;

  a.dropdown-item:first-child {
    border-radius: 5px 5px 0 0 !important;
    border-top: none !important;
  }
  a.dropdown-item:last-child {
    border-radius: 0 0 5px 5px !important;
  }
  a.dropdown-item:hover,
  a.dropdown-item:focus {
    background-color: $light;
  }

  > li > a {
    display: block;
    padding: 8px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.428571429;
    color: #294865d9;
    white-space: nowrap;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #8491a5;
      background-color: #f1f1f9;
    }
  }
  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #eaedf1;
  }
  .dropdown-plus-title {
    width: 100%;
    color: $default-color;
    padding: 6px 12px;
    font-weight: 500;
    border: 0 solid #eaedf1;
    border-bottom-width: 1px;
    cursor: pointer;
  }
}
ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
.btn.dropdown-toggle {
  &.btn-primary ~ .dropdown-menu .dropdown-plus-title {
    border-color: $primary !important;
  }
  &.btn-success ~ .dropdown-menu .dropdown-plus-title {
    border-color: $success !important;
  }
  &.btn-info ~ .dropdown-menu .dropdown-plus-title {
    border-color: $info !important;
  }
  &.btn-warning ~ .dropdown-menu .dropdown-plus-title {
    border-color: $warning !important;
  }
  &.btn-danger ~ .dropdown-menu .dropdown-plus-title {
    border-color: $danger !important;
  }
  &.btn-dark ~ .dropdown-menu .dropdown-plus-title {
    border-color: $gray-dark !important;
  }
  &.btn-light ~ .dropdown-menu .dropdown-plus-title {
    border-color: $gray-light !important;
  }
}
.dropdown-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  border-bottom: 1px solid #eaedf1;
}
.dropdown-demo .dropdown-menu {
  position: static;
  float: none;
}
.dropdown-menu-header label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8491a5;
}
.dropdown-media-list .media {
  &:hover,
  &:focus {
    background-color: #f8f9fa;
  }
  padding: 12px 15px;
}
.media {
  display: flex;
  align-items: flex-start;
}
.dropdown-media-list {
  img {
    border-radius: 100%;
  }
  .media-body {
    margin-left: 15px;
    > {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin-bottom: 0;
          font-weight: 500;
          color: #8491a5;
          font-size: 14px;
        }
        span {
          font-size: 12px;
          color: #cfcfcf;
        }
      }
      p {
        font-size: 14px;
        color: #bfbfbf;
        margin-bottom: 0;
      }
    }
  }
}
.dropdown-list-footer {
  padding: 10px 15px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #eaedf1;
}
.dropdown-media-list {
  padding: 0;
}
@media (min-width: 768px) {
  ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
@media (min-width: 768px) {
  ul.dropdown-menu li.dropdown ul.dropdown-menu {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-arrow:before {
    content: "";
    width: 13px;
    height: 13px;
    background: $white;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}
.dropdown {
  display: block;
}
.header .dropdown .nav-link.icon:hover {
  background: none !important;
}
.dropdown-menu {
  box-shadow: 0 1px 2px 0 $black-05;
  min-width: 12rem;
}
.dropdown-menu-arrow.dropdown-menu-end {
  &:before,
  &:after {
    left: auto;
    right: 12px;
  }
}
.dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  &:after {
    vertical-align: 0.155em;
  }
  &:empty:after {
    margin-left: 0;
  }
}
.dropdown-icon {
  color: #8f9daf;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  width: 1em;
  display: inline-block;
  text-align: center;
  vertical-align: -1px;
}
.dropdown-menu.dropdown-menu-end.show {
  left: inherit !important;
}
